/* Action-Sheet menu Item Styles */
.common-menu-normal {
  font-weight: 800;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.04em;

  --color: #000000;
}

.common-menu-hightlight {
  font-weight: 800;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.04em;

  --color: #2d9cdb;
}
.common-menu-red {
  font-weight: 800;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.04em;

  --color: #ed513b;
}

.common-background {
  background: #ffffff;
}

.common-small-outer-container {
  width: 100%;
  height: 100%;
  max-width: 360px;
  max-height: min(100%, 780px);
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
}

.ion-color-common-header-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #3f4d5d;
  --ion-color-tint: #39393980;

  --min-height: 43px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 43px;
}

.ion-color-common-header-composer-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #3f4d5d;
  --ion-color-tint: #39393980;

  --min-height: 53px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 53px;

  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.common-header-toolbar-button {
  z-index: 50;
}

.common-container-full {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.common-background-white {
  background: #fff;
}

.common-hidden {
  display: none;
  visibility: hidden;
}

.common-header {
  height: 43px;
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08); */
  position: relative;
}

.common-header-composer {
  height: 53px;
}

.common-header-title-center {
  width: 100%;
  text-align: center;
  color: #393939;
}

.common-header-title {
  color: #393939;
}

.common-content-with-header {
  width: 100%;
  height: calc(100% - 43px);
  max-height: calc(100% - 43px);
}

.common-content-with-header-footer {
  width: 100%;
  height: calc(100% - 89px);
  max-height: calc(100% - 89px);
}

.common-footer-container {
  width: 100%;
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.common-footer-button {
  height: 46px;
  width: 100%;
  /* padding-top: 10px; */

  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 150%;
  text-align: center;

  /* 화이트 */

  color: #ffffff;
}

.common-footer-button-caution {
  --ion-color-base: #ed6236;
  background: #ed6236 !important;
}

.common-footer-button-danger {
  --ion-color-base: #eb5757;
  background: #eb5757 !important;
}

.common-footer-button-normal {
  --ion-color-base: #2667eb;
  background: #2667eb !important;
}

.common-footer-button-text {
  flex-grow: 1;
  align-items: center;
  width: 100%;

  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 16px;
  color: #ffffff;
  margin-right: 5px;
  margin-left: 5px;
}

.common-content-with-composer-header {
  width: 100%;
  max-height: 100%;
  height: calc(100% - 53px);
}

.common-content {
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: relative;
}

.common-block {
  padding: 20px 24px;
}

.common-block-sub {
  width: 100%;
  padding: 16px;
  background: #f5f9fa;
  border-radius: 12px;
}

.common-block-line {
  padding: 0px 24px;
}

.common-bottom-space {
  height: 40px;
}

.common-bottom-footer-space {
  height: 126px;
}

.common-scroll {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.common-scroll-x {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}

.common-nowrap {
  white-space: nowrap;
  flex-wrap: nowrap;
}

.common-no-scroll {
  overflow-y: none;
  /* -webkit-overflow-scrolling: none; */
}

.common-position-relative {
  position: relative;
}

.common-position-absolute {
  position: absolute;
}

.common-background-white {
  background: #fff;
}

.common-list {
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.common-bold {
  font-weight: bold;
}

.common-color-highlight {
  color: #2d9cdb !important;
}

.common-color-caution {
  color: #f32e6d !important;
}

.common-color-point {
  color: #fd6128 !important;
}

.common-text-align-right {
  width: 100%;
  text-align: right;
}

.common-text-align-left {
  text-align: left;
}

.common-text-align-center {
  width: 100%;
  text-align: center;
}

.common-container {
  width: 100%;
}

.common-container-column {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.common-container-row {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
}

.common-container-row-wrap {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}

.common-flex-align-start {
  align-items: start;
}

.common-flex-align-center {
  align-items: center;
}

.common-flex-align-end {
  align-items: flex-end;
}

.common-flex-auto {
  flex: 1 1 auto;
}

.common-flex-grow {
  flex-grow: 1;
}

.common-flex-grow-2 {
  flex-grow: 2;
}

.common-flex-column {
  display: flex;
  flex-direction: column;
}

.common-flex-row {
  display: flex;
  flex-direction: row;
}

.common-flex-row-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}

.common-modal-full-popup-container {
  --width: 100%;
  --height: 100%;
  --background: #0000;
  padding: 0px;
}

.common-modal-popup-container {
  --width: 100%;
  /* --height: 240px; */
  --background: #0000;
  padding-left: 8px;
  padding-right: 8px;
}

.common-modal-popup-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.common-modal-popup-inner-max360 {
  width: 100%;
  max-width: 360px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.common-modal-popup-backdrop {
  flex-grow: 1;
  width: 100%;
}

.common-modal-container {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 13px;
  padding-top: 40px;
  padding-bottom: 21px;
  padding-left: 21px;
  padding-right: 21px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.common-modal-container-outer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.common-modal-container-max360 {
  max-width: 360px;
}

.ion-color-common-modal-popup-close {
  --color: #888888;
  --padding-start: 13px;
  --padding-end: 16px;
  --box-shadow: none;
  position: absolute;
  right: 0px;
  top: 10px;
  margin: 0px;
}

.common-modal-popup-close-icon {
  width: 18px;
  height: 18px;
}

.common-always-message {
  position: absolute;
  top: 0px;
  right: 0px;
  font-family: "NanumSqaure";
  font-size: 8px;
  font-weight: bold;
  line-height: 100%;
}

.common-max-width-container {
  margin: auto;
  max-width: 600px;
  height: 100%;
}

.common-spinner {
  margin-top: 20px;
  width: 100px;
  height: 100px;
}

.common-unselectable {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.ion-color-common-button {
  --border-radius: 0px;
  --border-width: 0px;
  --border-style: solid;
  --border-color: #0000;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
  margin: 0px;
  margin-inline: 0px;
  --box-shadow: none;
  white-space: nowrap;
}

.common-file-input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.common-toggle-selected {
  background: #2d9cdb !important;
  color: #f2f2f2 !important;
}

.common-toggle {
  height: 20px;
  padding: 5px 10px;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 11px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  background: #fff;
  color: #888888;
}

@media (max-width: 600px) {
  .common-max-width-container {
    width: 100%;
    height: 100%;
  }
}

.common-text-button {
  font-size: 16px;
  line-height: normal;
  color: #2d9cdb;
  font-weight: bold;
  padding-right: 10px;
}

.common-text-button-disabled {
  font-size: 16px;
  line-height: normal;
  color: #c4c4c4;
  padding-right: 10px;
}

.common-ad-tag {
  margin-left: 10px;
  padding: 2px 5px;
  background: #f2c94c;
  border-radius: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 100%;
  /* identical to box height, or 10px */

  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
}

.ion-color-common-checkbox {
  --checkmark-color: #fff;
  --background-checked: #2d9cdb;
  --border-color-checked: #2d9cdb;
  margin-right: 10px;
}

.ion-color-common-move-to-top-fab {
  --ion-color-base: #fff8;
  --ion-color-contrast: #000;
  --ion-color-shade: #fff8;
  --padding-start: 0px;
  --padding-end: 0px;
  /* width: 84px;
  height: 84px; */
  width: 40px;
  height: 40px;
  --border-radius: 20px;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 0px;
  left: calc(50% - 20px);
  z-index: 5;
}

.common-move-to-top-fab-icon {
  color: #2d9cdb88;
  width: 20px;
  height: 20px;
  --font-size: 20px;
}

.common-effect-parent {
  overflow: hidden;
}

.common-effect-parent > .common-effect-ripple {
  background-color: #0004;
  border-radius: 50%;
  pointer-events: none;
  position: absolute;
  transform: scale(0);
}

.common-effect-parent > .common-effect-start {
  transform: scale(0.2);
}

.common-effect-parent > .common-effect-active {
  transform: scale(2);
  transition: transform 700ms, opacity 700ms;
  opacity: 0.2;
}

.common-effect-parent > .common-effect-hover {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: #000;
  opacity: 0;
  pointer-events: none;
}

@media (hover: hover) {
  .common-effect-parent:hover > .common-effect-hover {
    opacity: 0.05;
    transition: all 0.2s ease;
  }
}

.common-position-sticky {
  position: sticky;
  z-index: 100;
}
