.welcome-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #dae8ef;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.welcome-slider-container {
  margin-top: 23px;
}

.welcome-swipe-container {
  width: 100%;
  height: calc(100vh - 33px);
}

.welcome-swipe {
  width: 100%;
  height: 100%;
}

.welcome-0-profile {
  margin-top: 40px;
  width: 80px;
  height: 80px;
  border-radius: 40px;
}

.welcome-0-welcome {
  margin-top: 17px;

  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.05em;
  font-weight: bold;
  color: #2d9cdb;
}

.welcome-0-description {
  width: calc(100% - 10px);
  margin-top: 15px;
  margin-left: 5px;
  margin-right: 5px;

  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 22px;
  padding-right: 22px;

  background: #ffffff;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 13px;

  font-size: 16px;
  line-height: 25px;

  color: #000000;
}

.welcome-next-button-container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ion-color-welcome-next-button {
  --ion-color-base: #0000;
  --box-shadow: none;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  width: 170px;
  height: 36px;

  font-size: 17px;
  line-height: normal;
  text-align: right;

  border: 1.5px solid #2d9cdb;
  box-sizing: border-box;
  border-radius: 5px;

  color: #2d9cdb;
}

.welcome-next-button-container-reverse {
  flex-grow: 1;
  width: 100%;
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  align-items: center;
  background: #2d9cdb;
}

.ion-color-welcome-next-button-reverse {
  --ion-color-base: #0000;
  --box-shadow: none;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  width: 170px;
  height: 36px;

  font-size: 17px;
  line-height: normal;
  text-align: right;

  border: 1.5px solid #fff;
  box-sizing: border-box;
  border-radius: 5px;

  color: #fff;
}

.welcome-1-title {
  margin-top: 20px;

  font-size: 20px;
  line-height: 23px;
  font-weight: bold;
  letter-spacing: 0.05em;

  color: #2d9cdb;
}

.welcome-1-description-container {
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.welcome-1-profile {
  width: 50px;
  height: 50px;
}

.welcome-1-description {
  flex-grow: 1;
  margin-right: 5px;
  margin-left: 5px;

  font-size: 14px;
  line-height: 19px;

  color: #171717;
}

.welcome-1-subtitle {
  margin-top: 34px;
  padding-left: 10px;
  width: 100%;

  font-size: 14px;
  line-height: 19px;

  color: #171717;
}

.welcome-1-items-container {
  width: calc(100% - 10px);
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;

  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 7px;
  padding-right: 7px;

  background: #ffffff;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
}

.welcome-navigate-container {
  width: 100%;
  flex-grow: 1;
  align-items: flex-end;
  height: 21px;
  display: flex;
  flex-direction: row;
}

.welcome-navigate-container-reverse {
  width: 100%;
  flex-grow: 1;
  align-items: flex-end;
  height: 21px;
  display: flex;
  flex-direction: row;
  background: #2d9cdb;
}

.welcome-navigate-space {
  flex-grow: 1;
}

.ion-color-welcome-navigate-active {
  --ion-color-base: #0000;
  --box-shadow: none;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  height: 40px;

  font-size: 17px;
  line-height: normal;
  text-align: right;

  color: #2d9cdb;
}

.ion-color-welcome-navigate-active-reverse {
  --ion-color-base: #0000;
  --box-shadow: none;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  height: 40px;

  font-size: 17px;
  line-height: normal;
  text-align: right;

  color: #fff;
}

.welcome-1-description-title {
  margin-top: 24px;
  margin-bottom: 24px;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.04em;

  color: #2d9cdb;
}

.welcome-1-description-image {
  width: 100%;
  height: auto;
  background: #2d9cdb;
}
