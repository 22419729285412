.register4-container {
  widows: 100%;
  height: 100%;
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #dae8ef;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.register4-service-description-container {
  width: 100%;
  margin-top: 15px;
}

.register4-service-description {
  font-size: 13px;
  line-height: 20px;

  color: #171717;
  margin-bottom: 15px;
}

.register4-body-container {
  background: #fff;
  width: calc(100% - 16px);
  margin-top: 13px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 23px;
  padding-bottom: 23px;
  border-radius: 13px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.register4-check-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.register4-check-item {
  flex-grow: 1;
  margin-left: 12px;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.02em;

  /* 프로필아이디 */

  color: #6b6b6b;
}

.register4-check-item-description-container {
  padding-left: 36px;
}

.register4-check-item-description {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 155.11%;
  /* or 19px */

  /* 프로필날짜조회수 */

  color: #888888;
}

.register4-line {
  margin: 10px 0px;
  width: 100%;
  border-bottom: 1px solid #2d9cdb;
}

.register4-check-item-all {
  margin-left: 12px;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;

  /* qna타이틀내용 */

  color: #171717;
}

.register4-sub-item-container {
  padding-left: 36px;
}

.ion-color-register4-finalize {
  margin-left: 24px;
  margin-right: 24px;
  height: 48px;
  width: calc(100% - 48px);
  --ion-color-base: #2d9cdb;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.1);
  --border-radius: 24px;
  --padding-start: 0px;
  --padding-end: 0px;
}

.ion-color-register4-finalize-inactive {
  margin-left: 24px;
  margin-right: 24px;
  height: 48px;
  width: calc(100% - 48px);
  --ion-color-base: #cecece;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.1);
  --border-radius: 24px;
  --padding-start: 0px;
  --padding-end: 0px;
}

.register4-finalize-container {
  position: sticky;
  bottom: 10px;
  margin-top: 28px;
  width: 100%;
}

.ion-color-register4-detail-view {
  height: 18px;
  width: 65px;
  --ion-color-base: #fff;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  --border-radius: 16.1667px;
  --padding-start: 0px;
  --padding-end: 0px;

  font-size: 10px;
  line-height: normal;

  color: #a1a0a5;
}
