.viewer-body-popup-container {
  --width: 100%;
  --height: 100%;
  /* --height: auto; */
  --background: #0000;
  padding-left: 8px;
  padding-right: 8px;
}

.viewer-body-popup-inner {
  width: 100%;
  height: 100%;
  padding-top: 4px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.viewer-body-header-container {
  padding-right: 60px;
}

.ion-color-viewer-body-hide-parent {
  background: #fafafa;
  /* 시그니처컬러 */

  border: 1px solid #2d9cdb;

  height: 34px;
  margin-right: 10px;
  margin-bottom: 15px;
}

.viewer-body-hide-parent {
  font-size: 15px;
  line-height: 23px;
  font-weight: bold;
  letter-spacing: -0.02em;
  --box-shadow: none;

  color: #2d9cdb;
}

.modal-wrapper.sc-ion-modal-md {
  --box-shadow: none;
}
