.component-composer-popup-container {
  --width: 100%;
  /* --height: auto; */
  --background: #0000;
  padding-left: 20px;
  padding-right: 20px;
}

.component-composer-popup-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.component-composer-popup-backdrop {
  flex-grow: 1;
  width: 100%;
}

.component-composer-container {
  width: 100%;
  margin: auto;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 13px;
  /* padding-top: 13px;
    padding-bottom: 13px;
    padding-left : 17px;
    padding-right : 17px; */
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.component-composer-sub-container {
  width: 100%;
}

.component-composer-textarea {
  width: 100%;
  height: 400px;
}

.component-composer-preset-button {
  font-size: 10px;
  --padding-start: 3px;
  --padding-end: 3px;
  height: 20px;
}

.component-composer-title {
  width: 100%;

  font-size: 17px;
  line-height: normal;
  font-weight: bold;
  color: #171717;
  padding-top: 13px;
  padding-left: 16px;
}

.component-composer-type-container {
  padding-left: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.modal-wrapper.sc-ion-modal-md {
  --box-shadow: none;
}
