@import './../../_design/color.scss';

.cardview-test {
    --cardview-border-radius: 24px;
    --cardview-card-border-radius: 0px;
    --cardview-card-padding: 0px 0px;
}

.cardview-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: none;
    .cardview-content-container{
        width: 100%;
        position: relative;

        
        .cardview-content-slider {
            width: 100%;
            height: 100%; 
            background: #fff;
            border-radius: var(--cardview-border-radius, 0px);
            overflow: hidden;
            
            .cardview-content-image-container{
                padding: var(--cardview-card-padding, 0px);
            
                .cardview-content-image {
                    border-radius: var(--cardview-card-border-radius, 0px);
                    width: 100%;
                    height: 100%; 
                    object-fit: contain;
                }
            }
        }
    }

    .cardview-page-index-container-center { 
        position: absolute;
        bottom: 24px;
        left: 0px;
        width: 100%;
        z-index: 3;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .cardview-page-index-container-left { 
        position: absolute;
        bottom: 24px;
        left: 24px;
    }

    .cardview-page-index-container-centered-left { 
        position: absolute;
        bottom: 24px;
        left: calc(5% + 24px);
    }

    .cardview-page-index-container-right { 
        position: absolute;
        bottom: 16px;
        right: 6px;
    }
    
    .cardview-page-index-selected {
        background: $Gray7;
        width: 16px;
        height: 4px;
        border-radius: 6px;
        margin: 0px 3px;
    }
    
    .cardview-page-index {
        background: $Gray2;
        width: 6px;
        height: 4px;
        border-radius: 6px;
        margin: 0px 3px;
    }

    .cardview-page-button-previous{
        position: absolute;
        left: 0px;
        top: 50%;
        transition: (-50%, 0px);
        width: 30px;
        height: 60px;
        border-radius: 0px 30px 30px 0px;
        background: rgba(0, 0, 0, 0.3);
        padding: 18px 0px;
    }

    .cardview-page-button-next{
        position: absolute;
        right: 0px;
        top: 50%;
        transition: (-50%, 0px);
        width: 30px;
        height: 60px;
        border-radius: 30px 0px 0px 30px;
        background: rgba(0, 0, 0, 0.3);
        padding: 18px 0px 18px 6px;
    }
}

.slick-slide div {
    outline: none;
}