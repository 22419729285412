.home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  -ms-overflow-style: none;
}

.home-logo-container {
  position: relative;
  width: 100%;
  height: 151px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-logo {
  margin-top: 50px;
  width: 64px;
  height: 86px;
}

.home-alarm {
  position: absolute;
  top: 0px;
  right: 0px;
  --ion-color-base: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home-admin {
  color: #f88;
  --ion-color-base: #fff;
  --background: #fff;
  --box-shadow: none;
}

.home-qustion-suggest {
  height: 41px;
  margin-left: 15px;
  margin-right: 15px;

  border: 1.5px solid #2d9cdb;
  box-sizing: border-box;
  border-radius: 7px;

  /* 아이콘라인컬러 */

  color: #a1a0a5;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
}

.home-qustion-suggest-text {
  flex-grow: 1;

  font-size: 16px;
  text-align: left;
  line-height: 136.2%;
  /* identical to box height, or 22px */

  letter-spacing: -0.04em;
}

.home-qustion-suggest-icon {
  width: 20px;
  height: 20px;
}

.home-suggestion-list {
  margin-top: 35px;
  width: 100%;
}

.ion-color-home-fab {
  --ion-color-base: #fd6128;
  --ion-color-contrast: #0000;
  --ion-color-shade: #ff9158;
}

.home-fab-text {
  color: #fff;

  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.1em;
  font-weight: bold;
}

.home-suggestion-container {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-suggestion-finished-text {
  font-size: 12px;
  line-height: 16px;
  /* or 16px */

  text-align: center;

  color: #000000;
}

.home-suggestion-activity {
  margin-top: 25px;
  color: #2d9cdb;

  padding-left: 20px;
  padding-right: 20px;
  height: 28px;

  font-size: 12px;
  line-height: 130%;
  /* or 16px */

  display: flex;
  align-items: center;
  text-align: center;

  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 20.5px;
}

.home-suggestion-activity-item-container {
  margin-top: 15px;
  margin-right: 15px;
  margin-left: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 15px;
  padding-right: 0px;

  width: calc(100% - 30px);

  display: flex;
  flex-direction: row;
  align-items: center;

  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 13px;
}

.home-suggestion-activity-item-text {
  font-size: 12px;
  line-height: 16px;
  flex-grow: 1;
  color: #000000;
}

.ion-color-home-suggestion-activity-item-button {
  height: 29px;
  --ion-color-base: #fff;
  --padding-start: 12.5px;
  --padding-end: 12.5px;
  --box-shadow: none;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;

  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.1em;
  color: #2d9cdb;
  font-weight: bold;
}

.ion-color-home-suggestion-activity-icon-button {
  --ion-color-base: #fff;
  --ion-color-contrast: #2d9cdb;
  --ion-color-shade: #2d9cdb;
  --ion-color-tint: #2d9cdb;
  --box-shadow: none;
  --padding-start: 20px;
  --padding-end: 20px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

.home-suggestion-activity-icon {
  color: #2d9cdb;
  width: 15px;
  height: 20px;
}
.home-suggestion-activity-icon-kakao {
  height: 40px;
  width: 82px;
  --padding-start: 0px;
  --padding-end: 0px;
}
