.register12-work-type-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 28px;
}

.register12-work-type-selected {
  height: 32px;
  padding-top: 10px;
  margin-top: 15px;
  margin-left: 2px;
  margin-right: 2px;
  width: 128px;
  background: #2d9cdb;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;

  font-size: 14px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  color: #f2f2f2;
}

.register12-work-type-unselected {
  height: 32px;
  padding-top: 10px;
  padding-top: 10px;
  margin-top: 15px;
  margin-left: 2px;
  margin-right: 2px;
  width: 128px;
  background: #fff;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;

  font-size: 14px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  color: #a1a0a5;
}
