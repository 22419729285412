.poll-simple-page-description {
  width: 100%;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;

  color: #171717;
  margin-bottom: 25px;
}

.poll-simple-page-item-container {
  width: 100%;
  margin-bottom: 25px;
}

.poll-simple-page-item-question {
  width: 100%;

  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  margin-bottom: 15px;

  color: #000000;
}

.poll-simple-page-item-margin {
  margin-bottom: 15px;
}

.poll-simple-page-item-text {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 100%;

  color: #000000;
}

.poll-simple-page-input-container {
  width: 100%;
  position: relative;
  border: 1px solid #a1a0a5;
  background: rgba(244, 244, 244, 0.5);
}

.poll-simple-page-input-placeholder {
  z-index: 2;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  color: #888;
  padding: 3px 10px;
}

.poll-simple-page-input {
  background: #0000;
  z-index: 0;
  min-height: 22px !important;
  width: 100%;

  font-size: 15px;
  line-height: 150%;
  padding: 0px;
  color: #171717;
  outline-style: none;
  padding: 3px 10px;
  border: none;
}

.poll-simple-page-input-inline {
  background: rgba(244, 244, 244, 0.5);
  z-index: 2;
  height: 22px !important;
  flex-grow: 1;
  border: 1px solid #a1a0a5;

  font-size: 15px;
  line-height: 150%;
  padding: 0px;
  color: #171717;
  outline-style: none;
  padding: 0px 5px;
}

.poll-simple-page-radio {
  margin-right: 5px;
  color: #000;
}

.poll-simple-page-radio:checked {
  margin-right: 5px;
  color: #888;
}

.poll-simple-page-item-slider-container {
  width: 100%;
  height: 40px;
}

.poll-simple-page-item-slider {
}

.ion-color-poll-simple-page-checkbox {
  --ion-color-base: #2667eb;
  --ion-color-contrast: #fff;
  color: #fff;
  --border-width: 1px;
  margin-right: 10px;
  --border-radius: 0px;
  width: 20px;
  height: 20px;
}
