.keyword-info-popup-container {
  --width: 100%;
  /* --height: auto; */
  --background: #0000;
  padding-left: 20px;
  padding-right: 20px;
}

.keyword-info-popup-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.keyword-info-popup-backdrop {
  flex-grow: 1;
  width: 100%;
}

.keyword-info-container {
  width: 100%;
  margin: auto;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 13px;
  /* padding-top: 13px;
    padding-bottom: 13px;
    padding-left : 17px;
    padding-right : 17px; */
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.keyword-title-container {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  padding-top: 13px;
  padding-left: 16px;
}

.keyword-name-container {
  flex-grow: 1;
}

.keyword-name-kor {
  font-size: 17px;
  line-height: normal;
  font-weight: bold;
  color: #171717;
}

.keyword-name-eng {
  font-size: 14px;
  line-height: normal;

  color: #2d9cdb;
}

.ion-color-keyword-popup-close {
  --color: #ececec;
  --padding-start: 13px;
  --padding-end: 16px;
  --box-shadow: none;
  margin: 0px;
}

.keyword-popup-close-icon {
  width: 14px;
  height: 14px;
}

.keyword-ingredients-container {
  width: 100%;
  background: rgba(244, 244, 244, 0.5);
  border-radius: 13px;
}

.keyword-ingredient-container {
  width: 100%;
  padding-left: 16px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.keyword-ingredient-info {
  font-size: 12px;
  line-height: normal;
  flex-grow: 1;
  color: #6b6b6b;
}

.keyword-ingredient-name {
  font-weight: bold;
}

.keyword-ingredient-link-container {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  height: 32px;
  min-width: 32px;
  max-width: 32px;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.1);
}

.keyword-ingredient-link {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #2d9cdb;
}

.keyword-ingredient-link-disabled {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #2d9cdb80;
}

.keyword-buttons-container {
  width: 100%;
  height: 32px;
  background: #2d9cdb;
  border-radius: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ion-color-keyword-link-disabled {
  flex-grow: 1;
  --box-shadow: none;
  margin: 0px;

  font-size: 12px;
  line-height: 12px;

  color: #bad8df;
}
.keyword-link-container {
  padding-left: 13px;
  padding-right: 13px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.keyword-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: none;
  margin: 0px;

  font-size: 12px;
  line-height: 12px;
  font-weight: bold;

  color: #ffffff;
}

.keyword-link-disabled {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: none;
  margin: 0px;

  font-size: 12px;
  line-height: 12px;

  color: #ffffff80;
}

.keyword-link-icon {
  height: 14px;
  width: 14px;
  margin-left: 4px;
  border-radius: 7px;
  border: 1px solid;
}

.modal-wrapper.sc-ion-modal-md {
  --box-shadow: none;
}
