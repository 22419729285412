.loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
}
.loading-title-container {
  width: 100%;
  height: 35%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loading-title {
  width: 100%;

  text-align: center;
  font-size: 22px;
  line-height: 30px;
}

.loading-logo {
  width: 100%;
  height: 30%;
  object-fit: contain;
}

.loading-name {
  font-size: 22px;
  line-height: 22px;
  text-align: center;
}

.loading-logo-container {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}
