.setting-notification-content {
  width: 100%;
  --background: #dae8ef;
}

.setting-notification-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  align-items: center;
}

.setting-notification-header {
  height: 43px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.setting-notification-title {
  font-size: 20px;
  font-weight: bold;
  color: #171717;
  flex-grow: 1;
}

.setting-notification-description-container {
  display: flex;
  flex-direction: row;
  margin-left: 26px;
  margin-right: 26px;
  margin-top: 16px;

  font-size: 12px;
  line-height: 19px;

  color: #2d9cdb;
}

.setting-notification-description-title {
  font-weight: bold;
}

.setting-notification-description {
  flex-grow: 1;
}

.setting-notification-body-container {
  background: #fff;
  width: calc(100% - 16px);
  margin-top: 13px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 23px;
  padding-bottom: 23px;
  border-radius: 13px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.setting-notification-item-container {
  display: flex;
  flex-direction: row;
  padding-top: 24px;
  padding-bottom: 10px;
  align-items: center;
}

.setting-notification-item-name {
  flex-grow: 1;

  font-size: 15px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* qna타이틀내용 */

  color: #171717;
}

.ion-color-setting-notification-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --min-height: 43px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 43px;

  font-size: 16px;
  line-height: normal;

  color: #a1a0a5;
}

.setting-notification-toggle {
  zoom: 0.8;
  --background-checked: #2d9cdb;
  --background: #a1a0a5;
}

.setting-notification-subitem-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.setting-notification-subitem {
  --size: 17px;

  font-size: 15px;
  line-height: 17px;
  color: #171717;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-left: 20px; */
  margin-right: 30px;
}

.setting-notification-subitem-text {
  margin-left: 5px;
}
