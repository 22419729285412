.ion-color-boards-toolbar {
  --ion-toolbar-color-unchecked: #39393980;
  --ion-toolbar-color-checked: #171717;
  --ion-color-base: #2d9cdb;
  --ion-color-shade: #393939;
  --ion-color-tint: #393939;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --padding-start: 0px;
  --padding-end: 0px;

  --min-height: 53px;
  height: 53px;
}

.ion-color-boards-fab {
  --ion-color-base: #fd6128;
  --ion-color-contrast: #0000;
  --ion-color-shade: #ff9158;
  width: 84px;
  height: 84px;
}

.boards-fab-icon {
  color: #fff;
  width: 30px;
  height: 30px;
  font-size: 30px;
  font-weight: bold;
}

.ion-color-boards-fab-invite {
  --ion-color-base: #2d9cdb;
  --ion-color-contrast: #0000;
  --ion-color-shade: #2d9cdb;
  /* width: 84px;
    height: 84px; */
  width: 56px;
  height: 56px;
}

@keyframes invite-blink {
  0% {
    border-color: #ffffff00;
    border-width: 3px;
  }
  50% {
    border-color: #ffffffaa;
    border-width: 6px;
  }
  100% {
    border-color: #ffffff00;
    border-width: 3px;
  }
}

.boards-fab-invite-border {
  position: absolute;
  width: 86px;
  height: 86px;
  border-radius: 43px;

  border-style: solid;
  animation: invite-blink 2s infinite;
}

.boards-header {
  height: 53px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.boards-toolbar-admin {
  color: #f88;
}

.boards-segment-button {
  --indicator-color-checked: #dae8ef !important;
  --indicator-color: #2d9cdb !important;
  --padding-start: 10px;
  --padding-end: 10px;
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  text-transform: none;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.1em;
  height: 53px;
  min-height: 53px;
  /* 상단바 제목_비활성화 */

  color: rgb(181, 183, 214) !important;
  min-width: 0px;
}

.boards-segment-button-text {
  width: 100%;
  height: 30px;
  padding-top: 5px;
  line-height: 20px;
  vertical-align: bottom;
}

.boards-segment-button.segment-button-checked {
  font-weight: 800;
  font-size: 20px;
  line-height: 20px;
  border-bottom: 4px solid #dae8ef;
  color: #ffffff !important;
}

.boards-segment-button-new {
  color: #fff;
  text-shadow: -1px -1px 0 #eb5757, 1px -1px 0 #eb5757, -1px 1px 0 #eb5757, 1px 1px 0 #eb5757;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  position: absolute;
  right: 4px;
  top: 12px;
}

.ion-color-boards-segment {
  --ion-color-base: #cecece;
  height: 53px;
  justify-content: left;
  color: #cecece;
  --padding-start: 20px;
  --padding-end: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.boards-fab-icon {
  color: #fff;
}

.boards-swipe {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* -webkit-overflow-scrolling: none; */
}

.boards-bell {
  --color-active: #fff;
}

.boards-fab-text {
  color: #fff;

  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.1em;
  font-weight: bold;
}

.boards-fab-invite-text {
  color: #fff;

  /* font-size: 22px;
    line-height: 27px; */
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.1em;
  font-weight: bold;
}

.segment-scrollable.sc-ion-segment-md-h {
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-x: none;
}
