.notification-list-item-container {
  padding : 16px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 0.3px solid #2d9cdb;
}

.notification-list-item-container-unchecked {
  background: #dae8ef;
  /* background: #F00; */
}

.notification-list-item-profile {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-right: 10px;
}

.notification-list-item-body {
  flex-grow: 1;

  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.03em;

  color: #000000;
}

.notification-list-item-name {
  font-weight: bold;
}

.notification-list-item-time {
  margin-top: 4px;
  font-size: 10px;
  line-height: 16px;

  letter-spacing: -0.03em;

  /* 하단바활성화 */

  color: #2d9cdb;
}


