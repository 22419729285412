.confirm-icon {
  width: 36px;
  height: 36px;
  margin-bottom: 29px;
}

.confirm-image {
  width: 100%;
  max-width: 360px;
  margin-bottom: 20px;
}

.confirm-title-container {
  width: 100%;
  margin-bottom: 16px;

  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.04em;
  font-weight: bold;
}

.confirm-title-highlight {
  color: #2d9cdb;
}

.confirm-email-container {
  width: 100%;
}

.confirm-body-container {
  width: 100%;

  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.02em;

  color: #6b6b6b;
}

.confirm-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.confirm-button {
  margin-top: 16px;
  min-width: 40%;
  height: 29px;
  --ion-color-base: #2d9cdb;
  background: #2d9cdb;
  --border-radius: 0px;
  /* --border-width: 2px;
    --border-style: solid;
    --border-color: #fff; */
  --padding-start: 15px;
  --padding-end: 15px;
  --box-shadow: none;

  font-size: 15px;
  line-height: 23px;
  text-align: center;

  color: #fff;
}

.confirm-button-reverse {
  margin-top: 16px;
  min-width: 40%;
  height: 29px;
  --ion-color-base: #fff;
  background: #fff;
  --border-radius: 0px;
  --border-width: 2px;
  --border-style: solid;
  --border-color: #2d9cdb;
  --padding-start: 15px;
  --padding-end: 15px;
  --box-shadow: none;

  font-size: 15px;
  line-height: 23px;
  text-align: center;

  color: #2d9cdb;
}

.confirm-cancel-button {
  margin-top: 16px;
  min-width: 40%;
  height: 29px;
  --ion-color-base: #888;
  background: #888;
  --border-radius: 0px;
  /* --border-width: 2px;
    --border-style: solid;
    --border-color: #A1A0A5; */
  --padding-start: 15px;
  --padding-end: 15px;
  --box-shadow: none;

  font-size: 15px;
  line-height: 23px;
  text-align: center;

  color: #fff;
}

.confirm-description-highlight {
  font-weight: bold;
}

.confirm-input {
  flex-grow: 1;
  margin-left: 5px;
  --padding-start: 10px;
  --padding-end: 10px;
  --inline-padding-start: 0px;
  --inline-padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  border: 1px solid #a1a0a5;

  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 26px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #171717;
  margin-right: 10px;
}

.confirm-text-area {
  border: 1px solid #a1a0a5;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 130%;
  color: #171717;
  min-height: 100px;
  max-height: 300px;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
}

.ion-color-confirm-close-button {
  --color: #ececec;
  --padding-start: 13px;
  --padding-end: 16px;
  --box-shadow: none;
  margin: 0px;
}

.confirm-close-button {
  position: absolute;
  top: 0px;
  right: 0px;
}

.confirm-close-button-icon {
  width: 14px;
  height: 14px;
}
