.event-info-popup-container {
    --width: 100%;
    --height: 100%;
    --background: #0000;
    padding-left: 20px;
    padding-right: 20px;
}

.event-info-popup-container-full {
    --width: 100%;
    --height: 100%;
    --background: #0000;
    padding-left: 0px;
    padding-right: 0px;
}

.event-info-popup-inner{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.event-info-popup-backdrop {
    flex-grow: 1;
    width: 100%;
}

.event-info-container {
    width: 100%;
    max-height: 100%;
    margin: auto;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.event-info-container-full {
    height: 100%;
}

.event-info-image-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100% - 40px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    align-items: center;
}

.event-info-image {
    width: 100%;
    height: 100%;
    height: auto;
}
.event-info-buttons-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding: 10px 10px;
}

.event-info-button-text {
    padding-left: 5px;
}

.event-info-button-space { 
    flex-grow: 1;
}

.event-info-close-icon {
    width: 14px;
    height: 14px;
}

.modal-wrapper.sc-ion-modal-md {
    --box-shadow: none;
}