.allinone-partner-post-container{
  margin-top: 24px;
}

.allinone-partner-post-profile-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
}
.allinone-partner-post-body-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  .allinone-partner-post-body-information{
    flex-grow:1;
  }

  .allinone-partner-post-body-image{
    width: 72px;
    height: 72px;
    border-radius: 8px;
    object-fit: cover;
    transform: translateZ(0);

  }
}