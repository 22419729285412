.viewerbody-container {
  background: #fff;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
}

.viewerbody-container-updated {
  border: 1px solid #fd6128;
}

.viewerbody-container-inner {
  width: 100%;
  padding: 20px 20px 40px;
  display: flex;
  flex-direction: column;
}

.viewerbody-profile-container {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.viewerbody-deleted {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;

  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 26px;
  color: #6b6b6b;
  margin-left: 20px;
}

.viewerbody-profile-container-padding {
  padding-left: 20px;
}

.viewerbody-body-container {
  font-size: 14px;
  line-height: 22px;
  white-space: pre-wrap;
  color: #171717;
}

.viewerbody-keywords-container {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.viewerbody-keyword-separate {
  font-size: 11px;
  line-height: 17px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  margin-right: 4px;
  color: #2d9cdb;
}

.viewerbody-images-container {
  width: 100%;
}

.viewerbody-image {
  height: auto;
  width: 100%;
  /* max-height: 240px; */
  margin-top: 16px;
  border-radius: 8px;
}

.ion-color-viewerbody-more {
  --ion-color-base: #313131;
  --ion-color-contrast: #313131;
  --ion-color-shade: #aeaeae;
  --ion-color-tint: #aeaeae;
  --padding-end: 0px;
  height: 20px;
}

.viewerbody-links-container {
  margin-bottom: 5px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */

  /* 시그니처컬러 */

  color: #2d9cdb;
}

.viewerbody-links {
  font-size: 10px;
  line-height: 14px;
  text-decoration: unset;

  color: #ababab;
}

.viewerbody-title-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.viewerbody-title {
  display: inline-block;
  line-height: 23px;
  font-weight: 800;

  font-size: 17px;
  letter-spacing: -0.04em;
  color: #171717;
  margin-bottom: 10px;
}

.viewerbody-title-icon {
  vertical-align: middle;
  margin-right: 5px;
  margin-top: 0px;
  width: 17px;
  height: 17px;
}

.viewerbody-title-nice-icon {
  margin-right: 5px;
  margin-top: -4px;
  min-width: 28px;
  height: 40px;
}

.viewerbody-toolbar-container {
  display: flex;
  flex-direction: row;
  border-top: 0.5px solid #dadada;
  margin-top: 14px;
  padding-top: 7px;
}

.viewerbody-files-container {
  width: 100%;
}

.viewerbody-keywords {
  font-size: 14px;
  line-height: 22px;

  color: #171717;
  font-weight: bold;
}

.viewerbody-keywords-icon {
  padding-top: 4px;
  height: 14px;
  width: 14px;
}

.viewerbody-reply-flag {
  position: absolute;
  width: 0px;
  border-radius: 13px 0px 0px 0px;
  border-bottom: 28px solid #0000;
  border-left: 28px solid #fd6128;
  border-top: 28px solid #fd6128;
  border-right: 28px solid #0000;
}

.viewerbody-reply-name {
  position: absolute;
  left: 2px;
  top: 7px;

  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 22px */
  font-weight: bold;
  letter-spacing: 0.05em;

  /* 화이트 */

  color: #ffffff;
  transform: rotate(-45deg);
}

.viewerbody-reply-flag-new {
  position: absolute;
  left: 3px;
  top: 3px;
  color: #fff;
  text-shadow: -1px -1px 0 #eb5757, 1px -1px 0 #eb5757, -1px 1px 0 #eb5757, 1px 1px 0 #eb5757;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  transform: rotate(-45deg);
}

.viewerbody-unread-flag {
  margin-left: 50px;
  margin-bottom: 5px;
  padding: 4px 6px;
  background: #2d9cdb;
  border-radius: 1px;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
}

.viewerbody-unread-flag-container {
  display: flex;
  flex-direction: row;
  margin-top: -10px;
}

.viewerbody-cardview-container {
  width: 100%;
  /* max-height: 70vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #fff;
  margin-bottom: 10px;
}

.viewerbody-cardview-swipe {
  width: 100%;
}

.viewerbody-cardview-image {
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
}

.viewerbody-cardview-back-button {
  height: 50px;
  width: 50px;
  color: #fff8;
  z-index: 50;
  position: absolute;
  -webkit-filter: drop-shadow(1px 1px #8888);
  filter: drop-shadow(1px 1px #8888);
}

.viewerbody-cardview-back-button-image {
  height: 50px;
  width: 50px;
}

.viewerbody-cardview-page-count {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  color: #222222;
  padding-right: 13px;
  padding-bottom: 10px;
}
