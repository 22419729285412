.mmenu-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: #0000;
  transition: transform 0.2s cubic-bezier(0, 0.52, 0, 1);
}

.mmenu-inner-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.mmenu-container.mmenu-slide-in {
  background: #0008 !important;
}

.mmenu-container.mmenu-slide-out {
  /* transform: translate3d(100px, 0, 0) !important; */
}

.mmenu-content {
  width: 100%;
  max-width: 450px;
  height: 100%;
  right: 0px;
  top: 0px;
  background: #2d9cdb;
  position: absolute;
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  transform: translate3d(min(100%, 450px), 0, 0);
}

.mmenu-content.mmenu-slide-in {
  transform: translate3d(0px, 0, 0) !important;
}

.mmenu-content.mmenu-slide-out {
  /* transform: translate3d(100px, 0, 0) !important; */
}

.mmenu-content-inner {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.mmenu-background {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: -1;
}

.ion-color-mmenu-close {
  position: absolute;
  top: 0px;
  right: 0px;
  --color: #ececec;
  --padding-start: 13px;
  --padding-end: 16px;
  --box-shadow: none;
  margin: 0px;
  z-index: 5;
}

.ion-color-mmenu-close2 {
  position: absolute;
  bottom: 15px;
  right: 10px;
  --color: #ececec;
  --padding-start: 13px;
  --padding-end: 16px;
  --box-shadow: none;
  margin: 0px;
  z-index: 5;
}

.mmenu-close-icon {
  width: 23px;
  height: 23px;
}

.mmenu-user-info-container {
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 5px;
  display: flex;
  flex-direction: row;
}

.mmenu-user-info-profile-container {
  height: 108px;
  width: 108px;
  min-width: 108px;
  padding: 0px 9px;
  border-radius: 54px;
  background: #7ec4ec;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
}

.mmenu-user-info-profile {
  height: 90px;
  width: 90px;
  border-radius: 45px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
}

.mmenu-user-info-data-container {
  flex-grow: 1;
}

.mmenu-user-info-data-name {
  display: flex;
  align-items: center;
  
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 18px;
  flex-grow: 1;
  letter-spacing: 0.1em;
  color: #ffffff;
}

.ion-color-mmenu-profile-change-button {
  height: 22px;
  --ion-color-base: #fff;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  --border-radius: 15px;
  --padding-start: 10px;
  --padding-end: 10px;
  font-size: 11px;
  line-height: 11px;
  text-align: center;
  letter-spacing: 0.02em;

  color: #2d9cdb;
}

.mmenu-user-info-data-statistics {
  margin-left: 20px;
  margin-top: 20px;
  width: 60px;
  border-bottom: 1.5px solid #ffffff;

  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  text-align: center;
  line-height: 22px;
  color: #ffffff;
}

.mmenu-user-info-data-statistics-container {
  margin-top: 15px;
  /* padding-left: 10px;
    padding-right: 10px; */
}

.mmenu-user-info-data-statistics-item {
  width: 25%;
}

.mmenu-user-info-data-statistics-item-title {
  width: 32px;

  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 153.23%;
  /* identical to box height, or 20px */
  padding: 0px 3.5px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2px;
  text-align: center;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;

  /* 시그니처컬러 */
  background: #fff;
  color: #2d9cdb;
}

.mmenu-user-info-data-statistics-item-value {
  margin-top: 10px;

  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
}

.mmenu-latest-commented-container {
  margin-top: 20px;
  width: 100%;
}

.mmenu-latest-commented-title {
  padding-left: 25px;

  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 100%;

  color: #ffffff;
}

.mmenu-latest-commented-items-container {
  /* width: 100%; */
  width: 100%;
  margin-top: 8px;
  overflow-x: scroll;
  /* overflow-y: auto; */
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-x: none;
}

.mmenu-latest-commented-items-inner-container {
  white-space: nowrap;
  position: relative;
  padding-left: 25px;
  padding-right: 25px;
}

.mmenu-latest-commented-item-container-outer {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 75px;
  padding-right: 10px;
  margin-right: 7px;
  background: #fefefe;
  border-radius: 5px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
}

.mmenu-latest-commented-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  height: 100%;
}

.mmenu-latest-commented-item-profile-container {
  width: 64px;
  height: 75px;

  margin-right: 7px;
}

.mmenu-latest-commented-item-profile {
  width: 38px;
  height: 38px;
  border-radius: 19px;
  margin-left: 13px;
  margin-top: 10px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
}

.mmenu-latest-commented-item-profile-name {
  width: 64px;
  text-align: center;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
}

.mmenu-latest-commented-item-profile-no-comment {
  width: 64px;
  height: 64px;
  margin-right: 7px;
}

.mmenu-latest-commented-item-text {
  width: calc(100% - 64px);
  max-width: calc(100% - 64px);

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #171717;
}

.mmenu-submenu-container {
  min-height: calc(100% - 326px);
  margin-top: 10px;
  padding: 0px 25px;
  display: flex;
  flex-direction: column;
}

.mmenu-submenu-item-container {
  width: 100%;
  height: 60px;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgba(244, 244, 244, 0.5);
}

.mmenu-submenu-item-container-last {
  border: none !important;
}

.mmenu-submenu-item-img {
  width: 22px;
  height: 22px;
}

.mmenu-submenu-item-icon {
  width: 26px;
  height: 26px;
  margin-right: -4px;
  color: #fff;
}

.mmenu-submenu-item-text {
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 100%;
  color: #ffffff;
  padding-left: 15px;
  position: relative;
}

.mmenu-submenu-item-container-fixed {
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 20px;
  left: 0px;
}

.mmenu-submenu-item-new {
  position: absolute;
  right: -22px;
  top: -11px;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
  background: #ff3d00;
  color: #fff;

  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
}
