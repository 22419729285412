.ytp-show-cards-title {
  visibility: hidden;
}

.video-play-player {
  width: 100%;
}

.video-player-toggle-selected {
  background: #2d9cdb !important;
  color: #f2f2f2 !important;
}

.video-player-toggle {
  height: 20px;
  padding: 5px 10px;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 11px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  background: #fff;
  color: #a1a0a5;
}
