.setting-content {
  width: 100%;
  --background: #dae8ef;
  /* --background: #F00; */
}

.setting-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  align-items: center;
}

.setting-header {
  height: 43px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.ion-color-setting-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --min-height: 43px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 43px;

  font-size: 16px;
  line-height: normal;

  color: #a1a0a5;
}

.setting-title {
  font-size: 14px;
  line-height: normal;

  color: #171717;
  flex-grow: 1;
}

.setting-body-container {
  background: #fff;
  width: calc(100% - 16px);
  margin-top: 13px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 20px;
  border-radius: 13px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.setting-item-container {
  display: flex;
  flex-direction: row;
  margin-top: 13px;
  align-items: flex-start;
  --ion-safe-area-right: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --background: #0000;
  --border-color: rgba(244, 244, 244, 0.5);
}

.setting-item-inner {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  align-items: center;
  padding-left: 16px;
  padding-right: 5px;
}

.setting-item-container_last {
  --border-color: rgba(244, 244, 244, 0);
}

.ion-color-setting-button {
  --ion-color-base: #fff;
  --ion-color-contrast: #a1a0a5;
  --box-shadow: none;
}

.setting-version {
  font-size: 12px;
  line-height: normal;
  margin-right: 20px;

  color: #a1a0a5;
}

.setting-title-red {
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.04em;

  color: #eb5757;
}

.setting-title-hide {
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.04em;

  color: #a1a0a5;
}

.setting-kakao {
  height: 30px;
  width: 30px;
}
