.login-container {
  height: 100%;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-catchphase {
  width: 100%;
  margin-top: 50px;
  text-align: center;

  font-size: 17px;
  line-height: 22px;
  /* font-weight: bold; */
  color: #171717;
}

.login-catchphase-normal {
  margin-bottom: 5px;
}

.login-catchphase-larger {
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 5px;
}

.login-kakao-button {
  width: 250px;
  height: 45px;
  --background: #fddd0a;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  --border-radius: 0px;
}

.login-kakao-logo {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.login-kakao-text {
  font-size: 17px;
  line-height: 100%;
  text-align: center;
  font-weight: bold;

  color: #381f1f;
}

.login-apple-button {
  margin-top: 20px;
  width: 250px;
  height: 45px;
  --background: #fff;
  --border-width: 1px;
  --border-color: #000;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  --border-radius: 0px;
}

.login-apple-logo {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  color: #171717;
  margin-bottom: 5px;
}

.login-apple-text {
  font-size: 17px;
  line-height: 100%;
  text-align: center;
  font-weight: bold;

  color: #171717;
}

.login-logo-h {
  margin-top: 20px;
  width: 135px;
}

.login-emoticon {
  width: 150px;
}
