.boardlist {
  width: 100%;
  padding: 0;
  background: #f7f8f9;
}

.board-list-background {
  width: 100%;
  height: 100%;
  padding: 8px;
  background: #f7f8f9;
}

.board-list-waiting-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.board-list-toolbar-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.board-list-toolbar-toggle-button {
  height: 21px;
  background: #c4c4c4;
  border-radius: 5px;

  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  padding: 5px 10px;

  /* 화이트 */

  color: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
}

.board-list-toolbar-toggle-button-active {
  background: #2d9cdb;
}

.ion-color-board-list-toolbar-button {
  --padding-start: 5px;
  --padding-end: 5px;
  height: 21px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #171717;

  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
}

.board-list-toolbar-button-icon {
  height: 21px;
  font-size: 21px;
  line-height: 21px;
}

.ion-color-board-list-fab {
  --ion-color-base: #fff8;
  --ion-color-contrast: #000;
  --ion-color-shade: #fff8;
  --padding-start: 0px;
  --padding-end: 0px;
  /* width: 84px;
    height: 84px; */
  width: 40px;
  height: 40px;
  --border-radius: 20px;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  position: absolute;
  bottom: 0px;
  left: calc(50% - 20px);
  z-index: 5;
}

.board-list-fab-icon {
  color: #2d9cdb88;
  width: 20px;
  height: 20px;
  --font-size: 20px;
}
