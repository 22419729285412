.action-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
}

.action-button-2 {
  height: 35px;
  flex-grow: 3;
}

.action-button-3 {
  height: 50px;
  flex-grow: 3;
}

.ion-color-action-button {
  --ion-color-base: #2d9cdb;
  --ion-color-contrast: #2d9cdb;
  --ion-color-shade: #2d9cdb;
  --ion-color-tint: #2d9cdb;
  color: #2d9cdb;
}

.ion-color-action-button-inactive {
  --ion-color-base: #a1a0a5;
  --ion-color-contrast: #2d9cdb;
  --ion-color-shade: #2d9cdb;
  --ion-color-tint: #2d9cdb;
  color: #a1a0a5;
}

.ion-color-action-button-emphasis {
  --ion-color-base: #333333;
  --ion-color-contrast: #2d9cdb;
  --ion-color-shade: #2d9cdb;
  --ion-color-tint: #2d9cdb;
  color: #333333;
}

.action-button-icon {
  width: 21px;
  height: 21px;
  font-size: 21px;
  /* object-fit: cover; */
}

.action-button-text {
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: capitalize;
}

.action-button-text-icon {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 100%;
  padding-bottom: 6px;
}
