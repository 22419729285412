.seminar-text-index {
    text-indent: -13px;
    margin-left: 13px;
}

.seminar-text-underline{
    text-decoration: underline;

}

.seminar-list-item-thumbnail{
    min-width: 80px;
    height: 80px;
    position: relative;
    --size: 78px;
    overflow: hidden;
}

.seminar-list-item-playtime{
    position: absolute;
    right: 8px;
    top: 8px;

}

.seminar-list-item-progress-container{
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 12px;
    padding: 4px;
    background: rgba(20, 32, 58, 0.5);
    backdrop-filter: blur(6px);
}