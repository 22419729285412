.user-search-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user-search-input {
  flex-grow: 1;
  opacity: 1 !important;
}

.user-search-level {
  width: 30%;
}

.user-admin-list-container {
  flex-grow: 1;
  width: 100%;
}

.user-admin-list-item-profile {
  margin-left: 10px;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.user-admin-list-item-container {
  width: 100%;
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #a1a0a5;

  font-size: 15px;
  line-height: 16px;

  color: #6b6b6b;
}

.user-manage-popup-container {
  --width: 100%;
  --height: 80%;
  --background: #0000;
  padding-left: 8px;
  padding-right: 8px;
}

.user-manage-container {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 13px;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 17px;
  padding-right: 17px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #dadada;
}

.user-manage-title {
  /* width: 100%; */
  /* margin-top: 10px;
    margin-bottom: 5px; */
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.04em;
  font-weight: bold;
  white-space: nowrap;
}

.user-manage-level-container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user-manage-textarea {
  border: 1px solid #686868;
}

.user-manage-level {
  flex-grow: 1;
}

.user-manage-description {
  width: 100%;

  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.02em;

  color: #6b6b6b;
}

.user-manage-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.user-manage-button-container {
  flex-grow: 1;
}

.ion-color-user-manage-cancel-button {
  flex-grow: 1;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 30px;
  --ion-color-base: #fff;
  --border-radius: 12px;
  --border-width: 1.5px;
  --border-style: solid;
  --border-color: #686868;
  --padding-start: 25px;
  --padding-end: 25px;
  --box-shadow: none;

  font-size: 15px;
  line-height: 20px;

  color: #686868;
}

.ion-color-user-manage-button {
  flex-grow: 1;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 30px;
  --ion-color-base: #fff;
  --border-radius: 12px;
  --border-width: 1.5px;
  --border-style: solid;
  --border-color: #2d9cdb;
  --padding-start: 25px;
  --padding-end: 25px;
  --box-shadow: none;

  font-size: 15px;
  line-height: 20px;

  color: #2d9cdb;
}
.ion-color-user-manage-tight-button {
  margin-top: 16px;
  margin-bottom: 16px;
  height: 30px;
  --ion-color-base: #fff;
  --border-radius: 12px;
  --border-width: 1.5px;
  --border-style: solid;
  --border-color: #2d9cdb;
  --padding-start: 25px;
  --padding-end: 25px;
  --box-shadow: none;

  font-size: 15px;
  line-height: 20px;

  color: #2d9cdb;
}

.ion-color-user-manage-button-critical {
  flex-grow: 1;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 30px;
  --ion-color-base: rgb(255, 205, 205);
  --border-radius: 12px;
  --border-width: 1.5px;
  --border-style: solid;
  --border-color: rgb(235, 11, 11);
  --padding-start: 25px;
  --padding-end: 25px;
  --box-shadow: none;

  font-size: 15px;
  line-height: 20px;

  color: rgb(235, 11, 11);
}

.user-manage-image {
  margin-top: 10px;
  margin-bottom: 10px;
  height: auto;
  width: 100%;
}
