.admin-board-search-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.admin-board-search-top-fixed{
  position: sticky;
  top: 0px;
  z-index: 100;
  background-color: #fff;
}

.admin-board-search-input {
  flex-grow: 1;
}

.admin-board-search-level {
  width: 30%;
}

.admin-board-list-container {
  flex-grow: 1;
  width: 100%;
}

.admin-board-list-item-container {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #a1a0a5;
  font-size: 15px;
  line-height: 16px;

  color: #6b6b6b;
}

.admin-content-manage-container {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 13px;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 17px;
  padding-right: 17px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  /* display: flex;
    flex-direction: column;
    align-items: flex-start; */
}

.admin-content-manage-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.admin-content-manage-button-container {
  flex-grow: 1;
}

.ion-color-admin-content-manage-cancel-button {
  flex-grow: 1;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 30px;
  --ion-color-base: #fff;
  --border-radius: 12px;
  --border-width: 1.5px;
  --border-style: solid;
  --border-color: #686868;
  --padding-start: 25px;
  --padding-end: 25px;
  --box-shadow: none;

  font-size: 15px;
  line-height: 20px;

  color: #686868;
}

.ion-color-admin-content-manage-button {
  flex-grow: 1;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 30px;
  --ion-color-base: #fff;
  --border-radius: 12px;
  --border-width: 1.5px;
  --border-style: solid;
  --border-color: #2d9cdb;
  --padding-start: 25px;
  --padding-end: 25px;
  --box-shadow: none;

  font-size: 15px;
  line-height: 20px;

  color: #2d9cdb;
}

.ion-color-admin-content-delete-button {
  flex-grow: 1;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 30px;
  --ion-color-base: #fdd;
  --border-radius: 12px;
  --border-width: 1.5px;
  --border-style: solid;
  --border-color: #f00;
  --padding-start: 25px;
  --padding-end: 25px;
  --box-shadow: none;

  font-size: 15px;
  line-height: 20px;

  color: #2d9cdb;
}

.ql-editor {
  max-height: 400px;
}

.keywords {
  color: #f00;
  font-weight: bold;
  user-select: text;
}
