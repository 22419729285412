.notification-bell-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  font-size: 20px;
  position: relative;
}

.notification-bell-button-inactive {
  visibility: visible;
  color: #ced3da;
}

.notification-bell-button-active {
  visibility: visible;
  color: var(--color-active, #2d9cdb);
}

.ion-color-notification-button {
  --ion-color-base: #fff;
  --box-shadow: none;
  --padding-start: 8px;
  --padding-end: 8px;
}

.ion-color-notification-bell-count {
  --ion-color-base: #e94d45;
  --padding-top: 0px;
  --padding-end: 0px;
  --padding-bottom: 0px;
  --padding-start: 0px;
  position: absolute;
  top: 0px;
  right: 7px;
  height: 15px;
  min-width: 15px;
  border-radius: 7.5px;
  font-size: 11px;
  line-height: 15px;
  color: #fff;
}
