.viewreply-report {
  width: calc(100% - 16px);
  height: 48px;
  background: #2d9cdb;
  border-radius: 13px 13px 0px 0px;
  margin-bottom: -16px;
  margin-right: 8px;
  margin-left: 8px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 10.5px;
  padding-bottom: 10.5px;
  font-size: 11px;
  line-height: 17px;
  letter-spacing: -0.02em;


  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;

  color: #ffffff;
}

.viewreply-suggest-container {
  width: calc(100% - 16px);
  margin-right: 8px;
  margin-left: 8px;
  background: #2d9cdb;
  /* 시그니처컬러 */
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 13px;

  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 20px;
}

.viewreply-suggest-text {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 150%;
  /* or 25px */

  letter-spacing: -0.04em;

  /* 화이트 */
  flex-grow: 1;

  color: #ffffff;
}

.viewreply-suggest-img {
  width: 90px;
  height: 90px;
}
