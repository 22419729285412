.register3-container {
  width: 100%;
  height: 100%;
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #dae8ef;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.register3-service-description {
  width: 220px;

  font-size: 12px;
  line-height: 17px;
  text-align: center;

  color: #6b6b6b;
  margin-bottom: 5px;
}

.register3-body-container {
  background: #fff;
  width: calc(100% - 16px);
  margin-top: 5px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 23px;
  padding-bottom: 23px;
  border-radius: 13px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.register3-item-container {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  align-items: flex-start;
}

.register3-item-name {
  min-width: 30px;
  margin-top: 3px;
  margin-right: 10px;

  font-size: 15px;
  line-height: normal;
  letter-spacing: 0.1em;

  color: #888888;
}

.register3-item-input {
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  margin-left: 10px;
  margin-right: 10px;

  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.1em;

  color: #171717;

  border-bottom: 0.5px solid #a1a0a5;
}

.register3-item-textarea {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;

  color: #6b6b6b;
}

.register3-profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.register3-profile-inner {
  width: 100px;
  height: 100px;
  position: relative;
}

.register3-profile-image {
  border-radius: 50px;
}

.register3-profile-upload-button-container {
  height: 28px;
  width: 28px;
  bottom: 5px;
  right: 5px;
  position: absolute;
}

.register3-profile-upload-button-inner {
  height: 100%;
  width: 100%;
  position: relative;
}

.register3-profile-upload-button {
  height: 100%;
  width: 100%;
  --padding-start: 25px;
  --padding-end: 25px;
  margin: 0px;
  background: #2d9cdb;
  /* 시그니처컬러 */
  border-radius: 14px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;

  color: #fff;
}

.ion-color-register3-profile-upload-button {
  height: 100%;
  width: 100%;
  --ion-color-base: #2d9cdb;
  --border-radius: 50%;
  --padding-start: 0px;
  --padding-end: 0px;
  margin: 0px;
  color: #fff;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
}

.register3-profile-upload-file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.register3-profile-upload-button-icon {
  width: 18px;
  height: 18px;
  margin-right: 2px;
  color: #fff;
}

.register3-upload-button-text {
  font-size: 14px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.1em;

  color: #a1a0a5;
}

.register-survey-title-margin {
  margin-bottom: 40px;
}

.register3-survey-item-container {
  padding-left: 14px;
  /* padding-right: 14px; */
  margin-bottom: 20px;
}

.register3-survey-item {
  margin-bottom: 10px;
}

.register3-survey-subitem {
  padding-left: 30px;
}

.ion-color-register3-checkbox {
  margin-right: 13px;
  --background-checked: #2d9cdb;
  --border-color-checked: #2d9cdb;
  --border-color: #c4c4c4;
  --checkmark-color: #fff;
}

.register3-checkbox-text {
  font-size: 15px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.1em;

  /* qna타이틀내용 */

  color: #171717;
}

.register3-checkbox-text-input {
  flex-grow: 1;

  padding-left: 10px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  min-width: 0px;

  font-size: 16px;
  line-height: normal;
  /* letter-spacing: 0.1em; */

  color: #171717;
  border: none;
  border-bottom: 0.5px solid #a1a0a5;
  outline-style: none;
  background-color: #fff;
}

.register3-checkbox-text-code {
  min-width: 120px;
  margin-right: 10px;
}
