
.seminar-review-done-popup-container {
    --width: 100%;
    /* --height: 240px; */
    --background: #0000;


    .seminar-review-done-popup-inner{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 0px 24px;

        .seminar-review-done-popup-backdrop {
            flex-grow: 1;
            width: 100%;
        }
        
        
        
        .seminar-review-done-container-outer {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
        
        
            .seminar-review-done-container {
                width: 100%;
                max-width: 360px;
                background: #FFFFFF;
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
                border-radius: 20px;
                padding: 24px;
                overflow: scroll;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;

                .seminar-review-done-body-container{
                    width: 100%;
                    padding-top: 36px;
                    padding-bottom: 60px;
                    flex-direction: column;
                    text-align: center;
                    align-items: center;

                    .seminar-review-done-body-icon{
                        margin-bottom: 20px;
                        width: 100%;

                    }

                    .seminar-review-done-body-title{
                        width: 100%;
                    }
                }

                .seminar-review-done-buttons-container{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    .seminar-review-done-button-gap{
                        width: 12px;
                    }

                    .seminar-review-done-button{
                        width: calc(50% - 6px);
                    }
                }
            }
        }
    }
}




  