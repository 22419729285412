html {
  touch-action: manipulation;
}

body {
  /* padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left); */
  margin-top: env(safe-area-inset-top) !important;
  margin-bottom: 0px !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}

* {
  -webkit-overflow-scrolling: touch;
  -webkit-user-select: none;
          user-select: none;
}

input,
textarea {
  -webkit-user-select: auto;
          user-select: auto;
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
textarea,
p,
blockquote,
th,
td,
input,
select,
textarea,
button {
  margin: 0;
  padding: 0;
}
fieldset,
img {
  border: 0 none;
}
dl,
ul,
ol,
menu,
li {
  list-style: inherit;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
input,
select,
textarea,
button {
  font-size: 100%;
  vertical-align: middle;
}
button {
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  -webkit-text-size-adjust: none;
} /* 뷰표트 변환시 폰트크기 자동확대 방지 */
input[type="text"],
input[type="password"],
input[type="submit"],
input[type="search"] {
  -webkit-appearance: none;
  border-radius: 0;
}
input:checked[type="checkbox"] {
  background-color: #666;
  -webkit-appearance: checkbox;
}
button,
input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="file"] {
  -webkit-appearance: button;
  border-radius: 0;
}
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
a {
  color: #333;
  text-decoration: none;
}
a:active,
a:hover {
  text-decoration: none;
}
address,
caption,
cite,
code,
dfn,
em,
var {
  font-style: normal;
  font-weight: normal;
}

/* Ionic Variables and Theming. For more information, please see
// https://beta.ionicframework.com/docs/theming/
// The app direction is used to include
// rtl styles in your app. For more information, please see
// https://beta.ionicframework.com/docs/layout/rtl
// $app-direction: ltr;
// Ionic Colors
// --------------------------------------------------
// Named colors makes it easy to reuse colors on various components.
// It's highly recommended to change the default colors
// to match your app's branding. Ionic provides eight layered colors
// that can be changed to theme an app. Additional colors can be
// added as well (see below). For more information, please see
// https://beta.ionicframework.com/docs/theming/advanced
// To easily create custom color palettes for your app’s UI,
// check out our color generator:
// https://beta.ionicframework.com/docs/theming/color-generator
*/

:root {
  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;

  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

/* Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the CSS variables found in Ionic's source files.
// To view all the possible Ionic variables, see:
// https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables
*/

:root {
  --ion-headings-font-weight: 300;

  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;
  --ion-font-family: "Pretendard", -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    sans-serif;
}

.md {
  --ion-toolbar-background: var(--ion-color-primary);
  --ion-toolbar-color: #fff;
  --ion-toolbar-color-activated: #fff;
}

.ion-page {
  --background: #ffffff;
  /* background: #fff; */
  -webkit-user-select: none;
          user-select: none;
}

header, main,
#app {
  background: #ffffff;
}

body {
  word-break: keep-all;
  background: #e4ebed;
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    sans-serif !important;
}

button div a {
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    sans-serif !important;
}

code,
kbd,
pre,
samp {
  font-family: "Pretendard", -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    sans-serif !important;
}

.footer {
  background: #ffffff;
}

::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "YanoljaYache";
  font-style: normal;
  font-weight: bold;
  src: url(/static/media/YanoljaYacheBold.0bc2ea36.ttf);
  src: url(/static/media/YanoljaYacheBold.0bc2ea36.ttf) format("truetype");
}

@font-face {
  font-family: "YanoljaYache";
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/YanoljaYacheRegular.d00bc514.ttf);
  src: url(/static/media/YanoljaYacheRegular.d00bc514.ttf) format("truetype");
}

@font-face {
  font-family: "Digital Numbers";
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/DigitalNumbers-Regular.df8665d3.ttf);
  src: url(/static/media/DigitalNumbers-Regular.df8665d3.ttf) format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 300;
  letter-spacing: -0.05em;
  src: url(/static/media/Pretendard-Light.aef3dc5f.otf);
  src: url(/static/media/Pretendard-Light.aef3dc5f.otf) format("opentype");
  unicode-range: U+AC00- U+D7A3;
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.05em;
  src: url(/static/media/Pretendard-Regular.46b0c48a.otf);
  src: url(/static/media/Pretendard-Regular.46b0c48a.otf) format("opentype");
  unicode-range: U+AC00- U+D7A3;
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.05em;
  src: url(/static/media/Pretendard-Medium.9ac30a1a.otf);
  src: url(/static/media/Pretendard-Medium.9ac30a1a.otf) format("opentype");
  unicode-range: U+AC00- U+D7A3;
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.05em;
  src: url(/static/media/Pretendard-SemiBold.d9d912f2.otf);
  src: url(/static/media/Pretendard-SemiBold.d9d912f2.otf) format("opentype");
  unicode-range: U+AC00- U+D7A3;
}

@font-face {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.05em;
  src: url(/static/media/Pretendard-Bold.e93f7970.otf);
  src: url(/static/media/Pretendard-Bold.e93f7970.otf) format("opentype");
  unicode-range: U+AC00- U+D7A3;
}

/* Action-Sheet menu Item Styles */
.common-menu-normal {
  font-weight: 800;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.04em;

  --color: #000000;
}

.common-menu-hightlight {
  font-weight: 800;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.04em;

  --color: #2d9cdb;
}
.common-menu-red {
  font-weight: 800;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  letter-spacing: -0.04em;

  --color: #ed513b;
}

.common-background {
  background: #ffffff;
}

.common-small-outer-container {
  width: 100%;
  height: 100%;
  max-width: 360px;
  max-height: min(100%, 780px);
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
}

.ion-color-common-header-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #3f4d5d;
  --ion-color-tint: #39393980;

  --min-height: 43px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 43px;
}

.ion-color-common-header-composer-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #3f4d5d;
  --ion-color-tint: #39393980;

  --min-height: 53px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 53px;

  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.common-header-toolbar-button {
  z-index: 50;
}

.common-container-full {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.common-background-white {
  background: #fff;
}

.common-hidden {
  display: none;
  visibility: hidden;
}

.common-header {
  height: 43px;
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08); */
  position: relative;
}

.common-header-composer {
  height: 53px;
}

.common-header-title-center {
  width: 100%;
  text-align: center;
  color: #393939;
}

.common-header-title {
  color: #393939;
}

.common-content-with-header {
  width: 100%;
  height: calc(100% - 43px);
  max-height: calc(100% - 43px);
}

.common-content-with-header-footer {
  width: 100%;
  height: calc(100% - 89px);
  max-height: calc(100% - 89px);
}

.common-footer-container {
  width: 100%;
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.common-footer-button {
  height: 46px;
  width: 100%;
  /* padding-top: 10px; */

  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 150%;
  text-align: center;

  /* 화이트 */

  color: #ffffff;
}

.common-footer-button-caution {
  --ion-color-base: #ed6236;
  background: #ed6236 !important;
}

.common-footer-button-danger {
  --ion-color-base: #eb5757;
  background: #eb5757 !important;
}

.common-footer-button-normal {
  --ion-color-base: #2667eb;
  background: #2667eb !important;
}

.common-footer-button-text {
  flex-grow: 1;
  align-items: center;
  width: 100%;

  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 16px;
  color: #ffffff;
  margin-right: 5px;
  margin-left: 5px;
}

.common-content-with-composer-header {
  width: 100%;
  max-height: 100%;
  height: calc(100% - 53px);
}

.common-content {
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: relative;
}

.common-block {
  padding: 20px 24px;
}

.common-block-sub {
  width: 100%;
  padding: 16px;
  background: #f5f9fa;
  border-radius: 12px;
}

.common-block-line {
  padding: 0px 24px;
}

.common-bottom-space {
  height: 40px;
}

.common-bottom-footer-space {
  height: 126px;
}

.common-scroll {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.common-scroll-x {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}

.common-nowrap {
  white-space: nowrap;
  flex-wrap: nowrap;
}

.common-no-scroll {
  overflow-y: none;
  /* -webkit-overflow-scrolling: none; */
}

.common-position-relative {
  position: relative;
}

.common-position-absolute {
  position: absolute;
}

.common-background-white {
  background: #fff;
}

.common-list {
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.common-bold {
  font-weight: bold;
}

.common-color-highlight {
  color: #2d9cdb !important;
}

.common-color-caution {
  color: #f32e6d !important;
}

.common-color-point {
  color: #fd6128 !important;
}

.common-text-align-right {
  width: 100%;
  text-align: right;
}

.common-text-align-left {
  text-align: left;
}

.common-text-align-center {
  width: 100%;
  text-align: center;
}

.common-container {
  width: 100%;
}

.common-container-column {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.common-container-row {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
}

.common-container-row-wrap {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}

.common-flex-align-start {
  align-items: start;
}

.common-flex-align-center {
  align-items: center;
}

.common-flex-align-end {
  align-items: flex-end;
}

.common-flex-auto {
  flex: 1 1 auto;
}

.common-flex-grow {
  flex-grow: 1;
}

.common-flex-grow-2 {
  flex-grow: 2;
}

.common-flex-column {
  display: flex;
  flex-direction: column;
}

.common-flex-row {
  display: flex;
  flex-direction: row;
}

.common-flex-row-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}

.common-modal-full-popup-container {
  --width: 100%;
  --height: 100%;
  --background: #0000;
  padding: 0px;
}

.common-modal-popup-container {
  --width: 100%;
  /* --height: 240px; */
  --background: #0000;
  padding-left: 8px;
  padding-right: 8px;
}

.common-modal-popup-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.common-modal-popup-inner-max360 {
  width: 100%;
  max-width: 360px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.common-modal-popup-backdrop {
  flex-grow: 1;
  width: 100%;
}

.common-modal-container {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 13px;
  padding-top: 40px;
  padding-bottom: 21px;
  padding-left: 21px;
  padding-right: 21px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.common-modal-container-outer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.common-modal-container-max360 {
  max-width: 360px;
}

.ion-color-common-modal-popup-close {
  --color: #888888;
  --padding-start: 13px;
  --padding-end: 16px;
  --box-shadow: none;
  position: absolute;
  right: 0px;
  top: 10px;
  margin: 0px;
}

.common-modal-popup-close-icon {
  width: 18px;
  height: 18px;
}

.common-always-message {
  position: absolute;
  top: 0px;
  right: 0px;
  font-family: "NanumSqaure";
  font-size: 8px;
  font-weight: bold;
  line-height: 100%;
}

.common-max-width-container {
  margin: auto;
  max-width: 600px;
  height: 100%;
}

.common-spinner {
  margin-top: 20px;
  width: 100px;
  height: 100px;
}

.common-unselectable {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.ion-color-common-button {
  --border-radius: 0px;
  --border-width: 0px;
  --border-style: solid;
  --border-color: #0000;
  --padding-start: 0px !important;
  --padding-end: 0px !important;
  margin: 0px;
  margin-inline: 0px;
  --box-shadow: none;
  white-space: nowrap;
}

.common-file-input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.common-toggle-selected {
  background: #2d9cdb !important;
  color: #f2f2f2 !important;
}

.common-toggle {
  height: 20px;
  padding: 5px 10px;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 11px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  background: #fff;
  color: #888888;
}

@media (max-width: 600px) {
  .common-max-width-container {
    width: 100%;
    height: 100%;
  }
}

.common-text-button {
  font-size: 16px;
  line-height: normal;
  color: #2d9cdb;
  font-weight: bold;
  padding-right: 10px;
}

.common-text-button-disabled {
  font-size: 16px;
  line-height: normal;
  color: #c4c4c4;
  padding-right: 10px;
}

.common-ad-tag {
  margin-left: 10px;
  padding: 2px 5px;
  background: #f2c94c;
  border-radius: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 100%;
  /* identical to box height, or 10px */

  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
}

.ion-color-common-checkbox {
  --checkmark-color: #fff;
  --background-checked: #2d9cdb;
  --border-color-checked: #2d9cdb;
  margin-right: 10px;
}

.ion-color-common-move-to-top-fab {
  --ion-color-base: #fff8;
  --ion-color-contrast: #000;
  --ion-color-shade: #fff8;
  --padding-start: 0px;
  --padding-end: 0px;
  /* width: 84px;
  height: 84px; */
  width: 40px;
  height: 40px;
  --border-radius: 20px;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 0px;
  left: calc(50% - 20px);
  z-index: 5;
}

.common-move-to-top-fab-icon {
  color: #2d9cdb88;
  width: 20px;
  height: 20px;
  --font-size: 20px;
}

.common-effect-parent {
  overflow: hidden;
}

.common-effect-parent > .common-effect-ripple {
  background-color: #0004;
  border-radius: 50%;
  pointer-events: none;
  position: absolute;
  -webkit-transform: scale(0);
          transform: scale(0);
}

.common-effect-parent > .common-effect-start {
  -webkit-transform: scale(0.2);
          transform: scale(0.2);
}

.common-effect-parent > .common-effect-active {
  -webkit-transform: scale(2);
          transform: scale(2);
  transition: opacity 700ms, -webkit-transform 700ms;
  transition: transform 700ms, opacity 700ms;
  transition: transform 700ms, opacity 700ms, -webkit-transform 700ms;
  opacity: 0.2;
}

.common-effect-parent > .common-effect-hover {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: #000;
  opacity: 0;
  pointer-events: none;
}

@media (hover: hover) {
  .common-effect-parent:hover > .common-effect-hover {
    opacity: 0.05;
    transition: all 0.2s ease;
  }
}

.common-position-sticky {
  position: -webkit-sticky;
  position: sticky;
  z-index: 100;
}

#app {
  width: 100%;
  height: 100%;
}

ion-app.ion-page {
  margin: auto;
  max-width: 600px;
  height: 100%;
}

ion-app.common-content {
  margin: auto;
  max-width: 600px;
  height: 100%;
}

ion-app.ion-page.admin {
  margin: auto;
  max-width: unset;
  width: 100%;
  height: 100%;
}

@media (max-width: 600px) {
  ion-app.ion-page {
    width: 100%;
    height: 100%;
  }

  ion-app.common-content {
    width: 100%;
    height: 100%;
  }
}
.Toastify__toast-container {
}

.Toastify__toast {
  background: #2d9cdb !important;
}

.Toastify__toast-body {
  width: 100%;
  background: #2d9cdb !important;
}

.Toastify__toast-body > div {
  width: 100%;
}

.app-chat-noti-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */

  letter-spacing: -0.2px;

  /* 화이트 */
  color: #ffffff;
}

.app-chat-noti-profile {
  width: 38px;
  height: 38px;
  border-radius: 19px;
  margin-right: 8px;
}

.app-chat-noti-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.app-chat-noti-body {
}

.home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  -ms-overflow-style: none;
}

.home-logo-container {
  position: relative;
  width: 100%;
  height: 151px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-logo {
  margin-top: 50px;
  width: 64px;
  height: 86px;
}

.home-alarm {
  position: absolute;
  top: 0px;
  right: 0px;
  --ion-color-base: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home-admin {
  color: #f88;
  --ion-color-base: #fff;
  --background: #fff;
  --box-shadow: none;
}

.home-qustion-suggest {
  height: 41px;
  margin-left: 15px;
  margin-right: 15px;

  border: 1.5px solid #2d9cdb;
  box-sizing: border-box;
  border-radius: 7px;

  /* 아이콘라인컬러 */

  color: #a1a0a5;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
}

.home-qustion-suggest-text {
  flex-grow: 1;

  font-size: 16px;
  text-align: left;
  line-height: 136.2%;
  /* identical to box height, or 22px */

  letter-spacing: -0.04em;
}

.home-qustion-suggest-icon {
  width: 20px;
  height: 20px;
}

.home-suggestion-list {
  margin-top: 35px;
  width: 100%;
}

.ion-color-home-fab {
  --ion-color-base: #fd6128;
  --ion-color-contrast: #0000;
  --ion-color-shade: #ff9158;
}

.home-fab-text {
  color: #fff;

  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.1em;
  font-weight: bold;
}

.home-suggestion-container {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-suggestion-finished-text {
  font-size: 12px;
  line-height: 16px;
  /* or 16px */

  text-align: center;

  color: #000000;
}

.home-suggestion-activity {
  margin-top: 25px;
  color: #2d9cdb;

  padding-left: 20px;
  padding-right: 20px;
  height: 28px;

  font-size: 12px;
  line-height: 130%;
  /* or 16px */

  display: flex;
  align-items: center;
  text-align: center;

  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 20.5px;
}

.home-suggestion-activity-item-container {
  margin-top: 15px;
  margin-right: 15px;
  margin-left: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 15px;
  padding-right: 0px;

  width: calc(100% - 30px);

  display: flex;
  flex-direction: row;
  align-items: center;

  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 13px;
}

.home-suggestion-activity-item-text {
  font-size: 12px;
  line-height: 16px;
  flex-grow: 1;
  color: #000000;
}

.ion-color-home-suggestion-activity-item-button {
  height: 29px;
  --ion-color-base: #fff;
  --padding-start: 12.5px;
  --padding-end: 12.5px;
  --box-shadow: none;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;

  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.1em;
  color: #2d9cdb;
  font-weight: bold;
}

.ion-color-home-suggestion-activity-icon-button {
  --ion-color-base: #fff;
  --ion-color-contrast: #2d9cdb;
  --ion-color-shade: #2d9cdb;
  --ion-color-tint: #2d9cdb;
  --box-shadow: none;
  --padding-start: 20px;
  --padding-end: 20px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

.home-suggestion-activity-icon {
  color: #2d9cdb;
  width: 15px;
  height: 20px;
}
.home-suggestion-activity-icon-kakao {
  height: 40px;
  width: 82px;
  --padding-start: 0px;
  --padding-end: 0px;
}

.notification-bell-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  font-size: 20px;
  position: relative;
}

.notification-bell-button-inactive {
  visibility: visible;
  color: #ced3da;
}

.notification-bell-button-active {
  visibility: visible;
  color: #2d9cdb;
  color: var(--color-active, #2d9cdb);
}

.ion-color-notification-button {
  --ion-color-base: #fff;
  --box-shadow: none;
  --padding-start: 8px;
  --padding-end: 8px;
}

.ion-color-notification-bell-count {
  --ion-color-base: #e94d45;
  --padding-top: 0px;
  --padding-end: 0px;
  --padding-bottom: 0px;
  --padding-start: 0px;
  position: absolute;
  top: 0px;
  right: 7px;
  height: 15px;
  min-width: 15px;
  border-radius: 7.5px;
  font-size: 11px;
  line-height: 15px;
  color: #fff;
}

.suggestion-item-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.suggestion-item-category {
  padding-left: 12px;
  padding-right: 12px;
  height: 28px;

  font-size: 12px;
  line-height: 130%;
  /* or 16px */

  display: flex;
  align-items: center;
  text-align: center;

  color: #171717;

  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 20.5px;
}

.suggestion-item-category-highlight {
  color: #fd6128;
}

.suggestion-item-body-container {
  padding-top: 10px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 15px;
  min-height: 120px;
  margin-top: 13px;
  margin-right: 15px;
  margin-left: 15px;
  width: calc(100% - 30px);

  display: flex;
  flex-direction: column;

  border: 1px solid #afafaf;
  box-sizing: border-box;
  /* box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.22); */
  border-radius: 4px;

  font-size: 16px;
  line-height: 25px;
  /* or 25px */

  letter-spacing: -0.01em;

  /* 프로필아이디 */

  color: #171717;
}

.suggestion-item-body-title {
  font-weight: 800;
}

.suggestion-item-body {
  width: 100%;
  height: 100%;
}

.suggestion-item-information-container {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  font-size: 10px;
  line-height: 10px;

  /* 프로필날짜조회수 */

  color: #888888;
}

.suggestion-item-information-title {
  margin-left: 10px;
}

.suggestion-item-information-count {
  margin-left: 5px;
}

.ion-color-boards-toolbar {
  --ion-toolbar-color-unchecked: #39393980;
  --ion-toolbar-color-checked: #171717;
  --ion-color-base: #2d9cdb;
  --ion-color-shade: #393939;
  --ion-color-tint: #393939;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --padding-start: 0px;
  --padding-end: 0px;

  --min-height: 53px;
  height: 53px;
}

.ion-color-boards-fab {
  --ion-color-base: #fd6128;
  --ion-color-contrast: #0000;
  --ion-color-shade: #ff9158;
  width: 84px;
  height: 84px;
}

.boards-fab-icon {
  color: #fff;
  width: 30px;
  height: 30px;
  font-size: 30px;
  font-weight: bold;
}

.ion-color-boards-fab-invite {
  --ion-color-base: #2d9cdb;
  --ion-color-contrast: #0000;
  --ion-color-shade: #2d9cdb;
  /* width: 84px;
    height: 84px; */
  width: 56px;
  height: 56px;
}

@-webkit-keyframes invite-blink {
  0% {
    border-color: #ffffff00;
    border-width: 3px;
  }
  50% {
    border-color: #ffffffaa;
    border-width: 6px;
  }
  100% {
    border-color: #ffffff00;
    border-width: 3px;
  }
}

@keyframes invite-blink {
  0% {
    border-color: #ffffff00;
    border-width: 3px;
  }
  50% {
    border-color: #ffffffaa;
    border-width: 6px;
  }
  100% {
    border-color: #ffffff00;
    border-width: 3px;
  }
}

.boards-fab-invite-border {
  position: absolute;
  width: 86px;
  height: 86px;
  border-radius: 43px;

  border-style: solid;
  -webkit-animation: invite-blink 2s infinite;
          animation: invite-blink 2s infinite;
}

.boards-header {
  height: 53px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.boards-toolbar-admin {
  color: #f88;
}

.boards-segment-button {
  --indicator-color-checked: #dae8ef !important;
  --indicator-color: #2d9cdb !important;
  --padding-start: 10px;
  --padding-end: 10px;
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  text-transform: none;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.1em;
  height: 53px;
  min-height: 53px;
  /* 상단바 제목_비활성화 */

  color: rgb(181, 183, 214) !important;
  min-width: 0px;
}

.boards-segment-button-text {
  width: 100%;
  height: 30px;
  padding-top: 5px;
  line-height: 20px;
  vertical-align: bottom;
}

.boards-segment-button.segment-button-checked {
  font-weight: 800;
  font-size: 20px;
  line-height: 20px;
  border-bottom: 4px solid #dae8ef;
  color: #ffffff !important;
}

.boards-segment-button-new {
  color: #fff;
  text-shadow: -1px -1px 0 #eb5757, 1px -1px 0 #eb5757, -1px 1px 0 #eb5757, 1px 1px 0 #eb5757;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  position: absolute;
  right: 4px;
  top: 12px;
}

.ion-color-boards-segment {
  --ion-color-base: #cecece;
  height: 53px;
  justify-content: left;
  color: #cecece;
  --padding-start: 20px;
  --padding-end: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.boards-fab-icon {
  color: #fff;
}

.boards-swipe {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* -webkit-overflow-scrolling: none; */
}

.boards-bell {
  --color-active: #fff;
}

.boards-fab-text {
  color: #fff;

  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.1em;
  font-weight: bold;
}

.boards-fab-invite-text {
  color: #fff;

  /* font-size: 22px;
    line-height: 27px; */
  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.1em;
  font-weight: bold;
}

.segment-scrollable.sc-ion-segment-md-h {
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-x: none;
}

.boardlistitem-container {
  width: 100%;
  /* --border-radius: 2px; */
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08); */
  /* margin-bottom: 8px; */
  background-color: #fff;
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #e4ebed;
}

.boardlistitem-notice-container {
  /* border: 1px solid #2d9cdb; */
}

.boardlistitem-body-inactive {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffff80;
}

.boardlistitem-banner {
  width: 100%;
  margin-bottom: 4px;
}

.boardlistitem-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  padding-left: 20px;
  padding-right: 20px;
}

.boardlistitem-notice-inner {
  align-items: center;
}

.boardlistitem-title-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 23px;
  font-weight: 800;
  font-size: 17px;
  letter-spacing: -0.04em;
  color: #171717;
  /* margin-bottom: 16px; */
  margin-bottom: 4px;
}
.boardlistitem-notice-category-container {
  background: #fffcab;
  font-weight: 800;
  font-size: 14px;
  line-height: 100%;
  /* or 14px */

  text-align: center;
  letter-spacing: -0.04em;
  color: #494949;
  padding: 3px 8px;

  margin-bottom: 10px;
}

.boardlistitem-notice-title-container {
  width: 100%;
  line-height: 23px;
  text-align: center;
  font-weight: 800;
  font-size: 17px;
  letter-spacing: -0.04em;
  color: #171717;
}

.boardlistitem-profile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
  line-height: 100%;
  padding: 0 20px;
  /* identical to box height, or 15px */

  letter-spacing: -0.02em;
  text-transform: lowercase;

  /* 프로필아이디 */

  color: #6b6b6b;
  margin-bottom: 10px;
}

.boardlistitem-profile {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 7.5px;
}

.boardlistitem-title {
  flex-grow: 1;
}

.boardlistitem-title-icon {
  vertical-align: middle;
  margin-right: 5px;
  width: 17px;
  height: 17px;
}

.boardlistitem-title-icon {
  margin-right: 4px;
  /* min-width: 30px; */
  /* height: 17px; */
  height: 20px;
  position: relative;
}

.boardlistitem-body-inner {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.boardlistitem-body-container {
  margin-bottom: 16px;
  padding: 0 20px;
}

.boardlistitem-body {
  line-height: 22px;

  font-size: 14px;
  color: #171717;

  flex-grow: 1;
}

.boardlistitem-body-text {
  width: 100%;
}

.boardlistitem-body-image {
  margin-left: 8px;
  min-width: 72px;
  width: 72px;
  height: 72px;
  border-radius: 8px;
  object-fit: cover;
}

.boardlistitem-body-big-image {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  margin-top: 10px;
}

.boardlistitem-inner-info-time {
  font-size: 11px;
  line-height: normal;
  color: #888888;
  flex-grow: 1;
}

.boardlistitem-inner-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.item-native {
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  padding-left: 0px;
}

.item-inner {
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  -webkit-padding-end: 0px;
          padding-inline-end: 0px;
}

.boardlistitem-inner-info-icon {
  vertical-align: middle;
  width: 13px;
  height: 13px;
  color: #888888;
  margin-right: 4px;
}

.boardlistitem-inner-info-count {
  margin-left: 5px;
  font-size: 10px;
  color: #888888;
}

.boardlistitem-inner-info-devider {
  font-size: 10px;
  color: #888888;
  margin-left: 3px;
  margin-right: 3px;
}

.boardlistitem-inner-info-item {
  margin-left: 10px;
  font-size: 10px;
  color: #888888;
}

.boardlistitem-inner-info-item-reply {
  margin-left: 10px;
  font-size: 10px;
  font-weight: bold;
  color: #2d9cdb;
}

.boardlistitem-inner-info-item-noreply {
  margin-left: 10px;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  background: #fd6128;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
}

.boardlistitem-scrap-button {
  position: absolute;
  padding: 0px;
  top: -9px;
  right: 16px;
  width: 40px;
  height: 45px;
  --padding-start: 0px;
  --padding-end: 0px;
}

.boardlistitem-toolbar-container {
  width: 100%;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.boardlistitem-toolbar-button {
  min-width: 25%;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 100%;

  letter-spacing: -0.02em;

  color: #6b6b6b;

  visibility: visible !important;
}

.boardlistitem-toolbar-button-on {
  color: #2d9cdb !important;
  visibility: visible !important;
}

.boardlistitem-toolbar-icon {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.boardlistitem-toolbar-button-devider {
  border-right: 0.5px solid #afafaf;
  height: 15px;
}

.profile-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  align-items: center;
}

.profile-profile-container {
  margin-top: 20px;
  width: 100px;
  height: 100px;
  position: relative;
}

.profile-profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  object-fit: fill;
}

.profile-profile-upload-button-container {
  height: 28px;
  width: 28px;
  bottom: 5px;
  right: 5px;
  position: absolute;
}

.profile-profile-upload-button-inner {
  height: 100%;
  width: 100%;
  position: relative;
}

.profile-profile-upload-button {
  height: 100%;
  width: 100%;
  --padding-start: 25px;
  --padding-end: 25px;
  margin: 0px;
  background: #2d9cdb;
  /* 시그니처컬러 */
  border-radius: 14px;

  color: #fff;
}

.ion-color-profile-profile-upload-button {
  height: 100%;
  width: 100%;
  --ion-color-base: #2d9cdb;
  --border-radius: 50%;
  --padding-start: 0px;
  --padding-end: 0px;
  margin: 0px;
  color: #fff;
}

.profile-profile-upload-file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.profile-profile-upload-button-icon {
  width: 18px;
  height: 18px;
  margin-right: 2px;
  color: #fff;
}

.profile-nickname-container {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-nickname {
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-transform: none;
  grid-gap:4px;
  gap:4px;
  color: #171717;
}

.profile-nickname-blocked {
    font-weight: 800;
    font-family: 'NanumSquare';
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.1em;
    text-transform: none;
    
    color: #FF1F31;
}

.profile-location {
  background: #ffffff;
  opacity: 0.5; /* 시그니처컬러 */
  border: 0.5px solid #2d9cdb;
  box-sizing: border-box;
  border-radius: 16px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 5px;

  font-size: 10px;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-transform: lowercase;

  color: #6b6b6b;
}

.profile-description {
  width: 250px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 35px;

  font-size: 12px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: lowercase;

  color: #6b6b6b;
}

.profile-counts-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-count-container {
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.profile-count-border {
  border-right: 0.6px solid #dadada;
}

.profile-count {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.005em;
  text-transform: lowercase;

  color: #6b6b6b;
}

.profile-count-type {
  font-size: 12px;
  line-height: 19px;
  letter-spacing: -0.005em;
  text-transform: lowercase;

  color: #2d9cdb;
}

.linkmeta-container {
  width: 100%;
  max-width: 480px;
  background: #f6f6f8;
  /* 하단바 비활성_New */

  border: 1px solid #afafaf;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.linkmeta-image {
  background: #f0f0f0;
  object-fit: cover;
  width: 90px;
  height: 90px;
}

.linkmeta-info-container {
  flex-grow: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.linkmeta-title {
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.04em;
  margin-bottom: 6px;
  color: #171717;
}

.linkmeta-desc {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 130%;
  /* or 14px */

  letter-spacing: -0.04em;

  /* qna타이틀내용 */

  color: #171717;
}

.linkmeta-youtube-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.linkmeta-youtube {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.linkmeta-link-icon-container {
  width: 15px;
  height: 15px;
  /* padding: 4.5px; */
  background: #fff;
  border: 1px solid #afafaf;
  border-radius: 3px;
  position: relative;
  margin-right: 6px;
}
.linkmeta-link-icon {
  width: 6px;
  height: 6px;
  object-fit: contain;
  position: absolute;
  left: 3px;
  top: 3px;
}

.linkmeta-url {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height */

  /* 프로필아이디 */

  color: #6b6b6b;
}

.boardlist {
  width: 100%;
  padding: 0;
  background: #f7f8f9;
}

.board-list-background {
  width: 100%;
  height: 100%;
  padding: 8px;
  background: #f7f8f9;
}

.board-list-waiting-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.board-list-toolbar-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.board-list-toolbar-toggle-button {
  height: 21px;
  background: #c4c4c4;
  border-radius: 5px;

  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  padding: 5px 10px;

  /* 화이트 */

  color: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
}

.board-list-toolbar-toggle-button-active {
  background: #2d9cdb;
}

.ion-color-board-list-toolbar-button {
  --padding-start: 5px;
  --padding-end: 5px;
  height: 21px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #171717;

  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
}

.board-list-toolbar-button-icon {
  height: 21px;
  font-size: 21px;
  line-height: 21px;
}

.ion-color-board-list-fab {
  --ion-color-base: #fff8;
  --ion-color-contrast: #000;
  --ion-color-shade: #fff8;
  --padding-start: 0px;
  --padding-end: 0px;
  /* width: 84px;
    height: 84px; */
  width: 40px;
  height: 40px;
  --border-radius: 20px;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  position: absolute;
  bottom: 0px;
  left: calc(50% - 20px);
  z-index: 5;
}

.board-list-fab-icon {
  color: #2d9cdb88;
  width: 20px;
  height: 20px;
  --font-size: 20px;
}

.ion-color-appstack {
  --ion-color-base: #fff;
  --ion-color-contrast: #2d9cdb;
  color: #a1a0a5;
  --ion-color-tint: #393939;
}

.appstack-tabbar {
  height: 56px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.appstack-icon {
  width: 24px;
  height: 24px;
  --padding-top: 0px;
  --padding-end: 0px;
  --padding-bottom: 0px;
  --padding-start: 0px;
}

.appstack-bag-icon {
  position: absolute;
  top: 8px;
  right: calc(50% - 15px);
  width: 15px;
  height: 15px;
  color: #fd6128;
}

.appstack-icon-label {
  font-size: 9px;
  line-height: 10px;
  text-align: center;
}

.ion-color-appstack-notification-count {
  --ion-color-base: #e94d45;
  --padding-top: 1px;
  --padding-end: 2px;
  --padding-bottom: 1px;
  --padding-start: 2px;
  position: absolute;
  top: 3px;
  left: 50%;
  height: 15px;
  min-width: 15px;
  border-radius: 7.5px;

  font-size: 11px;
  line-height: 11px;
  color: #fff;
}

.appstack-new-count-badge {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #fd6128;
  top: 3px;
  left: calc(50% + 5px);
}

.appstack-new-icon-badge {
  height: 16px;
  min-width: 16px;
  position: absolute;
  top: 3px;
  left: calc(50% + 3px);
  height: 16px;
  min-width: 16px;
}

.appstack-new-badge {
  background: #e94d45;
  height: 16px;
  min-width: 16px;
  padding-top: 3px;
  border-radius: 8px;
  position: absolute;
  top: 0px;
  left: calc(50% + 3px);
  height: 15px;
  min-width: 15px;
  border-radius: 7.5px;

  font-size: 11px;
  line-height: 11px;
  color: #fff;
}

.myprofile-content {
  width: 100%;
  height: calc(100% - 43px);
  --background: #dae8ef;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.myprofile-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  align-items: center;
}

.myprofile-header {
  height: 43px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.myprofile-card {
  margin: 8px;
  /* height: 300px; */
  background: #ffffff;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  overflow: show;
  position: relative;
}

.myprofile-card-more-container{
    position: absolute;
    right: 10px;
    top: 10px;
}

.ion-color-myprofile-card-more {
    --ion-color-base: #313131;
    --ion-color-contrast: #313131;
    --ion-color-shade: #aeaeae;
    --ion-color-tint: #aeaeae;
    --padding-end: 0px;
    height: 20px;
}

.myprofile-card-container {
  height: 100%;
  width: 100%;
}

.ion-color-myprofile-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --min-height: 43px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 43px;

  font-size: 16px;
  line-height: normal;

  color: #a1a0a5;
}

.ion-color-profile-change-button {
  position: absolute;
  top: 10px;
  right: 8px;
  height: 30px;
  width: 75px;
  --ion-color-base: #2d9cdb;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  --border-radius: 15px;
  --padding-start: 0px;
  --padding-end: 0px;

  font-size: 11px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  color: #fff;
}

.ion-color-profile-cart-button {
  position: absolute;
  top: 52px;
  right: 8px;
  height: 30px;
  width: 75px;
  --ion-color-base: #fff;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  --border-radius: 15px;
  --border-width: 1px;
  --border-style: solid;
  --border-color: #2d9cdb;
  --padding-start: 0px;
  --padding-end: 0px;

  font-size: 11px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  color: #2d9cdb;
}

.myprofile-profile-container {
  margin-top: 20px;
  width: 100px;
  height: 100px;
  position: relative;
}

.myprofile-profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  object-fit: fill;
}

.myprofile-profile-upload-button-container {
  height: 28px;
  width: 28px;
  bottom: 5px;
  right: 5px;
  position: absolute;
}

.myprofile-profile-upload-button-inner {
  height: 100%;
  width: 100%;
  position: relative;
}

.myprofile-profile-upload-button {
  height: 100%;
  width: 100%;
  --padding-start: 25px;
  --padding-end: 25px;
  margin: 0px;
  background: #2d9cdb;
  /* 시그니처컬러 */
  border-radius: 14px;

  color: #fff;
}

.ion-color-myprofile-profile-upload-button {
  height: 100%;
  width: 100%;
  --ion-color-base: #2d9cdb;
  --border-radius: 50%;
  --padding-start: 0px;
  --padding-end: 0px;
  margin: 0px;
  color: #fff;
}

.myprofile-profile-upload-file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.myprofile-profile-upload-button-icon {
  width: 18px;
  height: 18px;
  margin-right: 2px;
  color: #fff;
}

.myprofile-nickname-container {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.myprofile-nickname {
  font-weight: 800;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-transform: none;

  color: #171717;
}

.myprofile-location {
  background: #ffffff;
  opacity: 0.5; /* 시그니처컬러 */
  border: 0.5px solid #2d9cdb;
  box-sizing: border-box;
  border-radius: 16px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 5px;

  font-size: 10px;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-transform: lowercase;

  color: #6b6b6b;
}

.myprofile-description {
  width: 250px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 35px;

  font-size: 12px;
  line-height: 17px;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: lowercase;

  color: #6b6b6b;
}

.myprofile-counts-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.myprofile-count-container {
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.myprofile-count-border {
  border-right: 0.6px solid #dadada;
}

.myprofile-count {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.005em;
  text-transform: lowercase;

  color: #6b6b6b;
}

.myprofile-count-type {
  font-size: 12px;
  line-height: 19px;
  letter-spacing: -0.005em;
  text-transform: lowercase;

  color: #2d9cdb;
}

.ion-color-myprofile-segment {
  margin-top: 15px;
  width: calc(100% - 16px);
  margin-left: 8px;
  margin-right: 8px;
  height: 41px;
  border-radius: 13px 13px 0px 0px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  overflow: show;
  background-color: #a1a0a5;
  justify-items: left;
  --ion-color-base: #0000;
  /* margin-bottom: -16px; */
}

.myprofile-segment-button {
  height: 41px !important;
  min-height: 41px;
  min-width: 80px;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #ffffff !important;
  border-radius: 13px 13px 0px 0px;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  overflow: show;
  background: #a1a0a5 !important;
}

.myprofile-segment-button.segment-button-checked {
  background: #2d9cdb !important;

  font-size: 13px;
  line-height: 20px;
  font-weight: bold;
}

.myprofile-segment-button-text {
  width: 100%;
  height: 41px;
  padding-top: 7px;
}

.myprofile-segment-title {
  position: relative;
  border-radius: 13px 13px 0px 0px;
  background: #fff;
  width: calc(100% - 16px);
  margin-left: 8px;
  margin-right: 8px;
  height: 37px;
  margin-top: -13px;
  padding-top: 14px;
  padding-left: 16px;

  font-size: 11px;
  line-height: 17px;
  letter-spacing: -0.02em;
  text-transform: capitalize;

  color: #2d9cdb;
}

.myprofile-segment-title-highlight {
  font-weight: bold;
}

.myprofile-swipe {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.myprofile-list-container {
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  height: calc(100vh - 120px);
  overflow: hidden;
}

.board-content-list-waiting-container {
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
}

.board-content-list{
    padding-left: 8px;
    padding-right: 8px;
}
.boardcontentlist-simpleitem-container {
  /* margin-right: 8px;
    margin-left: 8px; */
  width: 100%;
  background-color: #fff;
  --padding-start: 0px;
  --inner-padding-end: 0px;
}

.boardcontentlist-simpleitem-inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 16px;
}

.boardcontentlist-simpleitem-odd {
  background: rgba(196, 196, 196, 0.1);
}

.boardcontentlist-simpleitem-category {
  width: 60px;
  min-width: 60px;
  padding-left: 17px;
  padding-right: 17px;
  font-size: 8px;
  line-height: normal;
  text-align: center;

  color: #6b6b6b;
}

.boardcontentlist-simpleitem-body-container {
  padding-top: 8px;
  padding-bottom: 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
}

.boardcontentlist-simpleitem-body {
  width: 100%;
  max-width: 100%;
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
  color: #171717;
  padding-bottom: 8px;
  display: inline-block;
  /* display: none; */
}

.boardcontentlist-simpleitem-info-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 9px;
  line-height: normal;

  color: #888888;
}

.boardcontentlist-simpleitem-info-nickname {
  font-weight: bold;
  margin-right: 10px;
}

.boardcontentlist-simpleitem-info-date {
  flex-grow: 1;
}

.boardcontentlist-simpleitem-info-item {
  margin-left: 7px;
}

.boardcontentlist-simpleitem-info-item-cnt {
  margin-left: 3px;
}

.myprofile-change-content {
  width: 100%;
  --background: #dae8ef;
}

.myprofile-change-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  align-items: center;
}

.myprofile-change-header {
  height: 43px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.ion-color-myprofile-change-toolbar-button-enable {
  --ion-color-base: #2d9cdb !important;
  color: #2d9cdb !important;
}

.ion-color-myprofile-change-toolbar-button {
  --ion-color-base: #a1a0a5 !important;
  color: #a1a0a5 !important;
}

.myprofile-change-title {
  font-size: 20px;
  font-weight: bold;
  color: #171717;
}

.myprofile-change-item-description {
  margin-top: 4px;
  margin-bottom: 8px;

  font-size: 10px;
  line-height: normal;

  color: #2d9cdb;
}

.myprofile-change-item-highlight {
  font-weight: bold;
}

.myprofile-change-item-textarea {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;

  color: #6b6b6b;
}

.myprofile-change-body-container {
  background: #fff;
  width: calc(100% - 16px);
  margin-top: 13px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 20px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 23px;
  padding-bottom: 23px;
  border-radius: 13px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.myprofile-change-item-container {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  margin-bottom: 8px;
  align-items: flex-start;
}

.myprofile-change-item-name {
  min-width: 39px !important;
  margin-top: 3px !important;
  margin-right: 10px !important;

  font-weight: normal !important;
  font-size: 10px !important;
  line-height: normal !important;
  letter-spacing: 0.1em !important;
  text-align: left !important;

  color: #888888 !important;
  white-space: nowrap;
}

.myprofile-change-item-input {
  --padding-start: 0px !important;
  --padding-end: 0px !important;
  --padding-top: 0px !important;
  --padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  /* margin-left:10px !important; */
  margin-right: 10px !important;

  font-size: 16px !important;
  line-height: bold !important;
  letter-spacing: 0.1em !important;
  width: calc(100% - 30px) !important;

  color: #171717 !important;
  border: none !important;
  border-bottom: 0.5px solid #a1a0a5 !important;
  outline-style: none !important;
}

.ion-color-myprofile-change-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --min-height: 43px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 43px;

  font-size: 16px;
  line-height: normal;

  color: #a1a0a5;
}

.ion-color-myprofile-chat-button {
    --border-radius: 0px;
    --border-width: 0px;
    --border-style: solid;
    --border-color: #0000;
    --padding-start: 0px !important;
    --padding-end: 0px !important;
    margin: 0px;
    margin-inline: 0px;
    --box-shadow: none;
    border: 1px solid #6B6B6B;
    border-radius: 1000px;
    white-space: nowrap;
    height: 24px;
    padding: 0px 16px;
    margin-right: 10px;
    color: #6B6B6B;
}

.myprofile-change-work-type-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 28px;
}

.myprofile-change-work-type-selected {
  height: 32px;
  padding-top: 10px;
  margin-top: 15px;
  margin-left: 2px;
  margin-right: 2px;
  width: 64px;
  background: #2d9cdb;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;

  font-size: 11px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  color: #f2f2f2;
}

.myprofile-change-work-type-unselected {
  height: 32px;
  padding-top: 10px;
  padding-top: 10px;
  margin-top: 15px;
  margin-left: 2px;
  margin-right: 2px;
  width: 64px;
  background: #fff;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;

  font-size: 11px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  color: #a1a0a5;
}


.dynamic-checker-checked {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 2px solid #2D9CDB;
    color: #2D9CDB;
    font-size: 14px;
    padding-top: 4px;
    text-align: center;
}

.dynamic-checker-unchecked {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    border: 2px solid #ececec;
    color: #ececec;
    font-size: 14px;
    padding-top: 4px;
    text-align: center;
}

.dynamic-checker-unchecking {
    width: 24px;
    height: 24px;
}

.dynamic-checker-warning {
    width: 24px;
    height: 24px;
    color: #f00;
    font-size: 14px;
    padding-top: 0px;
    text-align: center;
}
.interest-select-popup-container {
  --width: 100%;
  /* --height: 240px; */
  --background: #0000;
  padding-left: 8px;
  padding-right: 8px;
}

.interest-select-popup-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.interest-select-popup-backdrop {
  flex-grow: 1;
  width: 100%;
}

.interest-select-container {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 13px;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 17px;
  padding-right: 17px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.interest-select-icon {
  width: 36px;
  height: 36px;
  margin-bottom: 29px;
}

.interest-select-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}

.interest-select-title {
  flex-grow: 1;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.04em;
}

.interest-select-title-highlight {
  color: #2d9cdb;
}

.interest-select-body-container {
  width: 100%;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.02em;

  color: #6b6b6b;
}

.ion-color-interest-select-popup-close {
  --color: #ececec;
  --padding-start: 13px;
  --padding-end: 0px;
  --box-shadow: none;
  margin: 0px;
}

.interest-select-popup-close-icon {
  width: 20px;
  height: 20px;
}

.interest-select-description-highlight {
  font-weight: bold;
}

.ion-color-category-select-button-checked {
  /* padding-left: 14px; */
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  /* padding-right: 6px; */
  height: 32px;
  --ion-color-base: #2d9cdb;
  border: 1px solid #2d9cdb;
  box-sizing: border-box;
  background: #2d9cdb;
  border-radius: 16px;
  --border-radius: 16px;
  --padding-start: 14px;
  --padding-end: 6px;

  font-size: 15px;
  line-height: 21px;
  font-weight: bold;
  --box-shadow: none;

  color: #fff;
}

.ion-color-category-select-button-unchecked {
  /* padding-left: 14px;*/
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  /* padding-right: 6px; */
  height: 32px;
  --ion-color-base: #0000;
  border: 1px solid #2d9cdb;
  box-sizing: border-box;
  border-radius: 16px;
  --border-radius: 16px;
  --padding-start: 14px;
  --padding-end: 6px;

  font-size: 15px;
  line-height: 21px;
  --box-shadow: none;

  color: #000000;
}

.category-select-icon-container {
  margin-left: 6px;
  height: 24px;
  width: 24px;
}

.category-select-icon {
  border-radius: 12px;
  background: #fff;
  color: #2d9cdb;
  height: 24px;
  width: 24px;
  font-size: 24px;
}

.search-address-container {
  width: 100%;
  height: 100%;
  background: #ffffff;
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 13px; */

  /* padding-bottom: 13px; */
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.search-address-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 13px;
}

.search-address-title {
  margin-left: 12px;

  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  font-weight: bold;
  text-align: left;
  letter-spacing: 0.05em;

  color: #171717;
}

.search-address-input-description {
  /* margin-top: 16px; */
  margin-left: 12px;

  font-size: 18px;
  line-height: 33px;

  color: #6b6b6b;
}

.register-search-input-container {
  display: flex;
  flex-direction: row;

  height: 40px;
  background: #ffffff;
  /* 하단바비활성화 */
  border: 0.5px solid #a1a0a5;
  box-sizing: border-box;
  border-radius: 20px;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 15px;
  margin-bottom: 16px;
  padding-left: 14px;
}

.search-address-type-container {
  width: 100%;
  height: calc(100% - 59px);
}

.search-address-input {
  --padding-start: 0px;
  --padding-end: 0px;
  flex-grow: 1;
  --placeholder-color: #a1a0a5;
  --placeholder-opacity: 1;

  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  color: #171717;
}

.search-address-list-container {
  width: 100%;
  height: calc(100% - 100px);
  margin-bottom: 15px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.search-address-list {
}

.search-address-item-container {
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  --background: rgba(244, 244, 244, 0.5);
  margin-bottom: 8px;
  width: 100%;
}

.search-address-item-inner {
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: 16px;
  margin-right: 16px;
}

.search-address-item-name {
  font-size: 15px;
  line-height: normal;
  font-weight: bold;

  color: #6b6b6b;
  margin-bottom: 6px;
}

.search-address-item-address {
  font-size: 14px;
  line-height: normal;
  margin-bottom: 5px;
  color: #6b6b6b;
}

.search-address-item-phone-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search-address-item-phone-title {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 1px;
  height: 15.3px;

  font-size: 9px;
  line-height: normal;
  margin-right: 3px;
  border: 0.5px solid #2d9cdb;
  box-sizing: border-box;
  border-radius: 7.65144px;
  color: #888888;
}

.search-address-item-phone {
  font-size: 12px;
  line-height: normal;

  color: #6b6b6b;
}

.search-address-segment-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
}

.ion-color-search-address-segment {
  width: 160px;
  margin-left: 8px;
  margin-right: 8px;
  height: 26px;
  border-radius: 13px 13px 13px 13px;
  background-color: #a1a0a5;
  justify-items: left;
  --ion-color-base: #0000;
  /* margin-bottom: -16px; */
}

.search-address-segment-button {
  height: 26px !important;
  min-height: 26px;
  min-width: 50px;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #ffffff !important;
  border-radius: 13px 13px 13px 13px;
  background: #a1a0a5 !important;
}

.search-address-segment-button.segment-button-checked {
  background: #2d9cdb !important;

  font-size: 13px;
  line-height: 20px;
  font-weight: bold;
}

.search-address-segment-button-text {
  width: 100%;
  height: 41px;
  padding-top: 7px;
}

.search-address-segment-title {
  position: relative;
  border-radius: 13px 13px 13px 13px;
  background: #fff;
  width: 100px;
  margin-left: 8px;
  margin-right: 8px;
  height: 26px;
  margin-top: -13px;
  padding-top: 14px;
  padding-left: 16px;

  font-size: 11px;
  line-height: 17px;
  letter-spacing: -0.02em;
  text-transform: capitalize;

  color: #fd6128;
}

.search-address-segment-title-highlight {
  font-weight: bold;
}

.search-address-postal-container {
  /* display:none; */

  width: 100%;
  height: calc(100% - 130px);
  /* margin:5px 0; */

  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
  position: relative;
}

#postal-search {
  position: relative;
  width: 100%;
  border: none;
  /* height: 100%; */
}
.search-address-manual-item-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: flex-start;
}

.search-address-manual-item-name {
  min-width: 30px;
  margin-top: 3px;
  margin-right: 10px;

  font-size: 15px;
  line-height: normal;
  letter-spacing: 0.1em;

  color: #888888;
}

.search-address-manual-item-input {
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  margin-left: 10px;
  margin-right: 10px;

  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.1em;
  flex-grow: 1;

  color: #171717;

  border-bottom: 0.5px solid #a1a0a5;
}

.ion-color-serach-address-manual-done-button {
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 10px;
  height: 26px;
  --ion-color-base: #2d9cdb;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  --border-radius: 13px;

  font-size: 17px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.1em;

  color: #ffffff;
}
.search-address-close {
  --color: #939393;
  height: 17px;
  --padding-start: 13px;
  --padding-end: 13px;
  margin: 0px;
}
.search-address-close-icon {
  width: 14px;
  height: 14px;
}

.dynamic-checker-input-container {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  height: 54px;
  align-items: flex-start;
}

.dynamic-checker-input-title {
  min-width: 53px;
  margin-right: 10px;

  font-size: 15px;
  font-weight: bold;
  text-align: center;
  line-height: normal;
  letter-spacing: 0.1em;
  margin-top: 5px;
  word-wrap: normal;
  word-break: keep-all;

  color: #888888;
}

.dynamic-checker-input-title-warn {
  color: #fd6128;
}

.dynamic-checker-input-input-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 24px;
}

.dynamic-checker-input-input.dynamic-checker-input-input-warn {
  border-bottom: 0.5px solid #fd6128;
}

.dynamic-checker-input-input {
  width: calc(100% - 50px);
  /* flex-grow: 1; */
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;

  margin-right: 10px;

  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0.1em;

  color: #171717;
  border: none;
  border-bottom: 0.5px solid #a1a0a5;
  outline-style: none;
  background-color: #fff;
}

.dynamic-checker-input-warning {
  padding-top: 3px;

  font-size: 10px;
  line-height: 14px;

  color: #fd6128;
}

.dynamic-checker-input-message {
  padding-top: 3px;

  font-size: 10px;
  line-height: 14px;

  color: #2d9cdb;
}

.setting-content {
  width: 100%;
  --background: #dae8ef;
  /* --background: #F00; */
}

.setting-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  align-items: center;
}

.setting-header {
  height: 43px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.ion-color-setting-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --min-height: 43px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 43px;

  font-size: 16px;
  line-height: normal;

  color: #a1a0a5;
}

.setting-title {
  font-size: 14px;
  line-height: normal;

  color: #171717;
  flex-grow: 1;
}

.setting-body-container {
  background: #fff;
  width: calc(100% - 16px);
  margin-top: 13px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 20px;
  border-radius: 13px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.setting-item-container {
  display: flex;
  flex-direction: row;
  margin-top: 13px;
  align-items: flex-start;
  --ion-safe-area-right: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --background: #0000;
  --border-color: rgba(244, 244, 244, 0.5);
}

.setting-item-inner {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  align-items: center;
  padding-left: 16px;
  padding-right: 5px;
}

.setting-item-container_last {
  --border-color: rgba(244, 244, 244, 0);
}

.ion-color-setting-button {
  --ion-color-base: #fff;
  --ion-color-contrast: #a1a0a5;
  --box-shadow: none;
}

.setting-version {
  font-size: 12px;
  line-height: normal;
  margin-right: 20px;

  color: #a1a0a5;
}

.setting-title-red {
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.04em;

  color: #eb5757;
}

.setting-title-hide {
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.04em;

  color: #a1a0a5;
}

.setting-kakao {
  height: 30px;
  width: 30px;
}

.setting-notification-content {
  width: 100%;
  --background: #dae8ef;
}

.setting-notification-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  align-items: center;
}

.setting-notification-header {
  height: 43px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.setting-notification-title {
  font-size: 20px;
  font-weight: bold;
  color: #171717;
  flex-grow: 1;
}

.setting-notification-description-container {
  display: flex;
  flex-direction: row;
  margin-left: 26px;
  margin-right: 26px;
  margin-top: 16px;

  font-size: 12px;
  line-height: 19px;

  color: #2d9cdb;
}

.setting-notification-description-title {
  font-weight: bold;
}

.setting-notification-description {
  flex-grow: 1;
}

.setting-notification-body-container {
  background: #fff;
  width: calc(100% - 16px);
  margin-top: 13px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 23px;
  padding-bottom: 23px;
  border-radius: 13px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.setting-notification-item-container {
  display: flex;
  flex-direction: row;
  padding-top: 24px;
  padding-bottom: 10px;
  align-items: center;
}

.setting-notification-item-name {
  flex-grow: 1;

  font-size: 15px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* qna타이틀내용 */

  color: #171717;
}

.ion-color-setting-notification-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --min-height: 43px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 43px;

  font-size: 16px;
  line-height: normal;

  color: #a1a0a5;
}

.setting-notification-toggle {
  zoom: 0.8;
  --background-checked: #2d9cdb;
  --background: #a1a0a5;
}

.setting-notification-subitem-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.setting-notification-subitem {
  --size: 17px;

  font-size: 15px;
  line-height: 17px;
  color: #171717;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-left: 20px; */
  margin-right: 30px;
}

.setting-notification-subitem-text {
  margin-left: 5px;
}

.ion-color-toggle-checked {
    width: 24px;
    width: var(--size, 24px);
    height: 24px;
    height: var(--size, 24px);
    min-width: 24px;
    min-width: var(--size, 24px);
    min-height: 24px;
    min-height: var(--size, 24px);
    --radius: calc(var(--size) * 0.5);
    border-radius: 12px;
    border-radius: var(--radius, 12px);
    --ion-color-base: #2D9CDB;
    border: 2px solid #2D9CDB;
    --padding-start: 0px;
    --padding-end: 0px;
    overflow: hidden;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    z-index: 0;
}

.ion-color-toggle-unchecked {
    width: 24px;
    width: var(--size, 24px);
    height: 24px;
    height: var(--size, 24px);
    min-width: 24px;
    min-width: var(--size, 24px);
    min-height: 24px;
    min-height: var(--size, 24px);
    --radius: calc(var(--size) * 0.5);
    border-radius: 12px;
    border-radius: var(--radius, 12px);
    --ion-color-base: #A1A0A5;
    border: 2px solid #A1A0A5;
    --padding-start: 0px;
    --padding-end: 0px;
    overflow: hidden;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    z-index: 0;
}

.check-toggle-icon {
    --icon-size: calc(var(--size) * 0.8);
    width: 14px;
    width: var(--icon-size,14px);
    height: 14px;
    height: var(--icon-size,14px);
}

.mmenu-container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: #0000;
  transition: -webkit-transform 0.2s cubic-bezier(0, 0.52, 0, 1);
  transition: transform 0.2s cubic-bezier(0, 0.52, 0, 1);
  transition: transform 0.2s cubic-bezier(0, 0.52, 0, 1), -webkit-transform 0.2s cubic-bezier(0, 0.52, 0, 1);
}

.mmenu-inner-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.mmenu-container.mmenu-slide-in {
  background: #0008 !important;
}

.mmenu-container.mmenu-slide-out {
  /* transform: translate3d(100px, 0, 0) !important; */
}

.mmenu-content {
  width: 100%;
  max-width: 450px;
  height: 100%;
  right: 0px;
  top: 0px;
  background: #2d9cdb;
  position: absolute;
  transition: -webkit-transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1), -webkit-transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  -webkit-transform: translate3d(min(100%, 450px), 0, 0);
          transform: translate3d(min(100%, 450px), 0, 0);
}

.mmenu-content.mmenu-slide-in {
  -webkit-transform: translate3d(0px, 0, 0) !important;
          transform: translate3d(0px, 0, 0) !important;
}

.mmenu-content.mmenu-slide-out {
  /* transform: translate3d(100px, 0, 0) !important; */
}

.mmenu-content-inner {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.mmenu-background {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: -1;
}

.ion-color-mmenu-close {
  position: absolute;
  top: 0px;
  right: 0px;
  --color: #ececec;
  --padding-start: 13px;
  --padding-end: 16px;
  --box-shadow: none;
  margin: 0px;
  z-index: 5;
}

.ion-color-mmenu-close2 {
  position: absolute;
  bottom: 15px;
  right: 10px;
  --color: #ececec;
  --padding-start: 13px;
  --padding-end: 16px;
  --box-shadow: none;
  margin: 0px;
  z-index: 5;
}

.mmenu-close-icon {
  width: 23px;
  height: 23px;
}

.mmenu-user-info-container {
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 5px;
  display: flex;
  flex-direction: row;
}

.mmenu-user-info-profile-container {
  height: 108px;
  width: 108px;
  min-width: 108px;
  padding: 0px 9px;
  border-radius: 54px;
  background: #7ec4ec;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
}

.mmenu-user-info-profile {
  height: 90px;
  width: 90px;
  border-radius: 45px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
}

.mmenu-user-info-data-container {
  flex-grow: 1;
}

.mmenu-user-info-data-name {
  display: flex;
  align-items: center;
  
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 18px;
  flex-grow: 1;
  letter-spacing: 0.1em;
  color: #ffffff;
}

.ion-color-mmenu-profile-change-button {
  height: 22px;
  --ion-color-base: #fff;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  --border-radius: 15px;
  --padding-start: 10px;
  --padding-end: 10px;
  font-size: 11px;
  line-height: 11px;
  text-align: center;
  letter-spacing: 0.02em;

  color: #2d9cdb;
}

.mmenu-user-info-data-statistics {
  margin-left: 20px;
  margin-top: 20px;
  width: 60px;
  border-bottom: 1.5px solid #ffffff;

  font-style: normal;
  font-weight: 800;
  font-size: 13px;
  text-align: center;
  line-height: 22px;
  color: #ffffff;
}

.mmenu-user-info-data-statistics-container {
  margin-top: 15px;
  /* padding-left: 10px;
    padding-right: 10px; */
}

.mmenu-user-info-data-statistics-item {
  width: 25%;
}

.mmenu-user-info-data-statistics-item-title {
  width: 32px;

  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 153.23%;
  /* identical to box height, or 20px */
  padding: 0px 3.5px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 2px;
  text-align: center;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;

  /* 시그니처컬러 */
  background: #fff;
  color: #2d9cdb;
}

.mmenu-user-info-data-statistics-item-value {
  margin-top: 10px;

  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
}

.mmenu-latest-commented-container {
  margin-top: 20px;
  width: 100%;
}

.mmenu-latest-commented-title {
  padding-left: 25px;

  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 100%;

  color: #ffffff;
}

.mmenu-latest-commented-items-container {
  /* width: 100%; */
  width: 100%;
  margin-top: 8px;
  overflow-x: scroll;
  /* overflow-y: auto; */
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-x: none;
}

.mmenu-latest-commented-items-inner-container {
  white-space: nowrap;
  position: relative;
  padding-left: 25px;
  padding-right: 25px;
}

.mmenu-latest-commented-item-container-outer {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 75px;
  padding-right: 10px;
  margin-right: 7px;
  background: #fefefe;
  border-radius: 5px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
}

.mmenu-latest-commented-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  height: 100%;
}

.mmenu-latest-commented-item-profile-container {
  width: 64px;
  height: 75px;

  margin-right: 7px;
}

.mmenu-latest-commented-item-profile {
  width: 38px;
  height: 38px;
  border-radius: 19px;
  margin-left: 13px;
  margin-top: 10px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
}

.mmenu-latest-commented-item-profile-name {
  width: 64px;
  text-align: center;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
}

.mmenu-latest-commented-item-profile-no-comment {
  width: 64px;
  height: 64px;
  margin-right: 7px;
}

.mmenu-latest-commented-item-text {
  width: calc(100% - 64px);
  max-width: calc(100% - 64px);

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;
  color: #171717;
}

.mmenu-submenu-container {
  min-height: calc(100% - 326px);
  margin-top: 10px;
  padding: 0px 25px;
  display: flex;
  flex-direction: column;
}

.mmenu-submenu-item-container {
  width: 100%;
  height: 60px;
  min-height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgba(244, 244, 244, 0.5);
}

.mmenu-submenu-item-container-last {
  border: none !important;
}

.mmenu-submenu-item-img {
  width: 22px;
  height: 22px;
}

.mmenu-submenu-item-icon {
  width: 26px;
  height: 26px;
  margin-right: -4px;
  color: #fff;
}

.mmenu-submenu-item-text {
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 100%;
  color: #ffffff;
  padding-left: 15px;
  position: relative;
}

.mmenu-submenu-item-container-fixed {
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 20px;
  left: 0px;
}

.mmenu-submenu-item-new {
  position: absolute;
  right: -22px;
  top: -11px;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
  background: #ff3d00;
  color: #fff;

  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
}

.myscrap-content {
  width: 100%;
  --background: #dae8ef;
  --overflow: hidden;
}

.myscrap-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  align-items: center;
}

.myscrap-header {
  height: 43px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.ion-color-myscrap-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --min-height: 43px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 43px;

  font-size: 16px;
  line-height: normal;
  align-items: center;
  color: #a1a0a5;
}

.ion-color-myscrap-segment {
  margin-top: 15px;
  width: calc(100% - 16px);
  margin-left: 8px;
  margin-right: 8px;
  height: 41px;
  border-radius: 13px 13px 0px 0px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
  background-color: #a1a0a5;
  justify-content: left;
  --ion-color-base: #0000;
  /* margin-bottom: -16px; */
}

.myscrap-segment-button {
  height: 41px !important;
  min-height: 41px;
  min-width: 80px;
  width: 100px;
  max-width: 120px;
  font-size: 12px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #ffffff !important;
  border-radius: 13px 13px 0px 0px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
  background: #a1a0a5 !important;
}

.myscrap-segment-button.segment-button-checked {
  background: #2d9cdb !important;

  font-size: 13px;
  line-height: 20px;
  font-weight: bold;
}

.myscrap-segment-button-text {
  width: 100%;
  height: 41px;
  padding-top: 7px;
}

.myscrap-segment-title {
  position: relative;
  border-radius: 13px 13px 0px 0px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
  background: #fff;
  width: calc(100% - 16px);
  margin-left: 8px;
  margin-right: 8px;
  height: 14px;
  margin-top: -13px;
  padding-top: 0px;
  padding-left: 16px;
}

.myscrap-segment-title-highlight {
  font-weight: bold;
}

.myscrap-list-container-with-foot {
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  height: calc(100vh - 145px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.myscrap-list-container {
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  height: calc(100vh - 98px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.ion-color-notifications-toolbar {
    --ion-color-base: #fff;
    --ion-color-base-rgb: 255, 255, 255;
    --ion-color-shade: #c4c4c4;
    --ion-color-contrast: #c4c4c4;
    --ion-color-tint: #39393980;

    --min-height: 43px;
    --padding-top: 0px;
    --padding-botoom: 0px;
    --padding-left: 0px;
    --padding-right: 0px;

    height: 43px;
}

.notifications-header {
    height: 43px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.notifications-title{
    color: #393939;
}

.notification-list-item-container {
  padding : 16px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 0.3px solid #2d9cdb;
}

.notification-list-item-container-unchecked {
  background: #dae8ef;
  /* background: #F00; */
}

.notification-list-item-profile {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-right: 10px;
}

.notification-list-item-body {
  flex-grow: 1;

  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.03em;

  color: #000000;
}

.notification-list-item-name {
  font-weight: bold;
}

.notification-list-item-time {
  margin-top: 4px;
  font-size: 10px;
  line-height: 16px;

  letter-spacing: -0.03em;

  /* 하단바활성화 */

  color: #2d9cdb;
}



.notice-container {
    width: 100%;
    height: 100%;
    padding-top : 12px;
    background: #DAE8EF;
}
.boardcontentlist-folditem-container {
  background: #ffffff;

  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
  border: none;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 8px;
  margin-left: 8px;
  width: calc(100% - 16px);
  padding-left: 16px;
}

.boardcontentlist-folditem-head-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.boardcontentlist-folditem-head {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 16px;
}

.boardcontentlist-folditem-date {
  font-size: 11px;
  line-height: 13px;

  /* 프로필날짜조회수 */

  color: #888888;
  margin-bottom: 3px;
}

.boardcontentlist-folditem-title {
  font-size: 15px;
  line-height: 23px;

  /* qna타이틀내용 */

  color: #171717;
}

.boardcontentlist-folditem-title-unfold {
  font-size: 15px;
  line-height: 23px;

  /* qna타이틀내용 */
  font-weight: bold;
  color: #171717;
}

.ion-color-boardcontentlist-folditem-arrow {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #89e2a980;
  --ion-color-contrast: #2d9cdb;
  --ion-color-tint: #89e2a980;
  --box-shadow: none;

  color: #2d9cdb;
}

.boardcontentlist-folditem-body {
  margin-top: 10px;
  padding-bottom: 16px;

  font-size: 15px;
  line-height: 23px;
  padding-right: 16px;

  /* 프로필아이디 */

  color: #6b6b6b;
}

.boardcontentlist-notice-item-container {
  background: #ffffff;

  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
  border: none;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 8px;
  margin-left: 8px;
  width: calc(100% - 16px);
  padding-left: 16px;
}

.boardcontentlist-notice-item-head-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.boardcontentlist-notice-item-head {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 16px;
}

.boardcontentlist-notice-item-date {
  font-size: 11px;
  line-height: 13px;

  /* 프로필날짜조회수 */

  color: #888888;
  margin-bottom: 3px;
}

.boardcontentlist-notice-item-title {
  font-size: 15px;
  line-height: 23px;

  /* qna타이틀내용 */

  color: #171717;
}

.boardcontentlist-notice-item-title-unfold {
  font-size: 15px;
  line-height: 23px;

  /* qna타이틀내용 */
  font-weight: bold;
  color: #171717;
}

.ion-color-boardcontentlist-notice-item-arrow {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #89e2a980;
  --ion-color-contrast: #2d9cdb;
  --ion-color-tint: #89e2a980;
  --box-shadow: none;

  color: #2d9cdb;
}

.boardcontentlist-notice-item-body {
  margin-top: 10px;
  padding-bottom: 16px;

  font-size: 15px;
  line-height: 23px;
  padding-right: 16px;

  /* 프로필아이디 */

  color: #6b6b6b;
}

.select-region-popup-container {
  background-color: #fff;
  display: flex;
  flex-direction: row;
}

.select-region-popup-province-container {
  width: 30%;
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 2;
}

.select-region-popup-region-container {
  width: 70%;
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 0px 10px;
}

.select-region-popup-province-item-container {
  height: 40px;
  width: 100%;

  color: #171717;

  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 100%;
  border-bottom: 1px solid #ccc;
}

.ion-color-select-region-popup-province-item-container {
  height: 40px;
  --ion-color-base: #ececec;
  --border-radius: 0px;
  --border-width: 0px;
  --border-style: solid;
  --border-color: #0000;
  --padding-start: 0px;
  --padding-end: 0px;
  margin: 0px;
  margin-inline: 0px;
  --box-shadow: none;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  width: 100%;

  color: #171717;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.ion-color-select-region-popup-region-item-container {
  height: 40px;
  --ion-color-base: #fff;
  --border-radius: 0px;
  --border-width: 0px;
  --border-style: solid;
  --border-color: #0000;
  --padding-start: 20px;
  --padding-end: 0px;
  margin: 0px;
  margin-inline: 0px;
  --box-shadow: none;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  width: 100%;
  text-align: left;

  color: #171717;
  border-bottom: 1px solid #ccc;
}

.seminar-list-container {
  height: calc(100% - 54px);
  width: 100%; }

.seminar-list-cardview {
  --cardview-card-border-radius: 24px;
  --cardview-card-padding: 0px 6px; }

.tab-bar-container-1 {
  width: 100%;
  max-width: 100%;
  padding: 0px 24px;
  height: 53px;
  min-height: 53px;
  background: #FFFFFF;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
  position: relative;
  border-bottom: 1px solid #F7F8F9 !important; }
  .tab-bar-container-1 .tab-bar-items-container {
    height: 100%;
    white-space: nowrap;
    display: inline-block; }
    .tab-bar-container-1 .tab-bar-items-container .tab-bar-item-container {
      display: inline-block;
      position: relative;
      height: 100%;
      background: #FFFFFF; }
    .tab-bar-container-1 .tab-bar-items-container .tab-bar-item-container-inner {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center; }
    .tab-bar-container-1 .tab-bar-items-container .tab-bar-item-container-fit {
      margin-right: 20px; }
    .tab-bar-container-1 .tab-bar-items-container .tab-bar-item {
      padding: 11px 0px;
      position: relative;
      height: 51px;
      overflow: hidden;
      color: #C6CED7;
      border-bottom: none;
      background: #FFFFFF; }
    .tab-bar-container-1 .tab-bar-items-container .tab-bar-item-selected {
      color: #1E2834 !important;
      border-bottom: 2px solid #1E2834 !important; }

.tab-bar-container-2 {
  width: 100%;
  max-width: 100%;
  padding: 0px 24px;
  height: 32px;
  background: #FFFFFF;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
  overflow-y: hidden;
  position: relative; }
  .tab-bar-container-2 .tab-bar-items-container {
    height: 100%;
    white-space: nowrap; }
    .tab-bar-container-2 .tab-bar-items-container .tab-bar-item-container {
      display: inline-block;
      position: relative;
      height: 100%;
      background: #FFFFFF; }
    .tab-bar-container-2 .tab-bar-items-container .tab-bar-item-container-inner {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center; }
    .tab-bar-container-2 .tab-bar-items-container .tab-bar-item-container-fit {
      margin-right: 16px; }
    .tab-bar-container-2 .tab-bar-items-container .tab-bar-item {
      padding: 6px 12px;
      position: relative;
      height: 100%;
      overflow: hidden;
      color: #949CA6;
      background-color: #F7F8F9;
      border-radius: 12px; }
    .tab-bar-container-2 .tab-bar-items-container .tab-bar-item-selected {
      color: #FFFFFF !important;
      background-color: #1E2834 !important; }

.tab-bar-container-3 {
  width: 100%;
  max-width: 100%;
  padding: 0px 24px;
  height: 46px;
  background: #FFFFFF;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
  white-space: nowrap;
  position: relative; }
  .tab-bar-container-3 .tab-bar-items-container {
    height: 100%;
    white-space: nowrap; }
    .tab-bar-container-3 .tab-bar-items-container .tab-bar-item-container {
      display: inline-block;
      position: relative;
      height: 100%;
      background: #FFFFFF; }
    .tab-bar-container-3 .tab-bar-items-container .tab-bar-item-container-inner {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center; }
    .tab-bar-container-3 .tab-bar-items-container .tab-bar-item-container-fit {
      margin-right: 16px; }
    .tab-bar-container-3 .tab-bar-items-container .tab-bar-item {
      padding: 12px 0px;
      position: relative;
      height: 100%;
      overflow: hidden;
      color: #B3BBC5;
      border-bottom: none;
      background: #FFFFFF; }
    .tab-bar-container-3 .tab-bar-items-container .tab-bar-item-selected {
      color: #32C1FF !important;
      border-bottom: 2px solid #32C1FF !important; }

.cardview-test {
  --cardview-border-radius: 24px;
  --cardview-card-border-radius: 0px;
  --cardview-card-padding: 0px 0px; }

.cardview-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: none; }
  .cardview-container .cardview-content-container {
    width: 100%;
    position: relative; }
    .cardview-container .cardview-content-container .cardview-content-slider {
      width: 100%;
      height: 100%;
      background: #fff;
      border-radius: 0px;
      border-radius: var(--cardview-border-radius, 0px);
      overflow: hidden; }
      .cardview-container .cardview-content-container .cardview-content-slider .cardview-content-image-container {
        padding: 0px;
        padding: var(--cardview-card-padding, 0px); }
        .cardview-container .cardview-content-container .cardview-content-slider .cardview-content-image-container .cardview-content-image {
          border-radius: 0px;
          border-radius: var(--cardview-card-border-radius, 0px);
          width: 100%;
          height: 100%;
          object-fit: contain; }
  .cardview-container .cardview-page-index-container-center {
    position: absolute;
    bottom: 24px;
    left: 0px;
    width: 100%;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center; }
  .cardview-container .cardview-page-index-container-left {
    position: absolute;
    bottom: 24px;
    left: 24px; }
  .cardview-container .cardview-page-index-container-centered-left {
    position: absolute;
    bottom: 24px;
    left: calc(5% + 24px); }
  .cardview-container .cardview-page-index-container-right {
    position: absolute;
    bottom: 16px;
    right: 6px; }
  .cardview-container .cardview-page-index-selected {
    background: #F1F2F3;
    width: 16px;
    height: 4px;
    border-radius: 6px;
    margin: 0px 3px; }
  .cardview-container .cardview-page-index {
    background: #616975;
    width: 6px;
    height: 4px;
    border-radius: 6px;
    margin: 0px 3px; }
  .cardview-container .cardview-page-button-previous {
    position: absolute;
    left: 0px;
    top: 50%;
    transition: -50%, 0px;
    width: 30px;
    height: 60px;
    border-radius: 0px 30px 30px 0px;
    background: rgba(0, 0, 0, 0.3);
    padding: 18px 0px; }
  .cardview-container .cardview-page-button-next {
    position: absolute;
    right: 0px;
    top: 50%;
    transition: -50%, 0px;
    width: 30px;
    height: 60px;
    border-radius: 30px 0px 0px 30px;
    background: rgba(0, 0, 0, 0.3);
    padding: 18px 0px 18px 6px; }

.slick-slide div {
  outline: none; }

@-webkit-keyframes spinners-react-circular {
    0% { stroke-dashoffset: 306; }
    50% { stroke-dasharray: 40, 134; }
    100% {
        stroke-dasharray: 1, 174;
        stroke-dashoffset: 132;
    }
}
@keyframes spinners-react-circular {
    0% { stroke-dashoffset: 306; }
    50% { stroke-dasharray: 40, 134; }
    100% {
        stroke-dasharray: 1, 174;
        stroke-dashoffset: 132;
    }
}
.seminar-text-index {
  text-indent: -13px;
  margin-left: 13px; }

.seminar-text-underline {
  text-decoration: underline; }

.seminar-list-item-thumbnail {
  min-width: 80px;
  height: 80px;
  position: relative;
  --size: 78px;
  overflow: hidden; }

.seminar-list-item-playtime {
  position: absolute;
  right: 8px;
  top: 8px; }

.seminar-list-item-progress-container {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 12px;
  padding: 4px;
  background: rgba(20, 32, 58, 0.5);
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px); }

.allinone-container {
  padding: 12px 20px 40px 20px;
  background: #fff; }
  .allinone-container .allinone-banner-container {
    width: 100%;
    margin-top: 20px; }
    .allinone-container .allinone-banner-container .allinone-banner-cardview {
      --cardview-card-border-radius: 12px;
      --cardview-card-padding: 0px 0px; }
  .allinone-container .allinone-card-container {
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    padding: 24px 0px 24px 24px;
    height: 300px;
    -webkit-transform: translateZ(0);
            transform: translateZ(0); }
    .allinone-container .allinone-card-container .allinone-card-title {
      margin-bottom: 8px; }
    .allinone-container .allinone-card-container .allinone-card-description-img {
      position: absolute;
      right: -15px;
      bottom: 0px;
      width: 60%; }
    .allinone-container .allinone-card-container .allinone-card-more {
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-gap: 4px;
      gap: 4px;
      position: absolute;
      bottom: 24px;
      left: 24px; }
  .allinone-container .allinone-partners-container {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    grid-gap: 40px;
    gap: 40px; }
    .allinone-container .allinone-partners-container .allinone-card-title {
      margin-bottom: 10px; }
  .allinone-container .allinone-categories-container {
    margin-top: 80px; }
    .allinone-container .allinone-categories-container .allinone-categories {
      margin-top: 20px;
      padding: 20px 24px;
      border-radius: 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      grid-gap: 16px 12px;
      gap: 16px 12px;
      -webkit-transform: translateZ(0);
              transform: translateZ(0); }
      .allinone-container .allinone-categories-container .allinone-categories .allinone-category-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60px;
        white-space: nowrap;
        grid-gap: 4px;
        gap: 4px; }
        .allinone-container .allinone-categories-container .allinone-categories .allinone-category-container .allinone-card-description-img {
          width: 40px; }
    .allinone-container .allinone-categories-container .allinone-categories-description {
      margin: 20px 0px; }

.allinone-post-container {
  margin-top: 12px;
  background-color: #fff;
  padding: 32px 20px 20px 20px; }

.allinone-suggest-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  grid-gap: 12px;
  gap: 12px; }

.allinone-partner-post-container {
  margin-top: 24px; }

.allinone-partner-post-profile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  margin-bottom: 4px; }

.allinone-partner-post-body-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 20px;
  gap: 20px; }
  .allinone-partner-post-body-container .allinone-partner-post-body-information {
    flex-grow: 1; }
  .allinone-partner-post-body-container .allinone-partner-post-body-image {
    width: 72px;
    height: 72px;
    border-radius: 8px;
    object-fit: cover;
    -webkit-transform: translateZ(0);
            transform: translateZ(0); }

.allinone-container {
  padding: 12px 20px 40px 20px;
  background: #fff; }
  .allinone-container .allinone-banner-container {
    width: 100%;
    margin-top: 20px; }
    .allinone-container .allinone-banner-container .allinone-banner-cardview {
      --cardview-card-border-radius: 12px;
      --cardview-card-padding: 0px 0px; }
  .allinone-container .allinone-card-container {
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    padding: 24px 0px 24px 24px;
    height: 300px;
    -webkit-transform: translateZ(0);
            transform: translateZ(0); }
    .allinone-container .allinone-card-container .allinone-card-title {
      margin-bottom: 8px; }
    .allinone-container .allinone-card-container .allinone-card-description-img {
      position: absolute;
      right: -15px;
      bottom: 0px;
      width: 60%; }
    .allinone-container .allinone-card-container .allinone-card-more {
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-gap: 4px;
      gap: 4px;
      position: absolute;
      bottom: 24px;
      left: 24px; }
  .allinone-container .allinone-partners-container {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    grid-gap: 40px;
    gap: 40px; }
    .allinone-container .allinone-partners-container .allinone-card-title {
      margin-bottom: 10px; }
  .allinone-container .allinone-categories-container {
    margin-top: 80px; }
    .allinone-container .allinone-categories-container .allinone-categories {
      margin-top: 20px;
      padding: 20px 24px;
      border-radius: 20px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      grid-gap: 16px 12px;
      gap: 16px 12px;
      -webkit-transform: translateZ(0);
              transform: translateZ(0); }
      .allinone-container .allinone-categories-container .allinone-categories .allinone-category-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60px;
        white-space: nowrap;
        grid-gap: 4px;
        gap: 4px; }
        .allinone-container .allinone-categories-container .allinone-categories .allinone-category-container .allinone-card-description-img {
          width: 40px; }
    .allinone-container .allinone-categories-container .allinone-categories-description {
      margin: 20px 0px; }

.allinone-post-container {
  margin-top: 12px;
  background-color: #fff;
  padding: 32px 20px 20px 20px; }

.allinone-suggest-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  grid-gap: 12px;
  gap: 12px; }

.ion-color-html-composer-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --min-height: 53px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 53px;

  font-size: 16px;
  line-height: normal;
}

.html-composer-content {
  height: 300px;
  overflow-y: scroll;
  /* transform: translateZ(0); */
}
.html-composer-container {
  width: 100%;
  height: 100%;
  /* overflow-y: hidden; */

  /* display: flex; */
  /* flex-direction: column; */
  padding-left: 17px;
  padding-right: 17px;
  /* padding-bottom: 40px; */
  overflow-y: auto;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}

.html-composer-button {
  font-size: 16px;
  line-height: normal;
  color: #2d9cdb;
  font-weight: bold;
  padding-right: 10px;
}

.ion-color-html-composer-show-parent {
  background: #fafafa;
  /* 시그니처컬러 */

  border: 1px solid #2d9cdb;

  --box-shadow: none;

  color: #2d9cdb;
  --ion-color-base: #2d9cdb;
  margin-right: 20px !important;
}

.html-composer-show-parent {
  position: relative;

  font-size: 15px;
  line-height: 23px;
  font-weight: bold;
  letter-spacing: -0.02em;
  padding: 0px 10px;
}

.html-composer-show-parent-new {
  color: #fff;
  text-shadow: -1px -1px 0 #eb5757, 1px -1px 0 #eb5757, -1px 1px 0 #eb5757, 1px 1px 0 #eb5757;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.html-composer-show-parent-spinner {
  height: 26px;
  width: 26px;
}

.html-composer-button-disabled {
  font-size: 16px;
  line-height: normal;
  color: #c4c4c4;
  padding-right: 10px;
}

.ion-color-html-composer-checkbox {
  margin-right: 5px;
  --background-checked: #2d9cdb;
  --border-color-checked: #2d9cdb;
  --border-color: #c4c4c4;
  --checkmark-color: #fff;
}

.html-composer-profile-container {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.html-composer-title-container {
  width: calc(100% - 14px);
  margin-left: 7px;
  margin-right: 7px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: 0.5px solid #ececec;
}

.html-composer-title-icon {
  height: 17px;
  width: 17px;
  margin-right: 10px;
  margin-top: 6px;
}

.html-composer-advertise-input {
  z-index: 2;
  flex-grow: 1;
  font-weight: 800;

  font-size: 15px;
  line-height: 100%;
  letter-spacing: -0.04em;
  color: #171717;
  --padding-start: 0px;
  --padding-end: 0px;
  outline-style: none;
  border: none;
}

.html-composer-title-input {
  z-index: 2;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.04em;
  color: #171717;
  --padding-start: 0px;
  --padding-end: 0px;
  outline-style: none;
  border: none;
}

.html-composer-title-input::-webkit-input-placeholder {
  color: #b7c0c6;
}

.html-composer-title-input::placeholder {
  color: #b7c0c6;
}
.html-composer-title-input::-webkit-input-placeholder {
  color: #b7c0c6;
}

.html-composer-title-input-container {
  padding: 14px 10px;
  flex-grow: 1;
  position: relative;
  align-items: center;
}

.html-composer-hidden {
  display: none;
}

.html-composer-title-placeholder {
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-left: 8px;
  font-weight: 800;

  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.04em;

  color: #c0c0c0;
  text-align: left;
}

.html-composer-title-placeholder-sub {
  font-size: 15px;
}

.html-composer-body-container {
  position: relative;
  width: 100%;
  min-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 20px;
  /* margin-left: 7px; */
  /* margin-right: 7px; */
  /* padding-bottom: 10px; */
}

.html-composer-reommends-container {
  position: absolute;
  width: 100%;
  max-width: 100%;
  bottom: 0px;
  left: 0px;
  z-index: 3;
}

.html-composer-content-placeholder {
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  font-size: 14px;
  line-height: 22px;

  color: #ababab;
}

.html-composer-body {
  position: relative;
  background: #0000;
  z-index: 2;
  height: auto;
  width: 100%;
  border: 1px;

  font-size: 14px;
  line-height: 22px;
  padding: 0px;
  color: #171717;
  outline-style: none;
}
.html-composer-images-container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.html-composer-images-preview {
  width: 72px;
  height: 72px;
  border-radius: 13px;
  border-width: 0px;
  object-fit: cover;
  margin-right: 8px;
}

.html-composer-files-container {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  width: calc(100% - 14px);
  margin-left: 7px;
  margin-right: 7px;
}

.html-composer-toolbar-button-container {
  /* overflow: hidden; */
  display: inline-block;
  position: relative;
  height: 24px;
  width: 28px;
  margin: 0px;
  padding: 3px 5px;
  white-space: nowrap;
}

.ion-color-html-composer-toolbar-button {
  --ion-color-base: #494949;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --padding-start: 0px;
  --padding-end: 0px;
  height: 24px;
  width: 28px;
  margin: 0px;
}

.html-composer-toolbar-button-file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 28px;
  height: 24px;
}

.html-composer-toolbar-button-icon {
  width: 18px;
  height: 18px;
}

.ql-toolbar.ql-snow {
  color: #494949;
  background: #fafafa;
  border: 1px solid #f5f5f6;
  border-radius: 0px;
  box-sizing: border-box;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 12px;
}

.ql-container.ql-snow {
  border: none !important;
  height: calc(100% - 102px);
}

.ql-editor {
  -webkit-user-select: text;
  max-height: none !important;
  padding: 0px !important;

  font-size: 14px !important;
  line-height: 22px !important;
  color: #171717;
}

.ql-editor.ql-blank::before {
  font-size: 14px !important;
  font-style: normal !important;
  line-height: 22px !important;

  color: #ababab;
}

.ql-mention-list-item.selected {
  background-color: #dae8ef !important;
}

.ql-mention-list-item {
  padding: 0 !important;
  height: 40px;
}

.mention {
  border-radius: 6px !important;
  padding: 2px !important;
  background-color: #dae8ef !important;
  font-weight: bold;
}

.html-composer-search-medicine-item-container {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.html-composer-search-medicine-item-type {
  color: #2d9cdb;
  display: flex;
  padding-top: 3px;
  flex-direction: column;
  align-items: center;
  min-width: 35px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #2d9cdb;
  font-weight: 800;

  font-size: 10px;
  line-height: 14px;
  margin-right: 10px;
  letter-spacing: -0.04em;
}

.html-composer-search-medicine-item-info {
  flex-grow: 1;
}

.html-composer-search-medicine-item-name {
  font-weight: 800;

  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.04em;
  color: #171717;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.html-composer-search-medicine-item-description {
  font-size: 10px;
  line-height: 14px;
  letter-spacing: -0.04em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2d9cdb;
}

.html-composer-component {
  background: #2d9cdb80;
  border-radius: 4px;
  padding: 0px 4px;
}

.html-composer-anonymous-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
}

.html-composer-anonymous-toggle {
  zoom: 0.6;
  --background-checked: #2d9cdb;
  --background: #a1a0a5;
}

.html-composer-anonymous-button {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  padding-left: 5px;
  width: 105px;
  color: #2d9cdb;
}

.html-composer-anonymous-button-disabled {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  padding-left: 5px;
  width: 105px;
  color: #a1a0a5;
}

.html-composer-subcategory-container {
  width: 100%;
  background: #f7f8f9;
  padding: 12px;
  margin-bottom: 20px;

  border: 1px solid #e4ebed;
  border-radius: 8px;
}

.html-composer-subcategory-invite-description-title {
  margin-bottom: 6px;
}

.html-composer-subcategory-invite-container {
  width: 100%;
  background: #fff;
  border: 1px solid #e4ebed;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 12px;
  align-items: flex-start;
}

.html-composer-subcategory-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.04em;

  color: #000000;
  margin-right: 15px;
  margin-top: 3px;
  min-width: 56px;
}

.html-composer-subcategory-items-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 15px;
}

.html-composer-subcategory-item-toggle {
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #e4ebed;
  box-sizing: border-box;
  border-radius: 18px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #3f4d5d;
  margin-bottom: 10px;
  margin-right: 10px;
}

.html-composer-subcategory-item-toggle-selected {
  background: #2d9cdb !important;
  color: #fff !important;
}

.html-composer-subcategory-done-button {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  /* identical to box height, or 13px */

  /* 시그니처컬러 */

  color: #2d9cdb;
}

.html-composer-subcategory-body {
  flex-grow: 1;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 13px */

  /* Gray 2 */

  color: #4f4f4f;
  margin-top: 4px;
}

.html-composer-subcategory-modify-button {
  min-width: 32px;
  height: 21px;
  background: #2d9cdb;
  border-radius: 8px;
  color: #fff;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
}

.profile-simple-container {
  /* width:100%; */
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-simple-image {
  width: 40px;
  height: 40px;
  background-color: #a1a0a5;
  border-radius: 20px;
  color: #2d9cdb;
  margin-right: 12px;
}

.profile-simple-info-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.profile-simple-info-name {
  display: flex;
  align-items: center;
  font-weight: 800;

  font-size: 15px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #6b6b6b;
}

.profile-simple-info-name-blocked{
    font-weight: 800;
    font-size: 12px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #FF1F31;
    
}

.profile-simple-name-input{
    font-weight: 800;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #6B6B6B;
    --padding-start: 0px;
    --padding-end: 0px;
}

.profile-simple-info-desc {
  font-size: 11px;
  line-height: 17px;
  letter-spacing: -0.005em;
  text-transform: lowercase;

  color: #888888;
}

.profile-simple-info-my-post {
  padding: 4px 6px;
  background: #2d9cdb;
  border-radius: 1px;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
  margin-right: 5px;
}

.profile-simple-info-nice {
  padding: 4px 6px;
  background: #dd4e4e;
  border-radius: 1px;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
  margin-right: 5px;
}

.attachment-container {
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border: 0.5px solid #dadada;
  box-sizing: border-box;
  border-radius: 13px;
  padding-left: 4px;
  align-items: center;
  margin-top: 5px;
}

.attachment-icon {
  width: 52px;
  height: 52px;
}

.attachment-info-container {
  margin-left: 12px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.attachment-info-filename {
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
  color: #6a6a6a;
}

.attachment-info-size {
  font-size: 12px;
  line-height: 13px;
  color: #cecece;
}

.ion-color-attachment-delete {
  --ion-color-base: #fff;
  --color: #ececec;
  --box-shadow: none;
}

.component-composer-popup-container {
  --width: 100%;
  /* --height: auto; */
  --background: #0000;
  padding-left: 20px;
  padding-right: 20px;
}

.component-composer-popup-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.component-composer-popup-backdrop {
  flex-grow: 1;
  width: 100%;
}

.component-composer-container {
  width: 100%;
  margin: auto;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 13px;
  /* padding-top: 13px;
    padding-bottom: 13px;
    padding-left : 17px;
    padding-right : 17px; */
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.component-composer-sub-container {
  width: 100%;
}

.component-composer-textarea {
  width: 100%;
  height: 400px;
}

.component-composer-preset-button {
  font-size: 10px;
  --padding-start: 3px;
  --padding-end: 3px;
  height: 20px;
}

.component-composer-title {
  width: 100%;

  font-size: 17px;
  line-height: normal;
  font-weight: bold;
  color: #171717;
  padding-top: 13px;
  padding-left: 16px;
}

.component-composer-type-container {
  padding-left: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.modal-wrapper.sc-ion-modal-md {
  --box-shadow: none;
}

.split-pane-visible > .split-pane-side {
  max-width: 100px !important; }

.admin-header {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 800;
  font-size: 20px;
  color: #808080;
  padding-left: 10px; }
  .admin-header .admin-header-title {
    display: flex;
    flex-direction: row;
    font-size: 15px;
    grid-gap: 10px;
    gap: 10px; }

.admin-main-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
  overflow: hidden; }
  .admin-main-container * {
    user-select: auto;
    -moz-user-select: auto;
    -khtml-user-select: auto;
    -webkit-user-select: auto;
    -o-user-select: auto; }
  .admin-main-container div, .admin-main-container span, .admin-main-container a, .admin-main-container u, .admin-main-container strong, .admin-main-container blockquote, .admin-main-container ul, .admin-main-container li {
    user-select: text;
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    -o-user-select: text; }

.admin-full-height {
  height: 100%;
  --height: 100%; }

@media (max-width: 800px) {
  #admin-sub-conainer {
    display: none; } }

.admin-full-container {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-width: 100wh;
  align-items: center;
  flex: 1 1;
  overflow-y: scroll;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch; }

.admin-grow-container {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-width: 100wh;
  align-items: center;
  flex: 1 1;
  overflow-y: scroll;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch; }

.admin-container {
  width: 100%;
  height: 100%;
  max-width: 800px;
  align-items: center;
  flex: 1 1;
  overflow-y: scroll;
  position: relative;
  -webkit-overflow-scrolling: touch; }

.admin-sub-container {
  min-width: 50%; }

.admin-card-container {
  width: 100%;
  height: 100%;
  max-width: 800px;
  padding: 10px;
  border-radius: 5px;
  border: 0.5px solid #dadada;
  margin: 10px 0px; }

.admin-container-hidden {
  visibility: hidden;
  display: none !important; }

.admin-input {
  flex-grow: 1;
  opacity: 1 !important;
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px; }

.admin-editor {
  border: 1px solid #000; }

.admin-editor > .quill > .ql-container > .ql-editor {
  max-height: 200px !important; }

.admin-image-show-n-change-container {
  position: relative; }

.admin-image-show-n-change-file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%; }

.ion-color-admin-button {
  flex-grow: 1;
  max-width: 100px;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 20px;
  --ion-color-base: #fff;
  --border-radius: 12px;
  --border-width: 1.5px;
  --border-style: solid;
  --border-color: #2d9cdb;
  --padding-start: 10px;
  --padding-end: 10px;
  --box-shadow: none;
  font-size: 15px;
  line-height: 15px;
  color: #2d9cdb; }

.admin-image-show-n-change-image {
  min-width: 100px; }

.admin-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center; }

.admin-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }

.admin-grow {
  flex-grow: 1; }

.admin-align-start {
  align-items: flex-start; }

.admin-border {
  border: 0.5px solid #dadada; }

.admin-margin-bottom {
  margin-bottom: 10px; }

.admin-margin-top {
  margin-top: 10px; }

.admin-item {
  padding: 10px; }

.admin-large-font {
  font-weight: 800;
  font-size: 25px; }

.admin-item-container {
  margin-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fff;
  border-radius: 10px; }

.admin-file-button {
  --icon-color: #fff;
  background: #2d9cdb;
  --icon-size: 16px;
  width: 30px;
  height: 30px;
  border-radius: 5px; }

.admin-full-button-block {
  width: 100%;
  border: 1px solid #888;
  background: #dadada;
  display: flex;
  flex-direction: column;
  align-items: center; }

.admin-normal-text {
  font-size: 15px;
  line-height: 20px;
  font-weight: normal; }

.admin-title {
  font-size: 18px;
  line-height: 20px;
  font-weight: 800;
  margin-right: 5px; }

.admin-text-button {
  font-size: 13px;
  line-height: 15px;
  font-weight: normal;
  padding: 3px 10px;
  color: #2d9cdb; }

.admin-search-box {
  background: #fee;
  border: 1px solid #dadada;
  padding: 10px 5px; }

.admin-show-box {
  background: #efe;
  border: 1px solid #dadada;
  padding: 10px 5px; }

.admin-margin-right {
  margin-right: 10px; }

.admin-toggle-selected {
  background: #2d9cdb !important;
  color: #f2f2f2 !important; }

.admin-toggle {
  height: 20px;
  padding: 5px 10px;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 11px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;
  background: #fff;
  color: #888888; }

.admin-table {
  min-width: 100%;
  border: 1px solid #aaa;
  border-collapse: collapse;
  font-size: 15px;
  line-height: 20px;
  font-weight: normal;
  color: #000; }

.admin-table-fixed-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 5; }

.admin-table-label-x {
  font-size: 10px;
  font-weight: bold;
  color: #000;
  background: #eff;
  border: 1px solid #aaa;
  max-width: 80px;
  padding: 0px 5px;
  text-align: center;
  white-space: nowrap; }

.admin-table-label-y {
  background: #fef;
  border: 1px solid #aaa;
  padding-left: 0px 5px;
  text-align: center;
  white-space: nowrap; }

.admin-table-value {
  border: 1px solid #aaa;
  text-align: center;
  white-space: nowrap; }
  .admin-table-value:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.02); }

.admin-table-value-text {
  border: 1px solid #aaa;
  text-align: left;
  padding-left: 10px;
  white-space: nowrap; }

.admin-table-value-highlight {
  background: #ff6; }

.admin-table-value-selected {
  background: #cbe5fd;
  font-weight: bold;
  border: 2px solid #000; }

.admin-table-value-dimm {
  color: #888; }

.admin-table-value-align-space-between {
  justify-content: space-between; }

.admin-table-value-picked {
  color: #11235e;
  background: #f2f9ff; }

.admin-table-value-canceld {
  color: #f00;
  text-decoration: line-through; }

.admin-table-value-higlight {
  background: #eef;
  color: #00f; }

.admin-table-value-caution {
  background: #fee;
  color: #f00;
  border: 1px solid #f00; }

tr:hover td.admin-table-value {
  text-align: center;
  white-space: nowrap;
  background: #ffe;
  font-weight: bold; }

tr:hover td table tr td.admin-table-value {
  text-align: center;
  white-space: nowrap;
  background: #fff;
  font-weight: normal; }

tr:hover td table tr:hover td.admin-table-value {
  text-align: center;
  white-space: nowrap;
  background: #ffe;
  font-weight: bold; }

.admin-container-scroll-x {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch; }

.admin-container-scroll-x::-webkit-scrollbar {
  display: inline-table !important;
  width: 3px;
  height: 10px;
  background: none; }

.admin-container-scroll-x::-webkit-scrollbar-thumb {
  background: #fdd;
  opacity: 0.4; }

.admin-container-scroll-x::-webkit-scrollbar-track {
  background: none; }

.admin-container-list-max-height {
  max-height: 500px; }

.admin-keyword-item-container {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center; }

.admin-keyword-item-type {
  color: #2d9cdb;
  display: flex;
  padding-top: 3px;
  flex-direction: column;
  align-items: center;
  min-width: 35px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #2d9cdb;
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
  margin-right: 10px;
  letter-spacing: -0.04em; }

.admin-keyword-item-info {
  flex-grow: 1; }

.admin-keyword-item-name {
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.04em;
  color: #171717;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.admin-text-area {
  width: 100%; }

.admin-margin-height {
  height: 100px; }

.admin-container-border {
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
  border: 1px solid #18a0bf; }

.compose-cardnews-top-button {
  min-width: 50px;
  margin-left: 10px;
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 150%;
  text-align: center;
}

.compose-cardnews-top-button-other {
  /* 화이트 */
  border: 1px solid #2667eb;
  color: #2667eb;
  position: relative;
}

.compose-cardnews-top-button-done {
  /* 화이트 */
  color: #fff;
  background: #2667eb;
}

.compose-cardnews-card-button {
  min-width: 30px;
  width: 30px;
  border: 1px solid #2667eb;
  color: #2667eb;
  margin-left: 10px;
}

.compose-cardnews-thumbnail {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-right: 10px;
}

.compose-cardnews-textarea {
  width: 100%;
  min-height: 50px;
}

.viewerbody-container {
  background: #fff;
  position: relative;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
}

.viewerbody-container-updated {
  border: 1px solid #fd6128;
}

.viewerbody-container-inner {
  width: 100%;
  padding: 20px 20px 40px;
  display: flex;
  flex-direction: column;
}

.viewerbody-profile-container {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.viewerbody-deleted {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;

  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 26px;
  color: #6b6b6b;
  margin-left: 20px;
}

.viewerbody-profile-container-padding {
  padding-left: 20px;
}

.viewerbody-body-container {
  font-size: 14px;
  line-height: 22px;
  white-space: pre-wrap;
  color: #171717;
}

.viewerbody-keywords-container {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.viewerbody-keyword-separate {
  font-size: 11px;
  line-height: 17px;
  letter-spacing: -0.02em;
  text-transform: capitalize;
  margin-right: 4px;
  color: #2d9cdb;
}

.viewerbody-images-container {
  width: 100%;
}

.viewerbody-image {
  height: auto;
  width: 100%;
  /* max-height: 240px; */
  margin-top: 16px;
  border-radius: 8px;
}

.ion-color-viewerbody-more {
  --ion-color-base: #313131;
  --ion-color-contrast: #313131;
  --ion-color-shade: #aeaeae;
  --ion-color-tint: #aeaeae;
  --padding-end: 0px;
  height: 20px;
}

.viewerbody-links-container {
  margin-bottom: 5px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */

  /* 시그니처컬러 */

  color: #2d9cdb;
}

.viewerbody-links {
  font-size: 10px;
  line-height: 14px;
  text-decoration: unset;

  color: #ababab;
}

.viewerbody-title-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.viewerbody-title {
  display: inline-block;
  line-height: 23px;
  font-weight: 800;

  font-size: 17px;
  letter-spacing: -0.04em;
  color: #171717;
  margin-bottom: 10px;
}

.viewerbody-title-icon {
  vertical-align: middle;
  margin-right: 5px;
  margin-top: 0px;
  width: 17px;
  height: 17px;
}

.viewerbody-title-nice-icon {
  margin-right: 5px;
  margin-top: -4px;
  min-width: 28px;
  height: 40px;
}

.viewerbody-toolbar-container {
  display: flex;
  flex-direction: row;
  border-top: 0.5px solid #dadada;
  margin-top: 14px;
  padding-top: 7px;
}

.viewerbody-files-container {
  width: 100%;
}

.viewerbody-keywords {
  font-size: 14px;
  line-height: 22px;

  color: #171717;
  font-weight: bold;
}

.viewerbody-keywords-icon {
  padding-top: 4px;
  height: 14px;
  width: 14px;
}

.viewerbody-reply-flag {
  position: absolute;
  width: 0px;
  border-radius: 13px 0px 0px 0px;
  border-bottom: 28px solid #0000;
  border-left: 28px solid #fd6128;
  border-top: 28px solid #fd6128;
  border-right: 28px solid #0000;
}

.viewerbody-reply-name {
  position: absolute;
  left: 2px;
  top: 7px;

  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 22px */
  font-weight: bold;
  letter-spacing: 0.05em;

  /* 화이트 */

  color: #ffffff;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.viewerbody-reply-flag-new {
  position: absolute;
  left: 3px;
  top: 3px;
  color: #fff;
  text-shadow: -1px -1px 0 #eb5757, 1px -1px 0 #eb5757, -1px 1px 0 #eb5757, 1px 1px 0 #eb5757;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.viewerbody-unread-flag {
  margin-left: 50px;
  margin-bottom: 5px;
  padding: 4px 6px;
  background: #2d9cdb;
  border-radius: 1px;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
}

.viewerbody-unread-flag-container {
  display: flex;
  flex-direction: row;
  margin-top: -10px;
}

.viewerbody-cardview-container {
  width: 100%;
  /* max-height: 70vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #fff;
  margin-bottom: 10px;
}

.viewerbody-cardview-swipe {
  width: 100%;
}

.viewerbody-cardview-image {
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
}

.viewerbody-cardview-back-button {
  height: 50px;
  width: 50px;
  color: #fff8;
  z-index: 50;
  position: absolute;
  -webkit-filter: drop-shadow(1px 1px #8888);
  filter: drop-shadow(1px 1px #8888);
}

.viewerbody-cardview-back-button-image {
  height: 50px;
  width: 50px;
}

.viewerbody-cardview-page-count {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  color: #222222;
  padding-right: 13px;
  padding-bottom: 10px;
}

.action-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
}

.action-button-2 {
  height: 35px;
  flex-grow: 3;
}

.action-button-3 {
  height: 50px;
  flex-grow: 3;
}

.ion-color-action-button {
  --ion-color-base: #2d9cdb;
  --ion-color-contrast: #2d9cdb;
  --ion-color-shade: #2d9cdb;
  --ion-color-tint: #2d9cdb;
  color: #2d9cdb;
}

.ion-color-action-button-inactive {
  --ion-color-base: #a1a0a5;
  --ion-color-contrast: #2d9cdb;
  --ion-color-shade: #2d9cdb;
  --ion-color-tint: #2d9cdb;
  color: #a1a0a5;
}

.ion-color-action-button-emphasis {
  --ion-color-base: #333333;
  --ion-color-contrast: #2d9cdb;
  --ion-color-shade: #2d9cdb;
  --ion-color-tint: #2d9cdb;
  color: #333333;
}

.action-button-icon {
  width: 21px;
  height: 21px;
  font-size: 21px;
  /* object-fit: cover; */
}

.action-button-text {
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: capitalize;
}

.action-button-text-icon {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 100%;
  padding-bottom: 6px;
}

.viewer-comments-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.viewer-comment-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 8px;
  position: relative;
}

.viewer-comment-container-depth {
  padding-left: 25px;
}

.viewer-comment-profile {
  width: 24px;
  height: 24px;
  background-color: #a1a0a5;
  border-radius: 12px;
  color: #2d9cdb;
  margin-right: 11px;
}

.viewer-comment-nickname {
  font-size: 13px;
  line-height: 20px;
  font-weight: bold;
  color: #6b6b6b;
  margin-right: 7.5px;
}

.viewer-comment-nickname-blocked {
  color: #ff1f31;
}

.viewer-comment-body-container {
  width: calc(100% - 35px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.viewer-comment-body {
  width: 100%;

  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #6b6b6b;
}

.viewer-comment-body-blocked {
  width: 100%;
  font-family: "Nanum Barun Gothic";
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.03em;

  color: #6b6b6b;
}

.viewer-comment-image {
  height: auto;
  width: 100%;
  border-radius: 8px;
}

.viewer-comment-new {
  position: absolute;
  left: -3px;
  top: -5px;
  color: #fff;
  text-shadow: -1px -1px 0 #eb5757, 1px -1px 0 #eb5757, -1px 1px 0 #eb5757, 1px 1px 0 #eb5757;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
}

.viewer-comment-time {
  font-size: 10px;
  line-height: 16px;
  padding-right: 15px;
  color: #6b6b6b;
}

.viewr-comment-composer-conteainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.viewer-comment-composer-open-writer-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.viewer-comment-composer-open-writer-info-sub {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.viewer-comment-input-open-writer-info {
  flex-grow: 1;
  margin-left: 5px;
  --padding-start: 10px;
  --padding-end: 10px;
  --inline-padding-start: 0px;
  --inline-padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  border: 1px solid #a1a0a5;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: -0.02em;

  color: #171717;
  margin-right: 10px;
}

.viewer-comment-composer {
  width: 100%;
  /* height: 33px; */
  background: #ffffff;

  border: 1px solid #a1a0a5;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.viewer-comment-input {
  flex-grow: 1;
  margin-left: 13px;
  --padding-start: 0px;
  --padding-end: 0px;
  --inline-padding-start: 0px;
  --inline-padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: -0.02em;

  color: #171717;
}

.viewer-comment-composer-body {
  position: relative;
  background: #0000;
  z-index: 2;
  height: auto;
  width: 100%;
  border: 1px;

  font-size: 14px;
  line-height: 22px;
  padding: 5px 10px;
  color: #171717;
  outline-style: none;
}

.viewer-comment-composer-file {
  --icon-color: #a1a0a5;
  --icon-size: 16px;
  width: 30px;
  height: 20px;
}

.ion-color-viewer-comment-composer-done {
  --ion-color-base: #2d9cdb;
  color: #fff;
  width: 27.25px;
  height: 24px;
  margin: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  --border-radius: 12px;
  margin-right: 4px;
}

.ion-color-viewer-comment-composer-done-disabled {
  --ion-color-base: #a2a2a2;
  color: #fff;
  width: 24px;
  height: 24px;
  margin: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  --border-radius: 12px;
  margin-right: 4px;
}

.viewer-comment-composer-done-icon {
  width: 16px;
  height: 16px;
}

.viewer-comment-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.viewer-comment-action-enabled {
  padding-left: 15px;
  padding-right: 15px;

  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;

  letter-spacing: -0.02em;
  color: #2f80ed;
}

.viewer-comment-action-disabled {
  padding-left: 15px;
  padding-right: 15px;

  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;

  letter-spacing: -0.02em;
  color: #a2a2a2;
}

.viewer-comment-action-empasis {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;

  letter-spacing: -0.02em;
  color: #333333;
}

.ion-color-viewer-comment-composer-checkbox {
  margin-right: 5px;
  --background-checked: #2d9cdb;
  --border-color-checked: #2d9cdb;
  --border-color: #c4c4c4;
  --checkmark-color: #fff;
}

.viewer-comment-composer-name-input {
  font-weight: 800;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #6b6b6b;
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
}

.viewer-comment-composer-button {
  font-size: 15px;
  line-height: normal;
  color: #2d9cdb;
  font-weight: bold;
  padding-right: 5px;
}

.viewer-comment-composer-button-disabled {
  font-size: 15px;
  line-height: normal;
  color: #c4c4c4;
  padding-right: 5px;
}

.viewer-comment-file-container {
  margin-bottom: 5px;
}

.viewer-comment-composer-anonymous-container {
  margin-top: 15px;
}

.viewer-comment-composer-anonymous-toggle {
  zoom: 0.6;
  --background-checked: #2d9cdb;
  --background: #a1a0a5;
}

.viewer-comment-composer-anonymous-button {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  padding-left: 5px;
  width: 105px;
  color: #2d9cdb;
  white-space: nowrap;
}

.viewer-comment-composer-anonymous-disabled {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  padding-left: 5px;
  width: 105px;
  color: #a1a0a5;
  white-space: nowrap;
}



.video-play-player-container{
    width: 100%;
    position: relative;
    margin-bottom: -3px;
}

.video-play-player {
    width:100%;
}

.video-icon-container{
    width: 60px;
    height: 60px;
    background: #fff;
    position: absolute;
    padding: 15px;
    border-radius: 30px;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
}

.video-icon{
    width: 30px;
    height: 30px;
}

.ion-color-composer-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --min-height: 53px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 53px;

  font-size: 16px;
  line-height: normal;
}

.composer-content {
  width: 100%;
  height: 100%;
  background: #fff;
}

.composer-container {
  width: 100%;
  height: calc(100% - 40px);
  /* display: flex;
    flex-direction: column; */
  padding-left: 17px;
  padding-right: 17px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.composer-reommends-container {
  position: absolute;
  width: 100%;
  max-width: 100%;
  bottom: 0px;
  left: 0px;
}

.composer-button {
  font-size: 16px;
  line-height: normal;
  color: #2d9cdb;
  font-weight: bold;
  padding-right: 10px;
}

.ion-color-composer-show-parent {
  background: #fafafa;
  /* 시그니처컬러 */

  border: 1px solid #2d9cdb;

  --box-shadow: none;

  color: #2d9cdb;
  --ion-color-base: #2d9cdb;
  margin-right: 20px !important;
}

.composer-show-parent {
  position: relative;

  font-size: 15px;
  line-height: 23px;
  font-weight: bold;
  letter-spacing: -0.02em;
  padding: 0px 10px;
}

.composer-show-parent-new {
  color: #fff;
  text-shadow: -1px -1px 0 #eb5757, 1px -1px 0 #eb5757, -1px 1px 0 #eb5757, 1px 1px 0 #eb5757;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  position: absolute;
  right: 0px;
  top: 0px;
}

.composer-show-parent-spinner {
  height: 26px;
  width: 26px;
}

.composer-button-disabled {
  font-size: 16px;
  line-height: normal;
  color: #c4c4c4;
  padding-right: 10px;
}

.composer-profile-container {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
}

.composer-title-container {
  width: calc(100% - 14px);
  margin-left: 7px;
  margin-right: 7px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: 0.5px solid #ececec;
}

.composer-title-icon {
  height: 17px;
  width: 17px;
  margin-right: 10px;
  margin-top: 6px;
}

.composer-title-input {
  z-index: 2;
  width: 100%;
  font-weight: 800;

  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.04em;
  color: #171717;
  --padding-start: 0px;
  --padding-end: 0px;
}

.composer-title-input::-webkit-input-placeholder {
  color: #a1a0a5;
}

.composer-title-input::placeholder {
  color: #a1a0a5;
}
.composer-title-input::-webkit-input-placeholder {
  color: #a1a0a5;
}

.composer-title-input-container {
  padding: 14px 10px;
  flex-grow: 1;
  position: relative;
  align-items: center;
}

.composer-hidden {
  display: none;
}

.composer-title-placeholder {
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-left: 8px;
  font-weight: 800;

  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.04em;

  color: #c0c0c0;
  text-align: left;
}

.composer-title-placeholder-sub {
  font-size: 15px;
}

.composer-body-container {
  position: relative;
  width: calc(100% - 14px);
  min-height: 200px;
  padding: 20px;
  /* margin-left: 7px;
  margin-right: 7px;
  margin-bottom: 40px; */
}

.composer-content-placeholder {
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  font-size: 14px;
  line-height: 22px;

  color: #ababab;
  padding: 20px;
}

.composer-body {
  position: relative;
  background: #0000;
  z-index: 2;
  height: auto;
  width: 100%;
  border: 1px;

  font-size: 14px;
  line-height: 22px;
  padding: 0px;
  color: #171717;
  outline-style: none;
}
.composer-images-container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.composer-images-preview {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 12px;
}

.composer-files-container {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  width: calc(100% - 14px);
  margin-left: 7px;
  margin-right: 7px;
}

.ion-color-composer-footer-button {
  --ion-color-base: #fff;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --padding-start: 10px;
  --padding-end: 10px;
}

.composer-footer-container {
  height: 40px;
  width: 100%;
  background: #0000;
  /* position: fixed; */
  /* bottom: 0; */
  /* z-index: 100; */
  margin-top: 12px;
}

.composer-footer-container-onkeyboard {
  bottom: 220px;
}

.composer-footer-buttonbar-container.composer-footer-buttonbar-container-onkeyboard {
  border-radius: 13px 13px 13px 13px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
}

.composer-footer-buttonbar-container {
  margin-left: 16px;
  background-color: #f7f8f9;
  box-shadow: -2px -3px 5px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 10;
  height: 40px;
  padding: 0px 12px;
  display: inline-flex;
  grid-gap: 8px;
  gap: 8px;
  position: relative;
}

.composer-footer-button-file-container {
  overflow: hidden;
  display: inline-block;
  position: relative;
}

.composer-footer-button-file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 40px;
  height: 36px;
}

.composer-footer-button-icon {
  width: 20px;
  height: 20px;
}

.composer-footer-ios {
  height: 400px;
}

.composer-anonymous-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
}

.composer-anonymous-toggle {
  zoom: 0.6;
  --background-checked: #2d9cdb;
  --background: #a1a0a5;
}

.composer-anonymous-button {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  padding-left: 5px;
  width: 105px;
  color: #2d9cdb;
}

.composer-anonymous-button-disabled {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  padding-left: 5px;
  width: 105px;
  color: #a1a0a5;
}

.composer-subcategory-container {
  width: 100%;
  background: #f7f8f9;
  padding: 12px;
  margin-bottom: 20px;

  border: 1px solid #e4ebed;
  border-radius: 8px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
}

.composer-subcategory-invite-description-title {
  margin-bottom: 6px;
}

.composer-subcategory-invite-container {
  width: 100%;
  background: #fff;
  border: 1px solid #e4ebed;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 12px;
  align-items: flex-start;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
}

.composer-subcategory-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.04em;

  color: #000000;
  margin-right: 15px;
  margin-top: 3px;
  min-width: 56px;
}

.composer-subcategory-items-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 15px;
}

.composer-subcategory-item-toggle {
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #e4ebed;
  box-sizing: border-box;
  border-radius: 18px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #3f4d5d;
  margin-bottom: 10px;
  margin-right: 10px;
}

.composer-subcategory-item-toggle-selected {
  background: #2d9cdb !important;
  color: #fff !important;
}

.composer-subcategory-done-button {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
  /* identical to box height, or 13px */

  /* 시그니처컬러 */

  color: #2d9cdb;
}

.composer-subcategory-body {
  flex-grow: 1;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 13px */

  /* Gray 2 */

  color: #4f4f4f;
  margin-top: 4px;
}

.composer-subcategory-modify-button {
  min-width: 32px;
  height: 21px;
  background: #2d9cdb;
  border-radius: 8px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;

  color: #fff;

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 100%;
}

.viewer-body-popup-container {
  --width: 100%;
  --height: 100%;
  /* --height: auto; */
  --background: #0000;
  padding-left: 8px;
  padding-right: 8px;
}

.viewer-body-popup-inner {
  width: 100%;
  height: 100%;
  padding-top: 4px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.viewer-body-header-container {
  padding-right: 60px;
}

.ion-color-viewer-body-hide-parent {
  background: #fafafa;
  /* 시그니처컬러 */

  border: 1px solid #2d9cdb;

  height: 34px;
  margin-right: 10px;
  margin-bottom: 15px;
}

.viewer-body-hide-parent {
  font-size: 15px;
  line-height: 23px;
  font-weight: bold;
  letter-spacing: -0.02em;
  --box-shadow: none;

  color: #2d9cdb;
}

.modal-wrapper.sc-ion-modal-md {
  --box-shadow: none;
}

.viewreply-report {
  width: calc(100% - 16px);
  height: 48px;
  background: #2d9cdb;
  border-radius: 13px 13px 0px 0px;
  margin-bottom: -16px;
  margin-right: 8px;
  margin-left: 8px;
  padding-right: 16px;
  padding-left: 16px;
  padding-top: 10.5px;
  padding-bottom: 10.5px;
  font-size: 11px;
  line-height: 17px;
  letter-spacing: -0.02em;


  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;

  color: #ffffff;
}

.viewreply-suggest-container {
  width: calc(100% - 16px);
  margin-right: 8px;
  margin-left: 8px;
  background: #2d9cdb;
  /* 시그니처컬러 */
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 13px;

  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 20px;
}

.viewreply-suggest-text {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 150%;
  /* or 25px */

  letter-spacing: -0.04em;

  /* 화이트 */
  flex-grow: 1;

  color: #ffffff;
}

.viewreply-suggest-img {
  width: 90px;
  height: 90px;
}

.ion-color-viewer-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --min-height: 53px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 53px;
}

.viewer-content {
  position: relative;
  width: 100%;
  height: calc(100% - 43px);
  --background: #dae8ef;
  --overflow: none;
  /* -webkit-overflow-scrolling: touch; */
}

.viewer-container {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #eff3f5;
  /* padding-top: 16px; */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.viewer-container-with-suggest {
  height: calc(100% - 25px);
  margin-bottom: -13px;
}

.viewer-header {
  height: 53px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.viewer-title {
  color: #393939;
}

.viewer-footer {
  height: 47px;
}

.ion-color-viewer-reply {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;
  height: 47px;
  margin: 0px;
}

.viewer-reply-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.viewer-reply-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.02em;
  text-align: left;
  flex-grow: 1;

  color: #6b6b6b;
}

/* .viewer-reply-button-container {
  width: 40px;
  height: 40px;
  border-radius: 13px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;

  background: #fd6128;
  padding: 8px;
}

.viewer-reply-button {
  width: 24px;
  height: 24px;
  color: #fff;
} */

.viewer-reply-suggest {
  /* position: absolute;
    bottom: 0px; */
  position: relative;
  box-sizing: border-box;
  width: calc(100% - 16px);
  background: #2d9cdb;
  /* left: 0px; */
  border-radius: 13px 13px 0px 0px;

  margin-right: 8px;
  margin-left: 8px;

  padding-right: 16px;
  padding-left: 16px;
  padding-top: 10.5px;
  padding-bottom: 10.5px;

  font-size: 11px;
  line-height: 17px;
  letter-spacing: -0.02em;
  z-index: 2;
  color: #ffffff;
}

.viewer-content-waiting-container {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.viewer-content-waiting {
  height: 30px;
  width: 30px;
}

.viewer-nudge-button {
  width: 70%;

  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 100%;
  background: #fddd0a;
  color: #381f1f;
}

.viewer-nudge-icon {
  width: 24px;
  height: 24px;
  margin: 0px 20px;
}

.viewer-recommends-container {
  margin-top: 10px;
  margin-bottom: 90px;
}

.viewer-recommends-similar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;

  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}

.viewer-recommends-similar-item-container {
  width: 250px;
  min-width: 250px;
  height: 130px;
  padding: 10px 10px;
  margin-right: 10px;
  background: #ffffff;
  /* 시그니처컬러 */

  border: 1px solid #2d9cdb;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}

.viewer-recommends-similar-item-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.viewer-recommends-similar-item-header-title {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 100%;
  /* identical to box height, or 13px */

  letter-spacing: -0.03em;

  /* 시그니처컬러 */

  color: #2d9cdb;
  margin-right: 5px;
}

.viewer-recommends-similar-item-title-icon-container {
  margin-right: 5px;
  min-width: 30px;
  height: 17px;
  position: relative;
}

.viewer-recommends-similar-item-title-inner-icon {
  position: absolute;
  left: 0px;
  top: -5px;
  min-width: 23px;
  height: 30px;
}

.viewer-recommends-similar-item-text {
  height: 46px;
  width: 100%;

  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: -0.04em;

  color: #171717;
}

.viewer-recommends-item-info {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 100%;
  /* identical to box height, or 10px */

  letter-spacing: -0.04em;

  /* 프로필날짜조회수 */

  color: #888888;
  margin-right: 20px;
}

.viewer-recommends-prev-next-container {
  width: 100%;
  padding: 10px 8px;
}

.viewer-recommends-prev-next-item-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  background: #f3fbff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 5px;

  padding: 7px 5px;
  margin-bottom: 10px;
}

.viewer-recommends-prev-next-item-title-icon-container {
  padding: 0px 5px;
  min-width: 40px;
}

.viewer-recommends-prev-next-item-title-inner-icon {
  min-width: 23px;
  height: 30px;
}

.viewer-recommends-prev-next-item-detail-container {
  flex-grow: 1;
}

.viewer-recommends-prev-next-item-text {
  width: 100%;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  /* identical to box height, or 13px */

  letter-spacing: -0.04em;

  color: #171717;
}

.viewer-recommends-prev-next-item-type {
  min-width: 50px;

  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 100%;
  /* identical to box height, or 13px */

  letter-spacing: -0.03em;
  text-align: center;
  /* 시그니처컬러 */

  color: #2d9cdb;
}

.viewer-recommends-option-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 8px;
  padding-bottom: 8px;
}

.login-container {
  height: 100%;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-catchphase {
  width: 100%;
  margin-top: 50px;
  text-align: center;

  font-size: 17px;
  line-height: 22px;
  /* font-weight: bold; */
  color: #171717;
}

.login-catchphase-normal {
  margin-bottom: 5px;
}

.login-catchphase-larger {
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 5px;
}

.login-kakao-button {
  width: 250px;
  height: 45px;
  --background: #fddd0a;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  --border-radius: 0px;
}

.login-kakao-logo {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.login-kakao-text {
  font-size: 17px;
  line-height: 100%;
  text-align: center;
  font-weight: bold;

  color: #381f1f;
}

.login-apple-button {
  margin-top: 20px;
  width: 250px;
  height: 45px;
  --background: #fff;
  --border-width: 1px;
  --border-color: #000;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  --border-radius: 0px;
}

.login-apple-logo {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  color: #171717;
  margin-bottom: 5px;
}

.login-apple-text {
  font-size: 17px;
  line-height: 100%;
  text-align: center;
  font-weight: bold;

  color: #171717;
}

.login-logo-h {
  margin-top: 20px;
  width: 135px;
}

.login-emoticon {
  width: 150px;
}

.loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
}
.loading-title-container {
  width: 100%;
  height: 35%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loading-title {
  width: 100%;

  text-align: center;
  font-size: 22px;
  line-height: 30px;
}

.loading-logo {
  width: 100%;
  height: 30%;
  object-fit: contain;
}

.loading-name {
  font-size: 22px;
  line-height: 22px;
  text-align: center;
}

.loading-logo-container {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register0-container {
  height: 100%;
  padding-top: 50px;
  padding-bottom: 70px;
  padding-left: 25px;
  padding-right: 25px;
  background: #fff;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
  position: relative;
}

.register0-waiting-message {
  margin-top: 15px;
  margin-bottom: -70px;

  font-size: 13px;
  line-height: 20px;
  /* or 154% */

  letter-spacing: -0.02em;

  /* 프로필아이디 */

  color: #6b6b6b;
}

.register0-title-description {
  font-size: 17px;
  font-weight: bold;
  line-height: 20px;
  color: #fd6128;
  margin-bottom: 24px;
}

.register0-service-description-container {
  font-size: 20px;
  line-height: 37px;
  font-weight: bold;

  color: #2d9cdb;
  white-space: nowrap;
  margin-bottom: 60px;
}

.register0-service-description-hightlight {
  font-size: 22px;
  font-weight: bold;
}

.register0-select-container {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 20px 20px 100px 20px;
}

.register0-select-middle {
  flex-grow: 1;
}
.register0-select-text {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 20px;
}

.register0-image-container {
  width: 100%;
  margin-top: 160px;
  position: relative;
}

.register0-image {
  position: absolute;
  right: -25px;
  width: 340px;
}

.register0-select-pharamacist {
  width: 150px;
  height: 150px;
  --background: #2d9cdb;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  --border-radius: 13px;

  font-size: 17px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
  color: #ffffff;
}

.register0-select-student {
  width: 150px;
  height: 150px;
  --background: #a1a0a5;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  --border-radius: 13px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
  font-size: 17px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;

  color: #ffffff;
}

.register-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.register-title-sub {
  padding-top: 130px;

  font-size: 20px;
  line-height: 31px;

  color: #171717;
}

.register-title {
  font-size: 20px;
  font-weight: bold;
  color: #171717;
}

.register-title-margin-top {
  margin-top: 25px;
}

.register-progress-container {
  display: flex;
  flex-direction: row;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 25px;
}

.register-progress-done {
  width: 24px;
  height: 24px;
  margin-left: 20px;
  margin-right: 20px;
  border: 2px solid #2d9cdb;
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;

  font-size: 12px;
  line-height: 24px;
  text-align: center;

  color: #2d9cdb;
}

.register-progress-undone {
  width: 24px;
  height: 24px;
  margin-left: 20px;
  margin-right: 20px;
  border: 2px solid #a1a0a5;
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
  
  font-size: 12px;
  line-height: 24px;
  text-align: center;

  color: #a1a0a5;
}

.register-popup-close {
  --color: #ececec;
  height: 17px;
  --padding-start: 13px;
  --padding-end: 13px;
  margin: 0px;
}

.register-popup-close-icon {
  width: 14px;
  height: 14px;
}

.register-popup-search-icon {
  width: 16px;
  height: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-right: 14px;
  color: #2d9cdb;
}

.register-navigate-container {
  width: 100%;
  height: 61px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.ion-color-register-navigate-inactive {
  --ion-color-base: #0000;
  --box-shadow: none;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  height: 40px;

  font-size: 17px;
  line-height: normal;
  text-align: right;

  color: #a1a0a5;
}

.register-navigate-space {
  flex-grow: 1;
}

.ion-color-register-navigate-active {
  --ion-color-base: #0000;
  --box-shadow: none;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  height: 40px;

  font-size: 17px;
  line-height: normal;
  text-align: right;
  font-weight: bold;

  color: #fd6128;
}

.register-chat-guide {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  text-align: right;
  padding-right: 5px;
}

.register-chat-kakao {
  height: 40px;
  width: 82px;
}

.register1-container {
  padding-top: 18px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #dae8ef;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.register1-service-description {
  width: 220px;

  font-size: 12px;
  line-height: 17px;
  text-align: center;

  color: #6b6b6b;
}

.register1-body-container {
  background: #fff;
  width: calc(100% - 16px);
  margin-top: 5px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 23px;
  padding-bottom: 23px;
  border-radius: 13px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.register1-button-description {
  margin-top: 150px;
  margin-bottom: 15px;

  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;

  color: #2d9cdb;
}

.register1-button-description-highlight {
  font-weight: bold;
  font-size: 15px;
}

.register1-button {
  width: 100%;
  margin-top: 0px;
  height: 48px;
  --background: #2d9cdb;
  --border-radius: 24px;

  font-size: 17px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.1em;

  color: #ffffff;
}

.register1-item-container {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  align-items: center;
}

.register1-item-name {
  min-width: 30px;
  margin-right: 20px;

  font-size: 15px;
  line-height: normal;
  letter-spacing: 0.1em;

  color: #888888;
}

.register1-item-value-diabled {
  flex-grow: 1;

  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.1em;

  color: #a1a0a5;
}

.register1-item-input {
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;

  margin-right: 10px;

  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.1em;

  color: #171717;

  border-bottom: 0.5px solid #a1a0a5;
}

.register2-container {
  padding-top: 18px;
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  background: #dae8ef;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.register2-service-description {
  width: 100%;
  text-align: center;

  font-size: 12px;
  line-height: 17px;
  text-align: center;

  color: #6b6b6b;
  margin-bottom: 5px;
}

.register2-body-container {
  background: #fff;
  width: calc(100% - 16px);
  margin-top: 5px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 23px;
  padding-bottom: 23px;
  border-radius: 13px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.register2-item-container {
  display: flex;
  flex-direction: row;
  padding-top: 24px;
  align-items: center;
}

.register2-item-name {
  min-width: 30px;
  margin-right: 20px;

  font-size: 15px;
  line-height: normal;
  letter-spacing: 0.1em;

  color: #888888;
}

.register2-item-value-diabled {
  flex-grow: 1;

  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.1em;

  color: #a1a0a5;
}

.register2-item-input {
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;

  margin-right: 10px;

  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.1em;

  color: #171717;

  border-bottom: 0.5px solid #a1a0a5;
}

.register2-item-checked {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 2px solid #2d9cdb;
  color: #2d9cdb;
  font-size: 14px;
  padding-top: 4px;
  text-align: center;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
}

.register2-item-unchecked {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 2px solid #ececec;
  color: #ececec;
  font-size: 14px;
  padding-top: 4px;
  text-align: center;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
}

.register2-title-description {
  margin-top: 8px;

  font-size: 10px;
  line-height: normal;

  color: #2d9cdb;
}

.register2-title-description-highlight {
  margin-top: 15px;
  font-weight: bold;
}

.register2-work-type-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  margin-top: 10px;
  margin-bottom: 5px;
}

.register2-license-guide {
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 10px;

  font-size: 13px;
  line-height: 20px;
  color: #fd6128;
}

.register2-work-type-selected {
  height: 32px;
  padding-top: 10px;
  margin-top: 15px;
  margin-left: 2px;
  margin-right: 2px;
  width: 64px;
  background: #2d9cdb;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;

  font-size: 11px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  color: #f2f2f2;
}

.register2-work-type-unselected {
  height: 32px;
  padding-top: 10px;
  padding-top: 10px;
  margin-top: 15px;
  margin-left: 2px;
  margin-right: 2px;
  width: 64px;
  background: #fff;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;

  font-size: 11px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  color: #a1a0a5;
}

.register2-upload-container {
  margin-top: 16px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
}

.register2-upload-button-container {
  flex-grow: 1;
  height: 33px;
  overflow: hidden;
  display: inline-block;
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
}

.register2-upload-button {
  height: 32px;
  width: 100%;
  --padding-start: 25px;
  --padding-end: 25px;
  margin: 0px;
  background: #ffffff;
  /* 시그니처컬러 */
  border: 1px solid #2d9cdb;
  box-sizing: border-box;
  border-radius: 16.1667px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;

  color: #2d9cdb;
}

.register2-upload-file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.register2-upload-button-icon {
  width: 18px;
  height: 18px;
  margin-right: 2px;
}

.register2-upload-button-text {
  font-size: 14px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.1em;

  color: #a1a0a5;
}

.register2-upload-viewer-container {
  width: 100%;
}
.register2-upload-viewer {
  margin-top: 15px;
  height: auto;
  width: 100%;
}

.register3-container {
  width: 100%;
  height: 100%;
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #dae8ef;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.register3-service-description {
  width: 220px;

  font-size: 12px;
  line-height: 17px;
  text-align: center;

  color: #6b6b6b;
  margin-bottom: 5px;
}

.register3-body-container {
  background: #fff;
  width: calc(100% - 16px);
  margin-top: 5px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 23px;
  padding-bottom: 23px;
  border-radius: 13px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.register3-item-container {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  align-items: flex-start;
}

.register3-item-name {
  min-width: 30px;
  margin-top: 3px;
  margin-right: 10px;

  font-size: 15px;
  line-height: normal;
  letter-spacing: 0.1em;

  color: #888888;
}

.register3-item-input {
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  margin-left: 10px;
  margin-right: 10px;

  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.1em;

  color: #171717;

  border-bottom: 0.5px solid #a1a0a5;
}

.register3-item-textarea {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;

  color: #6b6b6b;
}

.register3-profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.register3-profile-inner {
  width: 100px;
  height: 100px;
  position: relative;
}

.register3-profile-image {
  border-radius: 50px;
}

.register3-profile-upload-button-container {
  height: 28px;
  width: 28px;
  bottom: 5px;
  right: 5px;
  position: absolute;
}

.register3-profile-upload-button-inner {
  height: 100%;
  width: 100%;
  position: relative;
}

.register3-profile-upload-button {
  height: 100%;
  width: 100%;
  --padding-start: 25px;
  --padding-end: 25px;
  margin: 0px;
  background: #2d9cdb;
  /* 시그니처컬러 */
  border-radius: 14px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;

  color: #fff;
}

.ion-color-register3-profile-upload-button {
  height: 100%;
  width: 100%;
  --ion-color-base: #2d9cdb;
  --border-radius: 50%;
  --padding-start: 0px;
  --padding-end: 0px;
  margin: 0px;
  color: #fff;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
}

.register3-profile-upload-file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.register3-profile-upload-button-icon {
  width: 18px;
  height: 18px;
  margin-right: 2px;
  color: #fff;
}

.register3-upload-button-text {
  font-size: 14px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.1em;

  color: #a1a0a5;
}

.register-survey-title-margin {
  margin-bottom: 40px;
}

.register3-survey-item-container {
  padding-left: 14px;
  /* padding-right: 14px; */
  margin-bottom: 20px;
}

.register3-survey-item {
  margin-bottom: 10px;
}

.register3-survey-subitem {
  padding-left: 30px;
}

.ion-color-register3-checkbox {
  margin-right: 13px;
  --background-checked: #2d9cdb;
  --border-color-checked: #2d9cdb;
  --border-color: #c4c4c4;
  --checkmark-color: #fff;
}

.register3-checkbox-text {
  font-size: 15px;
  font-weight: bold;
  line-height: 16px;
  letter-spacing: 0.1em;

  /* qna타이틀내용 */

  color: #171717;
}

.register3-checkbox-text-input {
  flex-grow: 1;

  padding-left: 10px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  min-width: 0px;

  font-size: 16px;
  line-height: normal;
  /* letter-spacing: 0.1em; */

  color: #171717;
  border: none;
  border-bottom: 0.5px solid #a1a0a5;
  outline-style: none;
  background-color: #fff;
}

.register3-checkbox-text-code {
  min-width: 120px;
  margin-right: 10px;
}

.register4-container {
  widows: 100%;
  height: 100%;
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #dae8ef;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.register4-service-description-container {
  width: 100%;
  margin-top: 15px;
}

.register4-service-description {
  font-size: 13px;
  line-height: 20px;

  color: #171717;
  margin-bottom: 15px;
}

.register4-body-container {
  background: #fff;
  width: calc(100% - 16px);
  margin-top: 13px;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 23px;
  padding-bottom: 23px;
  border-radius: 13px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.register4-check-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.register4-check-item {
  flex-grow: 1;
  margin-left: 12px;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.02em;

  /* 프로필아이디 */

  color: #6b6b6b;
}

.register4-check-item-description-container {
  padding-left: 36px;
}

.register4-check-item-description {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 155.11%;
  /* or 19px */

  /* 프로필날짜조회수 */

  color: #888888;
}

.register4-line {
  margin: 10px 0px;
  width: 100%;
  border-bottom: 1px solid #2d9cdb;
}

.register4-check-item-all {
  margin-left: 12px;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;

  /* qna타이틀내용 */

  color: #171717;
}

.register4-sub-item-container {
  padding-left: 36px;
}

.ion-color-register4-finalize {
  margin-left: 24px;
  margin-right: 24px;
  height: 48px;
  width: calc(100% - 48px);
  --ion-color-base: #2d9cdb;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.1);
  --border-radius: 24px;
  --padding-start: 0px;
  --padding-end: 0px;
}

.ion-color-register4-finalize-inactive {
  margin-left: 24px;
  margin-right: 24px;
  height: 48px;
  width: calc(100% - 48px);
  --ion-color-base: #cecece;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.1);
  --border-radius: 24px;
  --padding-start: 0px;
  --padding-end: 0px;
}

.register4-finalize-container {
  position: -webkit-sticky;
  position: sticky;
  bottom: 10px;
  margin-top: 28px;
  width: 100%;
}

.ion-color-register4-detail-view {
  height: 18px;
  width: 65px;
  --ion-color-base: #fff;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  --border-radius: 16.1667px;
  --padding-start: 0px;
  --padding-end: 0px;

  font-size: 10px;
  line-height: normal;

  color: #a1a0a5;
}

.information-view-popup-container {
  --height: 100%;
  --width: 100%;
  width: 100%;
  height: 100%; }

.register12-work-type-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 28px;
}

.register12-work-type-selected {
  height: 32px;
  padding-top: 10px;
  margin-top: 15px;
  margin-left: 2px;
  margin-right: 2px;
  width: 128px;
  background: #2d9cdb;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;

  font-size: 14px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  color: #f2f2f2;
}

.register12-work-type-unselected {
  height: 32px;
  padding-top: 10px;
  padding-top: 10px;
  margin-top: 15px;
  margin-left: 2px;
  margin-right: 2px;
  width: 128px;
  background: #fff;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 16px;

  font-size: 14px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  color: #a1a0a5;
}


.user-search-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user-search-input {
  flex-grow: 1;
  opacity: 1 !important;
}

.user-search-level {
  width: 30%;
}

.user-admin-list-container {
  flex-grow: 1;
  width: 100%;
}

.user-admin-list-item-profile {
  margin-left: 10px;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.user-admin-list-item-container {
  width: 100%;
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #a1a0a5;

  font-size: 15px;
  line-height: 16px;

  color: #6b6b6b;
}

.user-manage-popup-container {
  --width: 100%;
  --height: 80%;
  --background: #0000;
  padding-left: 8px;
  padding-right: 8px;
}

.user-manage-container {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 13px;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 17px;
  padding-right: 17px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #dadada;
}

.user-manage-title {
  /* width: 100%; */
  /* margin-top: 10px;
    margin-bottom: 5px; */
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.04em;
  font-weight: bold;
  white-space: nowrap;
}

.user-manage-level-container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user-manage-textarea {
  border: 1px solid #686868;
}

.user-manage-level {
  flex-grow: 1;
}

.user-manage-description {
  width: 100%;

  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.02em;

  color: #6b6b6b;
}

.user-manage-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.user-manage-button-container {
  flex-grow: 1;
}

.ion-color-user-manage-cancel-button {
  flex-grow: 1;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 30px;
  --ion-color-base: #fff;
  --border-radius: 12px;
  --border-width: 1.5px;
  --border-style: solid;
  --border-color: #686868;
  --padding-start: 25px;
  --padding-end: 25px;
  --box-shadow: none;

  font-size: 15px;
  line-height: 20px;

  color: #686868;
}

.ion-color-user-manage-button {
  flex-grow: 1;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 30px;
  --ion-color-base: #fff;
  --border-radius: 12px;
  --border-width: 1.5px;
  --border-style: solid;
  --border-color: #2d9cdb;
  --padding-start: 25px;
  --padding-end: 25px;
  --box-shadow: none;

  font-size: 15px;
  line-height: 20px;

  color: #2d9cdb;
}
.ion-color-user-manage-tight-button {
  margin-top: 16px;
  margin-bottom: 16px;
  height: 30px;
  --ion-color-base: #fff;
  --border-radius: 12px;
  --border-width: 1.5px;
  --border-style: solid;
  --border-color: #2d9cdb;
  --padding-start: 25px;
  --padding-end: 25px;
  --box-shadow: none;

  font-size: 15px;
  line-height: 20px;

  color: #2d9cdb;
}

.ion-color-user-manage-button-critical {
  flex-grow: 1;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 30px;
  --ion-color-base: rgb(255, 205, 205);
  --border-radius: 12px;
  --border-width: 1.5px;
  --border-style: solid;
  --border-color: rgb(235, 11, 11);
  --padding-start: 25px;
  --padding-end: 25px;
  --box-shadow: none;

  font-size: 15px;
  line-height: 20px;

  color: rgb(235, 11, 11);
}

.user-manage-image {
  margin-top: 10px;
  margin-bottom: 10px;
  height: auto;
  width: 100%;
}

.admin-board-search-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.admin-board-search-top-fixed{
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 100;
  background-color: #fff;
}

.admin-board-search-input {
  flex-grow: 1;
}

.admin-board-search-level {
  width: 30%;
}

.admin-board-list-container {
  flex-grow: 1;
  width: 100%;
}

.admin-board-list-item-container {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #a1a0a5;
  font-size: 15px;
  line-height: 16px;

  color: #6b6b6b;
}

.admin-content-manage-container {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 13px;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 17px;
  padding-right: 17px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  /* display: flex;
    flex-direction: column;
    align-items: flex-start; */
}

.admin-content-manage-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.admin-content-manage-button-container {
  flex-grow: 1;
}

.ion-color-admin-content-manage-cancel-button {
  flex-grow: 1;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 30px;
  --ion-color-base: #fff;
  --border-radius: 12px;
  --border-width: 1.5px;
  --border-style: solid;
  --border-color: #686868;
  --padding-start: 25px;
  --padding-end: 25px;
  --box-shadow: none;

  font-size: 15px;
  line-height: 20px;

  color: #686868;
}

.ion-color-admin-content-manage-button {
  flex-grow: 1;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 30px;
  --ion-color-base: #fff;
  --border-radius: 12px;
  --border-width: 1.5px;
  --border-style: solid;
  --border-color: #2d9cdb;
  --padding-start: 25px;
  --padding-end: 25px;
  --box-shadow: none;

  font-size: 15px;
  line-height: 20px;

  color: #2d9cdb;
}

.ion-color-admin-content-delete-button {
  flex-grow: 1;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 30px;
  --ion-color-base: #fdd;
  --border-radius: 12px;
  --border-width: 1.5px;
  --border-style: solid;
  --border-color: #f00;
  --padding-start: 25px;
  --padding-end: 25px;
  --box-shadow: none;

  font-size: 15px;
  line-height: 20px;

  color: #2d9cdb;
}

.ql-editor {
  max-height: 400px;
}

.keywords {
  color: #f00;
  font-weight: bold;
  -webkit-user-select: text;
          user-select: text;
}

.admin-notification-recipient-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 28px;
}

.file-input-button-file-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position:relative;
    width: 100%;
    height: 100%;
}

.file-input-button-file {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}

.file-input-button-icon {
    width : var(--icon-size);
    height: var(--icon-size);
}

.ion-color-file-input-button {
    --ion-color-base: var(--icon-color);
    margin: 0px;
    --padding-start: 0px;
    --padding-end: 0px;
    height: 100%;
    width: 100%;
}
.event-info-popup-container {
    --width: 100%;
    --height: 100%;
    --background: #0000;
    padding-left: 20px;
    padding-right: 20px;
}

.event-info-popup-container-full {
    --width: 100%;
    --height: 100%;
    --background: #0000;
    padding-left: 0px;
    padding-right: 0px;
}

.event-info-popup-inner{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.event-info-popup-backdrop {
    flex-grow: 1;
    width: 100%;
}

.event-info-container {
    width: 100%;
    max-height: 100%;
    margin: auto;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.event-info-container-full {
    height: 100%;
}

.event-info-image-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100% - 40px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    align-items: center;
}

.event-info-image {
    width: 100%;
    height: 100%;
    height: auto;
}
.event-info-buttons-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding: 10px 10px;
}

.event-info-button-text {
    padding-left: 5px;
}

.event-info-button-space { 
    flex-grow: 1;
}

.event-info-close-icon {
    width: 14px;
    height: 14px;
}

.modal-wrapper.sc-ion-modal-md {
    --box-shadow: none;
}
.seminar-edit-close{
    position: absolute;
    right: 20px;
    top: 20px;
    width: 50px;
    height: 50px;
    color: #000;
}
.ytp-show-cards-title {
  visibility: hidden;
}

.video-play-player {
  width: 100%;
  margin-bottom: -3px;
}

.video-play-icon {
  width: 80px;
  height: 80px;
  color: #fff;
  position: absolute;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
  -webkit-filter: drop-shadow(0px 0px 10px #0008);
          filter: drop-shadow(0px 0px 10px #0008);
}

.video-player-toggle-selected {
  background: #2d9cdb !important;
  color: #f2f2f2 !important;
}

.video-player-toggle {
  height: 20px;
  padding: 5px 10px;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 11px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  background: #fff;
  color: #a1a0a5;
}

.video-play-toolbar {
  background: #dae8ef;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 25px;
}

.video-play-toolbar-resolution {
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 14px;

  letter-spacing: -0.04em;

  /* 프로필아이디 */
  padding-left: 5px;
  padding-right: 5px;
  color: #6b6b6b;
}

.video-play-toolbar-resolution-selected {
  color: #2d9cdb;
}

.video-play-toolbar-resolution-toggle {
  zoom: 0.4;
  --background-checked: #2d9cdb;
  --background: #a1a0a5;
}

.video-play-toolbar-speed-down-button {
  color: #0000;
  width: 12px;
  height: 12px;
  padding: 0px 20px;
}

.video-play-toolbar-speed-up-button {
  color: #0000;
  width: 12px;
  height: 12px;
  padding: 0px 20px;
}

.video-play-toolbar-speed {
  color: #2d9cdb;

  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 14px;

  letter-spacing: -0.04em;

  /* 프로필아이디 */
  padding-left: 5px;
  padding-right: 5px;
  margin-left: -20px;
  margin-right: -20px;
  min-width: 45px;
  text-align: center;
}

.ion-color-html-composer-component-toolbar {
  --ion-color-base: #fff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --min-height: 53px;
  --padding-top: 0px;
  --padding-botoom: 0px;
  --padding-left: 0px;
  --padding-right: 0px;

  height: 53px;

  font-size: 16px;
  line-height: normal;
}

.html-composer-component-content {
  height: 100%;
  /* overflow-y: hidden; */
}
.html-composer-component-mini {
  width: 100%;
}

.html-composer-component-container {
  width: 100%;
  height: 100%;
  overflow-y: hidden;

  /* display: flex; */
  /* flex-direction: column; */
  padding-left: 17px;
  padding-right: 17px;
  /* padding-bottom: 40px; */
}

.html-composer-component-button {
  font-size: 16px;
  line-height: normal;
  color: #2d9cdb;
  font-weight: bold;
  padding-right: 10px;
}

.ion-color-html-composer-component-show-parent {
  background: #fafafa;
  /* 시그니처컬러 */

  border: 1px solid #2d9cdb;

  --box-shadow: none;

  color: #2d9cdb;
  --ion-color-base: #2d9cdb;
  margin-right: 20px !important;
}

.html-composer-component-show-parent {
  font-size: 15px;
  line-height: 23px;
  font-weight: bold;
  letter-spacing: -0.02em;
  padding: 0px 10px;
}

.html-composer-component-button-disabled {
  font-size: 16px;
  line-height: normal;
  color: #c4c4c4;
  padding-right: 10px;
}

.ion-color-html-composer-component-checkbox {
  margin-right: 5px;
  --background-checked: #2d9cdb;
  --border-color-checked: #2d9cdb;
  --border-color: #c4c4c4;
  --checkmark-color: #fff;
}

.html-composer-component-profile-container {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.html-composer-component-title-container {
  width: calc(100% - 14px);
  margin-left: 7px;
  margin-right: 7px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: 0.5px solid #ececec;
}

.html-composer-component-title-icon {
  height: 17px;
  width: 17px;
  margin-right: 10px;
  margin-top: 6px;
}

.html-composer-component-title-input {
  z-index: 2;
  width: 100%;
  font-weight: 800;

  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.04em;
  color: #171717;
  --padding-start: 0px;
  --padding-end: 0px;
  outline-style: none;
  border: none;
}

.html-composer-component-title-input::-webkit-input-placeholder {
  color: #a1a0a5;
}

.html-composer-component-title-input::placeholder {
  color: #a1a0a5;
}
.html-composer-component-title-input::-webkit-input-placeholder {
  color: #a1a0a5;
}

.html-composer-component-title-input-container {
  flex-grow: 1;
  position: relative;
  align-items: center;
}

.html-composer-component-hidden {
  display: none;
}

.html-composer-component-title-placeholder {
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-left: 8px;
  font-weight: 800;

  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.04em;

  color: #c0c0c0;
  text-align: left;
}

.html-composer-component-title-placeholder-sub {
  font-size: 15px;
}

.html-composer-component-body-container {
  position: relative;
  width: calc(100% - 14px);
  height: calc(100% - 167px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  margin-left: 7px;
  margin-right: 7px;
  padding-bottom: 10px;
}

.html-composer-component-content-placeholder {
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  font-size: 14px;
  line-height: 22px;

  color: #ababab;
}

.html-composer-component-body {
  position: relative;
  background: #0000;
  z-index: 2;
  height: auto;
  width: 100%;
  border: 1px;

  font-size: 14px;
  line-height: 22px;
  padding: 0px;
  color: #171717;
  outline-style: none;
}
.html-composer-component-images-container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.html-composer-component-images-preview {
  width: 72px;
  height: 72px;
  border-radius: 13px;
  border-width: 0px;
  object-fit: cover;
  margin-right: 8px;
}

.html-composer-component-files-container {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  width: calc(100% - 14px);
  margin-left: 7px;
  margin-right: 7px;
}

.html-composer-component-toolbar-button-container {
  /* overflow: hidden; */
  display: inline-block;
  position: relative;
  height: 24px;
  width: 28px;
  margin: 0px;
  padding: 3px 5px;
}

.ion-color-html-composer-component-toolbar-button {
  --ion-color-base: #494949;
  --ion-color-shade: #c4c4c4;
  --ion-color-contrast: #c4c4c4;
  --ion-color-tint: #39393980;

  --padding-start: 0px;
  --padding-end: 0px;
  height: 24px;
  width: 28px;
  margin: 0px;
}

.html-composer-component-toolbar-button-file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 28px;
  height: 24px;
}

.html-composer-component-toolbar-button-icon {
  width: 18px;
  height: 18px;
}

.ql-toolbar.ql-snow {
  color: #494949;
  background: #fafafa;
  border: 1px solid #f5f5f6;
  border-radius: 0px;
  box-sizing: border-box;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 12px;
}

.ql-container.ql-snow {
  border: none !important;
  height: calc(100% - 102px);
}

.ql-editor {
  -webkit-user-select: text;
  max-height: none !important;
  padding: 0px !important;

  font-size: 14px !important;
  line-height: 22px !important;
  color: #171717;
}

.ql-editor.ql-blank::before {
  font-size: 14px !important;
  font-style: normal !important;
  line-height: 22px !important;

  color: #ababab;
}

.ql-mention-list-item.selected {
  background-color: #dae8ef !important;
}

.ql-mention-list-item {
  padding: 0 !important;
  height: 40px;
}

.mention {
  border-radius: 6px !important;
  padding: 2px !important;
  background-color: #dae8ef !important;
  font-weight: bold;
}

.html-composer-component-search-medicine-item-container {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.html-composer-component-search-medicine-item-type {
  color: #2d9cdb;
  display: flex;
  padding-top: 3px;
  flex-direction: column;
  align-items: center;
  min-width: 35px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid #2d9cdb;
  font-weight: 800;

  font-size: 10px;
  line-height: 14px;
  margin-right: 10px;
  letter-spacing: -0.04em;
}

.html-composer-component-search-medicine-item-info {
  flex-grow: 1;
}

.html-composer-component-search-medicine-item-name {
  font-weight: 800;

  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.04em;
  color: #171717;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.html-composer-component-search-medicine-item-description {
  font-size: 10px;
  line-height: 14px;
  letter-spacing: -0.04em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2d9cdb;
}

.html-composer-component-component {
  background: #2d9cdb80;
  border-radius: 4px;
  padding: 0px 4px;
}

.html-composer-component-anonymous-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
}

.html-composer-component-anonymous-toggle {
  zoom: 0.6;
  --background-checked: #2d9cdb;
  --background: #a1a0a5;
}

.html-composer-component-anonymous-button {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  padding-left: 5px;
  width: 105px;
  color: #2d9cdb;
}

.html-composer-component-anonymous-button-disabled {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  padding-left: 5px;
  width: 105px;
  color: #a1a0a5;
}

.ytp-show-cards-title {
  visibility: hidden;
}

.video-play-player {
  width: 100%;
}

.video-player-toggle-selected {
  background: #2d9cdb !important;
  color: #f2f2f2 !important;
}

.video-player-toggle {
  height: 20px;
  padding: 5px 10px;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-left: 2px;
  margin-right: 2px;
  font-size: 11px;
  font-weight: bold;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;

  background: #fff;
  color: #a1a0a5;
}

.test-div-container {
  width: 100%;
  height: 100%;
  background-color: rgb(238, 255, 0);
  display: flex;
  flex-direction: column;
  /* max-height: 300px; */
  /* margin-bottom: 40px; */
}

.test-action-sheet {
  background-color: #f00 !important;
  --color: #fff;
}

.ion-color-test-boards-toolbar {
  --ion-toolbar-color-unchecked: #39393980;
  --ion-toolbar-color-checked: #171717;
  --ion-color-base: #2d9cdb;
  --ion-color-shade: #393939;
  --ion-color-tint: #393939;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --padding-start: 0px;
  --padding-end: 0px;

  --min-height: 53px;
  height: 53px;
}

.test-boards-header {
  height: 53px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.test-boards-segment-button {
  --indicator-color-checked: #f00 !important;
  --indicator-color: #0f0 !important;
  --padding-start: 10px;
  --padding-end: 10px;
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  text-transform: none;

  font-weight: 800;
  font-size: 17px;
  line-height: 19px;
  letter-spacing: -0.1em;
  height: 53px;
  min-height: 53px;
  /* 상단바 제목_비활성화 */
  border-bottom: 4px solid #0f0;

  color: rgb(181, 183, 214) !important;
  /* width: 80px; */
  min-width: 0px;
  /* max-width: 0px; */
}

.segment-button-indicator {
  height: 4px !important;
}
.test-boards-segment-button-text {
  width: 100%;
  height: 30px;
  padding-top: 5px;
  line-break: 30px;
}

.test-boards-segment-button.segment-button-checked {
  font-weight: 800;

  font-size: 17px;
  line-height: 19px;
  border-bottom: 4px solid #f00;

  color: #ffffff !important;
}

.ion-color-test-boards-segment {
  --ion-color-base: #cecece;
  height: 53px;
  justify-content: left;
  color: #cecece;
  --padding-start: 20px;
  --padding-end: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.test-floating-recommends-container {
  position: fixed;
  bottom: 0px;
  height: 100px;
  width: 100%;
  max-width: 600px;
  background-color: #eef;

  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}
.test-floating-recommends-container::-webkit-scrollbar {
  display: inline-table !important;
  width: 3px;
  height: 10px;
  background: none;
}
.test-floating-recommends-container::-webkit-scrollbar-thumb {
  background: #fdd;
  opacity: 0.4;
}
.test-floating-recommends-container::-webkit-scrollbar-track {
  background: none;
}

.test-floating-recommend-item-container {
  width: 400px;
  height: calc(100% - 20px);
  margin: 10px;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #171717;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
}

.sendbird-channel-list {
  width: 100% !important;
}

.sendbird-fileviewer__header {
  max-width: 100% !important;
}

/* .sendbird-fileviewer__header-delete {
    visibility: hidden;
} */

.poll-simple-page-description {
  width: 100%;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;

  color: #171717;
  margin-bottom: 25px;
}

.poll-simple-page-item-container {
  width: 100%;
  margin-bottom: 25px;
}

.poll-simple-page-item-question {
  width: 100%;

  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  margin-bottom: 15px;

  color: #000000;
}

.poll-simple-page-item-margin {
  margin-bottom: 15px;
}

.poll-simple-page-item-text {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 100%;

  color: #000000;
}

.poll-simple-page-input-container {
  width: 100%;
  position: relative;
  border: 1px solid #a1a0a5;
  background: rgba(244, 244, 244, 0.5);
}

.poll-simple-page-input-placeholder {
  z-index: 2;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 150%;
  color: #888;
  padding: 3px 10px;
}

.poll-simple-page-input {
  background: #0000;
  z-index: 0;
  min-height: 22px !important;
  width: 100%;

  font-size: 15px;
  line-height: 150%;
  padding: 0px;
  color: #171717;
  outline-style: none;
  padding: 3px 10px;
  border: none;
}

.poll-simple-page-input-inline {
  background: rgba(244, 244, 244, 0.5);
  z-index: 2;
  height: 22px !important;
  flex-grow: 1;
  border: 1px solid #a1a0a5;

  font-size: 15px;
  line-height: 150%;
  padding: 0px;
  color: #171717;
  outline-style: none;
  padding: 0px 5px;
}

.poll-simple-page-radio {
  margin-right: 5px;
  color: #000;
}

.poll-simple-page-radio:checked {
  margin-right: 5px;
  color: #888;
}

.poll-simple-page-item-slider-container {
  width: 100%;
  height: 40px;
}

.poll-simple-page-item-slider {
}

.ion-color-poll-simple-page-checkbox {
  --ion-color-base: #2667eb;
  --ion-color-contrast: #fff;
  color: #fff;
  --border-width: 1px;
  margin-right: 10px;
  --border-radius: 0px;
  width: 20px;
  height: 20px;
}



.slides-container{
    width: 100%;
    height: 50px; 
}
.admin-product-manage-body-container {
  width: 100%;
  min-height: 300px;
  max-height: 600px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  margin-left: 7px;
  margin-right: 7px;
  padding-bottom: 10px;
}
/* 

.admin-product-manage-body-container {
    width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    margin-left: 7px;
    margin-right:7px;
    padding-bottom: 10px;
}

.admin-product-manage-sub-container {
    border: 0.5px solid #DDD;
}


.admin-product-manage-sub-input-container {
    flex-grow: 1;
    border: 0.5px solid #DDD;
    font-size: 15px;
    line-height: 20px;
    font-weight: normal;
    margin-left: 10px;
    --padding-start: 10px;
    --padding-end: 0px;
    --padding-top: 0px;
    --padding-bottom: 0px;
}

.admin-product-manage-sub-item-title{
    min-width: 50px;
}

.admin-product-manage-sub-image {
    width: 80px;
    height: 80px;
    min-width: 80px;
    max-width: 80px;
    min-height: 80px;
    max-height: 80px;
    border: 0.5px solid #DDD;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    object-fit: cover;
}

.admin-product-manage-sub-life {
    width: 30px;
    height: 180px;
    background: greenyellow;
}

.admin-product-manage-sub-life-deleted {
    width: 30px;
    height: 180px;
    background: lightsalmon;
}

.admin-product-manage-banner-image {
    flex-grow: 1;
    border: 0.5px solid #DDD;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row;
    min-height: 80px;

} */

.register-waiting-logo {
  width: 120px;
  height: 120px;
  margin-top: 15vh;
}

.register-waiting-logo-small {
  width: 70px;
  height: 70px;
}

.register-waiting-status {
  margin-left: 25px;
  font-weight: 800;

  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.04em;

  color: #6b6b6b;
}

.register-waiting-status-text {
  font-weight: 800;

  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.04em;
  white-space: nowrap;

  color: #6b6b6b;
}

.register-waiting-message-container {
  height: 95px;
  padding-top: 15px;
  padding-bottom: 5px;
}

.register-waiting-swipe {
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-height: 100%;
  background: #dae8ef;
  max-height: calc(100% - 143px);
}

.register-waiting-read-content-button-container {
  padding: 5px 0px;
}

.register-waiting-read-content-button {
  background: #233ec4;
  border-radius: 7px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;

  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.04em;
  padding: 10px 15px;
  color: #ffffff;
}

.register-waiting-start-content-button {
  background: #2d9cdb;
  border-radius: 7px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;

  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.04em;
  padding: 10px 15px;
  color: #ffffff;
}

.register-waiting-status-text-etc {
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: -0.02em;
  white-space: nowrap;

  color: #6b6b6b;
}

.register-waiting-status-waiting {
  color: #2d9cdb;
}

.register-waiting-status-denied {
  color: #f32e6d;
}

.register-waiting-message {
  margin-left: 25px;
  margin-top: 15px;

  font-size: 15px;
  line-height: 20px;
  /* or 154% */

  letter-spacing: -0.02em;

  /* 프로필아이디 */

  color: #6b6b6b;
}

.register-waiting-guide.register-waiting-guide-margin {
  margin-top: 115px;
}

.register-waiting-guide {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: flex-end;
  width: 100%;
  text-align: right;
  padding-right: 25px;
  padding-left: 25px;

  font-size: 13px;
  line-height: 20px;
  /* or 154% */

  letter-spacing: -0.02em;

  /* 프로필아이디 */

  color: #6b6b6b;
}

.register-waiting-kakao {
  height: 40px;
  width: 82px;
  margin-top: 10px;
}

.register-waiting-message-box {
  margin-top: 20px;
  margin-right: 15px;
  margin-left: 15px;
  width: calc(100% - 30px);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #888888;
  box-sizing: border-box;

  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.02em;

  /* 프로필아이디 */

  color: #6b6b6b;
}

.register-waiting-bottom {
  margin-top: 70px;

  font-size: 17px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1em;
}

.ion-color-register-waiting-button {
  margin-left: 24px;
  margin-right: 24px;
  height: 48px;
  width: calc(100% - 48px);
  --ion-color-base: #2d9cdb;
  --ion-color-shade: #c4c4c4;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.1);
  --border-radius: 24px;
  --padding-start: 0px;
  --padding-end: 0px;
  color: #fff;
}

.ion-color-register-waiting-button-inactive {
  margin-left: 24px;
  margin-right: 24px;
  height: 48px;
  width: calc(100% - 48px);
  --ion-color-base: #fff;
  --ion-color-shade: #c4c4c4;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.1);
  --border-radius: 24px;
  --border-width: 1px;
  --border-style: solid;
  --border-color: #afafaf;
  --padding-start: 0px;
  --padding-end: 0px;
  color: #6b6b6b;
  margin-bottom: 15px;
}

.register-cancel-done-popup-title {
  color: #f32e6d;
}

.ion-color-register-waiting-fab-ask {
  --ion-color-base: #ffdf37;
  --ion-color-contrast: #0000;
  --ion-color-shade: #ffdf37;
  width: 84px;
  height: 84px;
}

.ion-color-register-waiting-fab-start {
  --ion-color-base: #2d9cdb;
  --ion-color-contrast: #0000;
  --ion-color-shade: #2d9cdb;
  width: 84px;
  height: 84px;
}

.register-waiting-fab-text {
  color: #fff;

  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.1em;
  font-weight: bold;
}

.register-waiting-fab-text-ask {
  color: #392f31;

  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.1em;
  font-weight: bold;
}

.register-waiting-page-container {
  position: relative;
  top: 20px;
  width: 100%;
  z-index: 3;
}

.register-waiting-content-sample {
  margin: auto;
  display: block;
}

.register-waiting-page-selected {
  background: #4448;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
  margin: 0px 7px;
}

.register-waiting-page {
  background: #8888;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  z-index: 0;
  margin: 0px 8px;
}

.welcome-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #dae8ef;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
}

.welcome-slider-container {
  margin-top: 23px;
}

.welcome-swipe-container {
  width: 100%;
  height: calc(100vh - 33px);
}

.welcome-swipe {
  width: 100%;
  height: 100%;
}

.welcome-0-profile {
  margin-top: 40px;
  width: 80px;
  height: 80px;
  border-radius: 40px;
}

.welcome-0-welcome {
  margin-top: 17px;

  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.05em;
  font-weight: bold;
  color: #2d9cdb;
}

.welcome-0-description {
  width: calc(100% - 10px);
  margin-top: 15px;
  margin-left: 5px;
  margin-right: 5px;

  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 22px;
  padding-right: 22px;

  background: #ffffff;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 13px;

  font-size: 16px;
  line-height: 25px;

  color: #000000;
}

.welcome-next-button-container {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ion-color-welcome-next-button {
  --ion-color-base: #0000;
  --box-shadow: none;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  width: 170px;
  height: 36px;

  font-size: 17px;
  line-height: normal;
  text-align: right;

  border: 1.5px solid #2d9cdb;
  box-sizing: border-box;
  border-radius: 5px;

  color: #2d9cdb;
}

.welcome-next-button-container-reverse {
  flex-grow: 1;
  width: 100%;
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  align-items: center;
  background: #2d9cdb;
}

.ion-color-welcome-next-button-reverse {
  --ion-color-base: #0000;
  --box-shadow: none;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  width: 170px;
  height: 36px;

  font-size: 17px;
  line-height: normal;
  text-align: right;

  border: 1.5px solid #fff;
  box-sizing: border-box;
  border-radius: 5px;

  color: #fff;
}

.welcome-1-title {
  margin-top: 20px;

  font-size: 20px;
  line-height: 23px;
  font-weight: bold;
  letter-spacing: 0.05em;

  color: #2d9cdb;
}

.welcome-1-description-container {
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.welcome-1-profile {
  width: 50px;
  height: 50px;
}

.welcome-1-description {
  flex-grow: 1;
  margin-right: 5px;
  margin-left: 5px;

  font-size: 14px;
  line-height: 19px;

  color: #171717;
}

.welcome-1-subtitle {
  margin-top: 34px;
  padding-left: 10px;
  width: 100%;

  font-size: 14px;
  line-height: 19px;

  color: #171717;
}

.welcome-1-items-container {
  width: calc(100% - 10px);
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;

  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 7px;
  padding-right: 7px;

  background: #ffffff;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
}

.welcome-navigate-container {
  width: 100%;
  flex-grow: 1;
  align-items: flex-end;
  height: 21px;
  display: flex;
  flex-direction: row;
}

.welcome-navigate-container-reverse {
  width: 100%;
  flex-grow: 1;
  align-items: flex-end;
  height: 21px;
  display: flex;
  flex-direction: row;
  background: #2d9cdb;
}

.welcome-navigate-space {
  flex-grow: 1;
}

.ion-color-welcome-navigate-active {
  --ion-color-base: #0000;
  --box-shadow: none;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  height: 40px;

  font-size: 17px;
  line-height: normal;
  text-align: right;

  color: #2d9cdb;
}

.ion-color-welcome-navigate-active-reverse {
  --ion-color-base: #0000;
  --box-shadow: none;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  height: 40px;

  font-size: 17px;
  line-height: normal;
  text-align: right;

  color: #fff;
}

.welcome-1-description-title {
  margin-top: 24px;
  margin-bottom: 24px;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.04em;

  color: #2d9cdb;
}

.welcome-1-description-image {
  width: 100%;
  height: auto;
  background: #2d9cdb;
}

.slider-index-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 120px;
    height: 10px;
}


.slider-index-dot {
    flex-grow:1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slider-index-dot-disabled {
    height: 5px;
    width: 5px;
    background: #2D9CDB;
    border-radius: 2.5px;    
}


.slider-index-dot-enabled {
    height: 10px;
    width: 10px;
    background: #2D9CDB;
    border-radius: 5px;
}
.progress-popup-container {
  --width: 100%;
  --height: 200px;
  --background: #0000;
  padding-left: 8px;
  padding-right: 8px;
  --box-shadow: none;
}

.progress-container {
  width: 100%;
  height: 100%;
  background: #0000;
  box-shadow: none;
  border-radius: 13px;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 17px;
  padding-right: 17px;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-spinner {
  width: 100px;
  height: 100px;
  margin-right: 50px;
  margin-left: 50px;
}

.progress-label {
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;

  color: #a1a0a5;
}

.keyword-info-popup-container {
  --width: 100%;
  /* --height: auto; */
  --background: #0000;
  padding-left: 20px;
  padding-right: 20px;
}

.keyword-info-popup-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.keyword-info-popup-backdrop {
  flex-grow: 1;
  width: 100%;
}

.keyword-info-container {
  width: 100%;
  margin: auto;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  border-radius: 13px;
  /* padding-top: 13px;
    padding-bottom: 13px;
    padding-left : 17px;
    padding-right : 17px; */
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.keyword-title-container {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  padding-top: 13px;
  padding-left: 16px;
}

.keyword-name-container {
  flex-grow: 1;
}

.keyword-name-kor {
  font-size: 17px;
  line-height: normal;
  font-weight: bold;
  color: #171717;
}

.keyword-name-eng {
  font-size: 14px;
  line-height: normal;

  color: #2d9cdb;
}

.ion-color-keyword-popup-close {
  --color: #ececec;
  --padding-start: 13px;
  --padding-end: 16px;
  --box-shadow: none;
  margin: 0px;
}

.keyword-popup-close-icon {
  width: 14px;
  height: 14px;
}

.keyword-ingredients-container {
  width: 100%;
  background: rgba(244, 244, 244, 0.5);
  border-radius: 13px;
}

.keyword-ingredient-container {
  width: 100%;
  padding-left: 16px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.keyword-ingredient-info {
  font-size: 12px;
  line-height: normal;
  flex-grow: 1;
  color: #6b6b6b;
}

.keyword-ingredient-name {
  font-weight: bold;
}

.keyword-ingredient-link-container {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  height: 32px;
  min-width: 32px;
  max-width: 32px;
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.1);
}

.keyword-ingredient-link {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #2d9cdb;
}

.keyword-ingredient-link-disabled {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #2d9cdb80;
}

.keyword-buttons-container {
  width: 100%;
  height: 32px;
  background: #2d9cdb;
  border-radius: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ion-color-keyword-link-disabled {
  flex-grow: 1;
  --box-shadow: none;
  margin: 0px;

  font-size: 12px;
  line-height: 12px;

  color: #bad8df;
}
.keyword-link-container {
  padding-left: 13px;
  padding-right: 13px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.keyword-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: none;
  margin: 0px;

  font-size: 12px;
  line-height: 12px;
  font-weight: bold;

  color: #ffffff;
}

.keyword-link-disabled {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: none;
  margin: 0px;

  font-size: 12px;
  line-height: 12px;

  color: #ffffff80;
}

.keyword-link-icon {
  height: 14px;
  width: 14px;
  margin-left: 4px;
  border-radius: 7px;
  border: 1px solid;
}

.modal-wrapper.sc-ion-modal-md {
  --box-shadow: none;
}


.image-viewer-popup-container {
    --width: 100%;
    --height: 100%;
    --background: #fff;
}

.image-viewer-popup-inner{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.image-viewer-title {
    width: 100%;
    text-align: center;
}

.image-viewer-swipe {
    height: 100%;
    max-height: 90%;
}
.react-swipeable-view-container {
    height: 100% !important;
}

.image-view-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.image-view{
    height: auto;
    width:100%;
}
.confirm-icon {
  width: 36px;
  height: 36px;
  margin-bottom: 29px;
}

.confirm-image {
  width: 100%;
  max-width: 360px;
  margin-bottom: 20px;
}

.confirm-title-container {
  width: 100%;
  margin-bottom: 16px;

  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.04em;
  font-weight: bold;
}

.confirm-title-highlight {
  color: #2d9cdb;
}

.confirm-email-container {
  width: 100%;
}

.confirm-body-container {
  width: 100%;

  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.02em;

  color: #6b6b6b;
}

.confirm-button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.confirm-button {
  margin-top: 16px;
  min-width: 40%;
  height: 29px;
  --ion-color-base: #2d9cdb;
  background: #2d9cdb;
  --border-radius: 0px;
  /* --border-width: 2px;
    --border-style: solid;
    --border-color: #fff; */
  --padding-start: 15px;
  --padding-end: 15px;
  --box-shadow: none;

  font-size: 15px;
  line-height: 23px;
  text-align: center;

  color: #fff;
}

.confirm-button-reverse {
  margin-top: 16px;
  min-width: 40%;
  height: 29px;
  --ion-color-base: #fff;
  background: #fff;
  --border-radius: 0px;
  --border-width: 2px;
  --border-style: solid;
  --border-color: #2d9cdb;
  --padding-start: 15px;
  --padding-end: 15px;
  --box-shadow: none;

  font-size: 15px;
  line-height: 23px;
  text-align: center;

  color: #2d9cdb;
}

.confirm-cancel-button {
  margin-top: 16px;
  min-width: 40%;
  height: 29px;
  --ion-color-base: #888;
  background: #888;
  --border-radius: 0px;
  /* --border-width: 2px;
    --border-style: solid;
    --border-color: #A1A0A5; */
  --padding-start: 15px;
  --padding-end: 15px;
  --box-shadow: none;

  font-size: 15px;
  line-height: 23px;
  text-align: center;

  color: #fff;
}

.confirm-description-highlight {
  font-weight: bold;
}

.confirm-input {
  flex-grow: 1;
  margin-left: 5px;
  --padding-start: 10px;
  --padding-end: 10px;
  --inline-padding-start: 0px;
  --inline-padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  border: 1px solid #a1a0a5;

  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 26px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #171717;
  margin-right: 10px;
}

.confirm-text-area {
  border: 1px solid #a1a0a5;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 130%;
  color: #171717;
  min-height: 100px;
  max-height: 300px;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
}

.ion-color-confirm-close-button {
  --color: #ececec;
  --padding-start: 13px;
  --padding-end: 16px;
  --box-shadow: none;
  margin: 0px;
}

.confirm-close-button {
  position: absolute;
  top: 0px;
  right: 0px;
}

.confirm-close-button-icon {
  width: 14px;
  height: 14px;
}

.gamification-report-container {
  position: relative;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.ion-color-gamification-report-close {
  position: absolute;
  top: 10px;
  right: 0px;
  --color: #000;
  --padding-start: 13px;
  --padding-end: 16px;
  --box-shadow: none;
  margin: 0px;
}

.gamification-report-close-icon {
  width: 30px;
  height: 30px;
}

.gamification-report-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gamification-report-main-background-help {
  background-color: #13d0d8;
}

.gamification-report-main-background-answerer {
  background-color: #ff626a;
}

.gamification-report-main-image {
  width: 270px;
  height: 270px;
  margin-top: -10px;
}

.gamification-report-main-message {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-family: "YanoljaYache";
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.gamification-report-main-message-point {
  color: #ffc45b;
}

.gamification-report-info-container {
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
}

.gamification-report-info-title {
  margin-left: 15px;
  margin-bottom: 20px;

  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
}

.gamification-report-info-user-list-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}

.gamification-report-info-user-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80px;
  margin-left: 20px;
}

.gamification-report-info-user-item-profile {
  min-width: 80px;
  min-height: 80px;
  border-radius: 40px;
}

.gamification-report-info-user-item-nickname {
  white-space: nowrap;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  max-width: 80px;
  text-overflow: ellipsis;
  color: #000000;
  width: 100%;
  text-align: center;
}

.gamification-report-info-weekly-report-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0px 15px;
}

.gamification-report-info-weekly-report-item-container {
  width: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gamification-report-info-weekly-report-count-container {
  width: 96px;
  height: 96px;
  border-radius: 48px;
  padding: 8px;
  margin-bottom: 10px;
  position: relative;
}

.gamification-report-info-weekly-report-count-container-1 {
  background: #fa9d44;
}

.gamification-report-info-weekly-report-count-container-2 {
  background: #44d7b6;
}

.gamification-report-info-weekly-report-count-container-3 {
  background: #b61fe0;
}

.gamification-report-info-weekly-report-count-inner {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  padding: 8px;
  background: #fff8;
}

.gamification-report-info-weekly-report-count {
  width: 64px;
  height: 64px;
  padding: 0px 2px;
  border-radius: 32px;
  background: #fff;
}

.gamification-report-info-weekly-report-count-text {
  width: 100%;
  height: 100%;
  font-family: "YanoljaYache";
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  white-space: nowrap;
  text-align: center;
  color: #171717;
  position: absolute;
  top: 0px;
  left: 0px;
  padding-top: 16px;
}

.gamification-report-info-weekly-report-description {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-align: center;

  color: #171717;
}

.information-popup-container {
  position: absolute;
  max-height: 50%;
  max-width: 100%;
  width: 100%;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
}

.information-popup-body {
  background: #ffffff;
  border: 1px solid #2667eb;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  padding: 5px 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;

  color: #2667eb;
}

.select-popup-icon {
  width: 36px;
  height: 36px;
  margin-bottom: 29px;
}

.select-popup-image {
  width: 100%;
  max-width: 360px;
  margin-bottom: 20px;
}

.select-popup-title-container {
  width: 100%;
  margin-bottom: 16px;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  line-height: 100%;

  letter-spacing: -0.2px;

  color: #000000;
}

.select-popup-title-highlight {
  color: #2d9cdb;
}

.select-popup-body-container {
  width: 100%;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.02em;

  color: #6b6b6b;
}

.select-popup-body-item-out-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0px;
}

.select-popup-body-item-out-bordered-container {
  border-bottom: 0.5px solid #a1a0a5;
}

.select-popup-body-item-container {
  flex-grow: 1;
}

.select-popup-body-item-checkbox {
  width: 17px;
  height: 17px;
}

.ion-color-select-popup-close-button {
  --color: #ececec;
  --padding-start: 13px;
  --padding-end: 16px;
  --box-shadow: none;
  margin: 0px;
}

.select-popup-close-button {
  position: absolute;
  top: 0px;
  right: 0px;
}

.select-popup-close-button-icon {
  width: 14px;
  height: 14px;
}

.landing-container {
  width: 100%;
  height: 100%;
  max-width: 360px;
  max-height: min(100%, 720px);
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  overflow: hidden;
}

.landing-button-container {
  height: 130px;
  min-height: 130px;
  background: #2d9cdb;
}

.landing-button-text {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 100%;
  color: #ffffff;
  margin-top: 25px;
  margin-bottom: 20px;
}

.landing-button-image {
  width: 130px;
  margin: 0px 20px;
}

.sales-product-viewer-content {
  width: 100%;
  height: calc(100% - 108px);
  background: #fff;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.sales-product-viewer-cardview-container {
  padding: 0px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #fff;
  margin-bottom: 10px;
}

.sales-product-viewer-cardview-square-container {
  width: 100%;
  /* position: relative; */
}

.sales-product-viewer-cardview-swipe {
  width: 100%;
  height: 100%;
  top: 0px;
}

.sales-product-viewer-cardview-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sales-product-viewer-index-container {
  position: absolute;
  bottom: 20px;
  left: 0px;
  width: 100%;
  z-index: 3;
}

.sales-product-viewer-page-selected {
  background: #fff8;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin: 0px 7px;
}

.sales-product-viewer-page {
  background: #bbb8;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin: 0px 8px;
}

.sales-product-viewer-information-container {
  width: 100%;
  padding: 10px 16px;
  margin-bottom: 7px;
}

.sales-product-viewer-information-title {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 137.88%;
  /* or 23px */

  color: #000000;
  margin-bottom: 9px;
}

.sales-product-viewer-information-discount {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 18px */

  color: #000000;
}

.sales-product-viewer-information-discount-original {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 18px */

  -webkit-text-decoration-line: line-through;

          text-decoration-line: line-through;

  /* 프로필날짜조회수 */

  color: #888888;
  margin-left: 8px;
}

.sales-product-viewer-information-price {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 137.88%;
  /* or 23px */

  color: #ae0003;
}

.sales-product-viewer-detail-title {
  padding: 10px 20px;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  /* identical to box height, or 15px */

  color: #000000;
}

.sales-product-viewer-detail-container {
  width: 100%;
  max-height: 490px;
  padding: 0px 20px;
  overflow: hidden;
  position: relative;
}

.sales-product-viewer-detail-container-expanded {
  width: 100%;
  padding: 0px 20px;
}

.sales-product-viewer-detail-more-button-container {
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 9px 0px;
  background-color: #fffd;
  position: absolute;
  bottom: 0px;
}

.sales-product-viewer-detail-more-button {
  border: 1px solid #366afe;
  width: 250px;
  height: 40px;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 137.88%;
  /* identical to box height, or 21px */

  text-align: center;
  background: #fff;
  color: #366afe;
}

.sales-product-viewer-footer {
  height: 65px;
  padding: 10px 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sales-product-viewer-share-button {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.sales-product-viewer-buy-button {
  flex-grow: 1;
  color: #fff;
  background: #2667eb;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  /* identical to box height, or 21px */

  text-align: center;
}


.product-order-container {
  padding: 10px;
}

.product-order-title {
  padding-left: 10px;
  padding-top: 10px;

  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 100%;
  /* identical to box height, or 17px */

  color: #000000;

  margin-bottom: 10px;
}

.product-order-information-container {
  width: 100%;
  padding: 10px;
  border: 1px solid #e2e2e2;
  margin-bottom: 10px;
}

.product-order-information-title {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 200%;

  color: #000000;
  margin-bottom: 10px;
}

.product-order-information-discount {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 137.88%;
  /* identical to box height, or 18px */

  color: #000000;

  margin-bottom: 5px;
}

.product-order-information-discount-original {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 137.88%;
  /* identical to box height, or 18px */

  -webkit-text-decoration-line: line-through;

          text-decoration-line: line-through;

  /* 프로필날짜조회수 */

  color: #888888;
  margin-left: 10px;
}

.product-order-information-price {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 137.88%;
  /* or 23px */

  color: #ae0003;
  margin-bottom: 10px;
}

.product-order-information-count-text {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 137.88%;
  /* identical to box height, or 18px */

  display: flex;
  align-items: center;

  color: #000000;
}

.product-order-pharmacist-warning {
  background: #ffca17;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 130%;
  /* or 17px */

  color: #000000;
  padding: 10px;
  margin-bottom: 10px;
}

.product-order-information-price-total-title {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 150%;

  color: #2667eb;
  margin-left: 10px;
}

.product-order-information-price-total-container {
  min-width: 130px;
}

.product-order-information-price-total-information {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 100%;

  color: #000000;
}

.product-order-recipient-change-button {
  height: 26px;
  padding: 5px;
  border: 1px solid #2667eb;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  color: #2667eb;
}

.product-order-delivery-change-button {
  height: 26px;
  padding: 5px;
  border: 1px solid #eb5757;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  color: #eb5757;
}

.product-order-registration-change-button {
  height: 33px;
  padding: 9px;
  border: 1px solid #eb5757;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  color: #eb5757;
  width: 70%;
  margin: 0px 15%;
}

.product-order-delivery-no-info {
  color: #eb5757 !important;
}

.product-order-guide-container {
  width: 100%;
  background: #eeeeee;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  /* or 16px */

  text-align: center;

  /* 프로필아이디 */

  color: #6b6b6b;
  padding: 10px 0px;
  margin-bottom: 10px;
}

.product-order-buy-button {
  width: 100%;
  background: #346aff;
  color: #fff;
}

.product-order-additional-information-container {
  padding: 10px 15px;
}

.product-order-additional-information-input-container {
  display: flex;
  flex-direction: row;

  height: 40px;
  background: #ffffff;
  /* 하단바비활성화 */
  border: 0.5px solid #a1a0a5;
  box-sizing: border-box;
  border-radius: 20px;
  /* margin-left: 8px; */
  /* margin-right: 8px; */
  margin-top: 15px;
  /* margin-bottom:16px; */
  padding-left: 14px;
}

.product-order-additional-information-input {
  --padding-start: 0px;
  --padding-end: 0px;
  flex-grow: 1;
  --placeholder-color: #a1a0a5;
  --placeholder-opacity: 1;

  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  color: #171717;
}

.product-order-additional-information-title {
  margin-top: 25px;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  /* or 15px */

  /* qna타이틀내용 */

  color: #171717;
}

.product-order-additional-information-description {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 100%;

  margin-top: 10px;
  /* 프로필아이디 */
  color: #6b6b6b;
}

.product-order-additional-information-footer {
  height: 65px;
  padding: 10px 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.product-order-additional-information-done-button {
  flex-grow: 1;
  color: #2667eb;
  background: #ffffff;
  /* 쨍한파랑 */

  border: 1px solid #2667eb;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  /* identical to box height, or 21px */

  text-align: center;
}

.product-order-done-message-container {
  width: 100%;
  background: #eeeeee;

  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 130%;
  /* or 19px */

  text-align: center;

  /* qna타이틀내용 */

  color: #171717;
  padding: 10px 0px;
  margin-bottom: 10px;
}

.product-order-done-information-price {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 137.88%;
  /* or 23px */

  /* qna타이틀내용 */

  color: #171717;
  flex-grow: 1;
}

.product-order-additional-information-footer {
  height: 65px;
  padding: 10px 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.product-order-additional-information-done-button {
  flex-grow: 1;
  background: #fff;
  color: #2667eb;
  border: 1px solid #2667eb;

  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  /* identical to box height, or 21px */

  text-align: center;
}

.product-order-additional-information-upload-container {
  margin-top: 16px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
}

.product-order-additional-information-upload-button-container {
  flex-grow: 1;
  height: 33px;
  overflow: hidden;
  display: inline-block;
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
}

.product-order-additional-information-upload-button {
  height: 32px;
  width: 100%;
  --padding-start: 25px;
  --padding-end: 25px;
  margin: 0px;
  background: #ffffff;
  /* 시그니처컬러 */
  border: 1px solid #2d9cdb;
  box-sizing: border-box;
  border-radius: 16.1667px;

  color: #2d9cdb;
}

.product-order-additional-information-upload-file {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.product-order-additional-information-upload-button-icon {
  width: 18px;
  height: 18px;
  margin-right: 2px;
}

.product-order-additional-information-upload-button-text {
  font-size: 14px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.1em;

  color: #a1a0a5;
}

.product-order-additional-information-upload-viewer-container {
  width: 100%;
}
.product-order-additional-information-upload-viewer {
  margin-top: 15px;
  height: auto;
  width: 100%;
}

.number-selector-container {
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #a1a0a5;
  border-radius: 4px;
  position: relative;
}

.number-selector-icon-remove {
  color: #a1a0a5;
}

.number-selector-icon-add {
  color: #171717;
}

.number-selector-info {
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 150%;
  /* identical to box height, or 19px */

  text-align: center;
  text-transform: capitalize;
  margin: 0px 10px;

  /* qna타이틀내용 */

  color: #171717;
}

.number-selector-info-disabled {
  color: #a1a0a5 !important;
}

.number-selector-remove {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.number-selector-add {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0px;
  right: 0px;
}

.component-test-cardnews1 {
  --cardview-card-border-radius: 24px;
  --cardview-card-padding: 0px 6px; }

.component-test-cardnews2 {
  --cardview-border-radius: 24px; }

.seminar-view-sticky-top {
  position: -webkit-sticky;
  position: sticky;
  z-index: 100; }

.seminar-view-information-icon {
  width: 18px;
  height: 18px; }

.seminar-view-information-text {
  margin-left: 4px !important;
  margin-right: 13px !important; }

.link-container {
  width: 100%;
  border-radius: 12px;
  background-color: #F7F8F9;
  --size: 60px;
  --radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px; }
  .link-container .link-info-container {
    flex-grow: 1; }

.link-plain {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline; }

.link-youtube-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; }
  .link-youtube-container .link-youtube {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none; }

.seminar-review-popup-container {
  --width: 100%;
  /* --height: 240px; */
  --background: #0000; }
  .seminar-review-popup-container .seminar-review-popup-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0px 24px; }
    .seminar-review-popup-container .seminar-review-popup-inner .seminar-review-popup-backdrop {
      flex-grow: 1;
      width: 100%; }
    .seminar-review-popup-container .seminar-review-popup-inner .seminar-review-container-outer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative; }
      .seminar-review-popup-container .seminar-review-popup-inner .seminar-review-container-outer .seminar-review-container {
        width: 100%;
        max-width: 360px;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
        border-radius: 20px;
        padding: 24px;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative; }
        .seminar-review-popup-container .seminar-review-popup-inner .seminar-review-container-outer .seminar-review-container .seminar-review-body-container {
          padding-top: 36px;
          padding-bottom: 40px;
          flex-direction: column;
          align-items: center;
          text-align: center;
          width: 100%; }
          .seminar-review-popup-container .seminar-review-popup-inner .seminar-review-container-outer .seminar-review-container .seminar-review-body-container .seminar-review-body-title {
            margin-bottom: 20px;
            width: 100%; }
          .seminar-review-popup-container .seminar-review-popup-inner .seminar-review-container-outer .seminar-review-container .seminar-review-body-container .seminar-review-body-star {
            display: inline-block; }
          .seminar-review-popup-container .seminar-review-popup-inner .seminar-review-container-outer .seminar-review-container .seminar-review-body-container .seminar-review-body-input {
            margin-top: 20px;
            min-height: 40px;
            width: 100%;
            display: block; }
        .seminar-review-popup-container .seminar-review-popup-inner .seminar-review-container-outer .seminar-review-container .seminar-review-buttons-container {
          width: 100%;
          display: flex;
          flex-direction: row; }
          .seminar-review-popup-container .seminar-review-popup-inner .seminar-review-container-outer .seminar-review-container .seminar-review-buttons-container .seminar-review-button-gap {
            width: 12px; }
          .seminar-review-popup-container .seminar-review-popup-inner .seminar-review-container-outer .seminar-review-container .seminar-review-buttons-container .seminar-review-button {
            width: calc(50% - 6px); }

.seminar-review-done-popup-container {
  --width: 100%;
  /* --height: 240px; */
  --background: #0000; }
  .seminar-review-done-popup-container .seminar-review-done-popup-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0px 24px; }
    .seminar-review-done-popup-container .seminar-review-done-popup-inner .seminar-review-done-popup-backdrop {
      flex-grow: 1;
      width: 100%; }
    .seminar-review-done-popup-container .seminar-review-done-popup-inner .seminar-review-done-container-outer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative; }
      .seminar-review-done-popup-container .seminar-review-done-popup-inner .seminar-review-done-container-outer .seminar-review-done-container {
        width: 100%;
        max-width: 360px;
        background: #FFFFFF;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
        border-radius: 20px;
        padding: 24px;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative; }
        .seminar-review-done-popup-container .seminar-review-done-popup-inner .seminar-review-done-container-outer .seminar-review-done-container .seminar-review-done-body-container {
          width: 100%;
          padding-top: 36px;
          padding-bottom: 60px;
          flex-direction: column;
          text-align: center;
          align-items: center; }
          .seminar-review-done-popup-container .seminar-review-done-popup-inner .seminar-review-done-container-outer .seminar-review-done-container .seminar-review-done-body-container .seminar-review-done-body-icon {
            margin-bottom: 20px;
            width: 100%; }
          .seminar-review-done-popup-container .seminar-review-done-popup-inner .seminar-review-done-container-outer .seminar-review-done-container .seminar-review-done-body-container .seminar-review-done-body-title {
            width: 100%; }
        .seminar-review-done-popup-container .seminar-review-done-popup-inner .seminar-review-done-container-outer .seminar-review-done-container .seminar-review-done-buttons-container {
          width: 100%;
          display: flex;
          flex-direction: row; }
          .seminar-review-done-popup-container .seminar-review-done-popup-inner .seminar-review-done-container-outer .seminar-review-done-container .seminar-review-done-buttons-container .seminar-review-done-button-gap {
            width: 12px; }
          .seminar-review-done-popup-container .seminar-review-done-popup-inner .seminar-review-done-container-outer .seminar-review-done-container .seminar-review-done-buttons-container .seminar-review-done-button {
            width: calc(50% - 6px); }

.contract-update-item-all {
  padding: 17.5px 12px;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  border-radius: 12px; }

.contract-update-item-all.checked {
  background-color: #EAF9FF; }

.contract-update-item-all.unchecked {
  background-color: #F7F8F9; }

.contract-update-item {
  padding: 9.5px 12px;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px; }

.contract-update-comment {
  padding: 17.5px 12px;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  border-radius: 12px;
  background-color: #F7F8F9; }

.user-permanent-image {
  width: 158px;
  height: 158px; }

.allinone-detail-head-container {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 30px;
  padding: 0px 20px; }
  .allinone-detail-head-container .allinone-detail-logo {
    min-width: 80px;
    min-height: 80px;
    width: 80px;
    height: 80px;
    object-fit: contain;
    border: 1px solid #e4ebed;
    border-radius: 40px;
    -webkit-transform: translateZ(0);
            transform: translateZ(0); }

.allinone-posts-container {
  padding: 0px 20px; }

.allinone-information-container {
  padding: 0px 20px; }
  .allinone-information-container .allinone-information-title {
    padding-top: 20px;
    padding-bottom: 8px; }
  .allinone-information-container .allinone-information-body {
    padding-bottom: 20px; }

.allinone-footer {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  width: 100%; }

/*Color*/
:root {
  --primary: #1fbcff;
  --primary_shadow: #008fcc;
  --blue400: #1f75ff;
  --blue300: #478eff;
  --blue100: #99c0ff;
  --red300: #ff4757;
  --gray900: #0f2034;
  --gray800: #273648;
  --gray700: #3f4d5d;
  --gray600: #4b5b69;
  --gray500: #5f6d79;
  --gray400: #7e8a95;
  --gray300: #9ba5af;
  --gray200: #b7c0c6;
  --gray100: #cdd5d9;
  --gray50: #e4ebed;
  --gray20: #eff3f5;
  --gray15: #f2f4f5;
  --gray10: #f7f8f9;
  --gray0: #ffffff; }

.allinone-introduction {
  font-size: 62.5%;
  line-height: 1.285;
  text-align: center;
  overflow-x: hidden;
  background: #f2f4f5;
  background: var(--gray15);
  /*Questions*/
  /*Description*/
  /*Recommend*/ }
  .allinone-introduction main {
    padding-bottom: 60px;
    background: #1f75ff;
    background: var(--blue400); }
  .allinone-introduction section {
    margin: 0 auto;
    padding: 80px 20px 0px 20px; }
  .allinone-introduction h1 {
    padding: 20px 0;
    font-size: 2.8em;
    font-weight: 700;
    color: #99c0ff;
    color: var(--blue100); }
  .allinone-introduction h2 {
    margin-bottom: 8px;
    font-size: 2.2em;
    font-weight: 700;
    color: #1f75ff;
    color: var(--blue400); }
  .allinone-introduction p {
    font-size: 1.6em;
    font-weight: 400;
    color: #3f4d5d;
    color: var(--gray700); }
  .allinone-introduction .emphasis {
    color: #ffffff;
    color: var(--gray0); }
  .allinone-introduction .questions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(/images/img_04.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 200px; }
  .allinone-introduction .questions .qbox {
    margin: 8px 0;
    padding: 1.2em 1.6em;
    width: -webkit-fit-content;
    width: fit-content;
    font-size: 18px;
    font-weight: 600;
    color: #3f4d5d;
    color: var(--gray700);
    background: #ffffff;
    background: var(--gray0);
    box-shadow: 0px 6px 14px rgba(15, 32, 52, 0.1);
    border-radius: 12px; }
  .allinone-introduction .q-01 {
    -webkit-transform: rotate(-2.79deg);
            transform: rotate(-2.79deg); }
  .allinone-introduction .q-02 {
    opacity: 0.9;
    -webkit-transform: rotate(-2.5deg);
            transform: rotate(-2.5deg); }
  .allinone-introduction .q-03 {
    opacity: 0.8; }
  .allinone-introduction .q-04 {
    opacity: 0.7;
    -webkit-transform: rotate(1.37deg);
            transform: rotate(1.37deg); }
  .allinone-introduction .q-05 {
    opacity: 0.6;
    -webkit-transform: rotate(-1.47deg);
            transform: rotate(-1.47deg); }
  .allinone-introduction .q-06 {
    opacity: 0.5; }
  .allinone-introduction .dots p {
    display: flex;
    flex-direction: column;
    font-size: 6em;
    line-height: 0.5;
    color: #478eff;
    color: var(--blue300); }
  .allinone-introduction .description article {
    margin: 40px auto;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    padding: 40px;
    background: #ffffff;
    background: var(--gray0);
    box-shadow: 0px 6px 14px rgba(15, 32, 52, 0.1);
    border-radius: 24px;
    flex: none;
    order: 1;
    flex-grow: 0; }
  .allinone-introduction .description .img-box {
    width: 100%;
    text-align: center; }
  .allinone-introduction .description img {
    margin-bottom: 32px;
    max-width: 180px; }
  .allinone-introduction .recommend {
    padding-bottom: 120px; }
  .allinone-introduction .recommend h1 {
    margin-bottom: 32px;
    color: #3f4d5d;
    color: var(--gray700); }
  .allinone-introduction .recommend p {
    margin-bottom: 12px;
    font-size: 1.8em; }
  .allinone-introduction .recommend img {
    max-width: 40px; }
  .allinone-introduction h6 {
    font-size: 14px;
    font-weight: 500;
    color: #4b5b69;
    color: var(--gray600); }
  .allinone-introduction .recommend article {
    margin: 40px auto;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: #ffffff;
    background: var(--gray0);
    box-shadow: 0px 6px 14px rgba(15, 32, 52, 0.1);
    border-radius: 24px;
    flex: none;
    order: 1;
    flex-grow: 0; }
  .allinone-introduction .recommend ul {
    width: 100%; }
  .allinone-introduction .recommend article li {
    margin: 16px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; }
  .allinone-introduction .recommend article li div {
    width: 80px; }

.allinone-head-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px; }

.allinone-footer {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  width: 100%; }

.allinone-detail-head-container {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 30px;
  padding: 0px 20px; }
  .allinone-detail-head-container .allinone-detail-logo {
    min-width: 80px;
    min-height: 80px;
    width: 80px;
    height: 80px;
    object-fit: contain;
    border: 1px solid #e4ebed;
    border-radius: 40px;
    -webkit-transform: translateZ(0);
            transform: translateZ(0); }

.allinone-posts-container {
  padding: 0px 20px; }

.allinone-information-container {
  padding: 0px 20px; }
  .allinone-information-container .allinone-information-title {
    padding-top: 20px;
    padding-bottom: 8px; }
  .allinone-information-container .allinone-information-body {
    padding-bottom: 20px; }

.allinone-footer {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  width: 100%; }

.allinone-head-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px; }

.allinone-footer {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  width: 100%; }

.chat-header-title {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  left: 0px;
  top: 0px;
  padding-top: 12px;

  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #171717;
}

.chat-header-call-button {
  background: #fff;
  border-radius: 5px;
  padding: 6px 12px;

  font-style: normal;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 100%;
  color: #2667eb !important;
  --ion-color-base: #2667eb !important;
  border: 1px solid #2667eb;
  height: 24px !important;
}

.chat-header-info-container {
  width: 100%;
  height: 64px;
  padding: 5px 10px;
  border-bottom: 1px solid #e2e2e2;
  border-top: 1px solid #e2e2e2;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chat-header-info-image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 80px;
  min-width: 80px;
  height: 54px;
  background: #dae8ef;
  border-radius: 15px;
  margin-right: 20px;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  text-align: center;

  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 120%;
  padding: 0px 5px;
}

.chat-header-info-title {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;

  color: #000000;
  margin-bottom: 6px;
}

.chat-header-info-price {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 135%;
  /* or 20px */

  color: #000000;
}

.chat-list-item-container {
  width: 100%;
  padding: 6px 10px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #e2e2e2;
  align-items: center;
}

.chat-list-item-profile {
  width: 56px;
  height: 56px;
  border-radius: 40px;
  margin-right: 16px;
}

.chat-list-item-type {
  padding: 4px;
  background: #dae8ef;

  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  margin-right: 10px;
  margin-bottom: 2px;
}

.chat-list-item-name {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  letter-spacing: -0.2px;

  color: #171717;
}

.chat-list-item-time {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;

  color: #888;
}

.chat-list-item-body {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */
  flex-grow: 1;

  color: #9e9e9e;
}

.chat-list-item-unread-count {
  height: 16px;
  min-width: 16px;
  padding: 3px 4px;
  border-radius: 8px;

  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 100%;
  /* or 10px */

  text-align: center;

  /* 화이트 */
  background: #fd6128;
  color: #ffffff;
}

