html {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

body {
  /* padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left); */
  margin-top: env(safe-area-inset-top) !important;
  margin-bottom: 0px !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}

* {
  -webkit-overflow-scrolling: touch;
  user-select: none;
}

input,
textarea {
  user-select: auto;
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
textarea,
p,
blockquote,
th,
td,
input,
select,
textarea,
button {
  margin: 0;
  padding: 0;
}
fieldset,
img {
  border: 0 none;
}
dl,
ul,
ol,
menu,
li {
  list-style: inherit;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
input,
select,
textarea,
button {
  font-size: 100%;
  vertical-align: middle;
}
button {
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  -webkit-text-size-adjust: none;
} /* 뷰표트 변환시 폰트크기 자동확대 방지 */
input[type="text"],
input[type="password"],
input[type="submit"],
input[type="search"] {
  -webkit-appearance: none;
  border-radius: 0;
}
input:checked[type="checkbox"] {
  background-color: #666;
  -webkit-appearance: checkbox;
}
button,
input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="file"] {
  -webkit-appearance: button;
  border-radius: 0;
}
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
a {
  color: #333;
  text-decoration: none;
}
a:active,
a:hover {
  text-decoration: none;
}
address,
caption,
cite,
code,
dfn,
em,
var {
  font-style: normal;
  font-weight: normal;
}
