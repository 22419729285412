.viewer-comments-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.viewer-comment-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 8px;
  position: relative;
}

.viewer-comment-container-depth {
  padding-left: 25px;
}

.viewer-comment-profile {
  width: 24px;
  height: 24px;
  background-color: #a1a0a5;
  border-radius: 12px;
  color: #2d9cdb;
  margin-right: 11px;
}

.viewer-comment-nickname {
  font-size: 13px;
  line-height: 20px;
  font-weight: bold;
  color: #6b6b6b;
  margin-right: 7.5px;
}

.viewer-comment-nickname-blocked {
  color: #ff1f31;
}

.viewer-comment-body-container {
  width: calc(100% - 35px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.viewer-comment-body {
  width: 100%;

  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.03em;
  color: #6b6b6b;
}

.viewer-comment-body-blocked {
  width: 100%;
  font-family: "Nanum Barun Gothic";
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.03em;

  color: #6b6b6b;
}

.viewer-comment-image {
  height: auto;
  width: 100%;
  border-radius: 8px;
}

.viewer-comment-new {
  position: absolute;
  left: -3px;
  top: -5px;
  color: #fff;
  text-shadow: -1px -1px 0 #eb5757, 1px -1px 0 #eb5757, -1px 1px 0 #eb5757, 1px 1px 0 #eb5757;
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
}

.viewer-comment-time {
  font-size: 10px;
  line-height: 16px;
  padding-right: 15px;
  color: #6b6b6b;
}

.viewr-comment-composer-conteainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.viewer-comment-composer-open-writer-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}

.viewer-comment-composer-open-writer-info-sub {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.viewer-comment-input-open-writer-info {
  flex-grow: 1;
  margin-left: 5px;
  --padding-start: 10px;
  --padding-end: 10px;
  --inline-padding-start: 0px;
  --inline-padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  border: 1px solid #a1a0a5;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: -0.02em;

  color: #171717;
  margin-right: 10px;
}

.viewer-comment-composer {
  width: 100%;
  /* height: 33px; */
  background: #ffffff;

  border: 1px solid #a1a0a5;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.viewer-comment-input {
  flex-grow: 1;
  margin-left: 13px;
  --padding-start: 0px;
  --padding-end: 0px;
  --inline-padding-start: 0px;
  --inline-padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: -0.02em;

  color: #171717;
}

.viewer-comment-composer-body {
  position: relative;
  background: #0000;
  z-index: 2;
  height: auto;
  width: 100%;
  border: 1px;

  font-size: 14px;
  line-height: 22px;
  padding: 5px 10px;
  color: #171717;
  outline-style: none;
}

.viewer-comment-composer-file {
  --icon-color: #a1a0a5;
  --icon-size: 16px;
  width: 30px;
  height: 20px;
}

.ion-color-viewer-comment-composer-done {
  --ion-color-base: #2d9cdb;
  color: #fff;
  width: 27.25px;
  height: 24px;
  margin: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  --border-radius: 12px;
  margin-right: 4px;
}

.ion-color-viewer-comment-composer-done-disabled {
  --ion-color-base: #a2a2a2;
  color: #fff;
  width: 24px;
  height: 24px;
  margin: 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  --border-radius: 12px;
  margin-right: 4px;
}

.viewer-comment-composer-done-icon {
  width: 16px;
  height: 16px;
}

.viewer-comment-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.viewer-comment-action-enabled {
  padding-left: 15px;
  padding-right: 15px;

  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;

  letter-spacing: -0.02em;
  color: #2f80ed;
}

.viewer-comment-action-disabled {
  padding-left: 15px;
  padding-right: 15px;

  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;

  letter-spacing: -0.02em;
  color: #a2a2a2;
}

.viewer-comment-action-empasis {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;

  letter-spacing: -0.02em;
  color: #333333;
}

.ion-color-viewer-comment-composer-checkbox {
  margin-right: 5px;
  --background-checked: #2d9cdb;
  --border-color-checked: #2d9cdb;
  --border-color: #c4c4c4;
  --checkmark-color: #fff;
}

.viewer-comment-composer-name-input {
  font-weight: 800;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #6b6b6b;
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
}

.viewer-comment-composer-button {
  font-size: 15px;
  line-height: normal;
  color: #2d9cdb;
  font-weight: bold;
  padding-right: 5px;
}

.viewer-comment-composer-button-disabled {
  font-size: 15px;
  line-height: normal;
  color: #c4c4c4;
  padding-right: 5px;
}

.viewer-comment-file-container {
  margin-bottom: 5px;
}

.viewer-comment-composer-anonymous-container {
  margin-top: 15px;
}

.viewer-comment-composer-anonymous-toggle {
  zoom: 0.6;
  --background-checked: #2d9cdb;
  --background: #a1a0a5;
}

.viewer-comment-composer-anonymous-button {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  padding-left: 5px;
  width: 105px;
  color: #2d9cdb;
  white-space: nowrap;
}

.viewer-comment-composer-anonymous-disabled {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  padding-left: 5px;
  width: 105px;
  color: #a1a0a5;
  white-space: nowrap;
}
