.slider-index-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 120px;
    height: 10px;
}


.slider-index-dot {
    flex-grow:1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slider-index-dot-disabled {
    height: 5px;
    width: 5px;
    background: #2D9CDB;
    border-radius: 2.5px;    
}


.slider-index-dot-enabled {
    height: 10px;
    width: 10px;
    background: #2D9CDB;
    border-radius: 5px;
}