.register-waiting-logo {
  width: 120px;
  height: 120px;
  margin-top: 15vh;
}

.register-waiting-logo-small {
  width: 70px;
  height: 70px;
}

.register-waiting-status {
  margin-left: 25px;
  font-weight: 800;

  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.04em;

  color: #6b6b6b;
}

.register-waiting-status-text {
  font-weight: 800;

  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.04em;
  white-space: nowrap;

  color: #6b6b6b;
}

.register-waiting-message-container {
  height: 95px;
  padding-top: 15px;
  padding-bottom: 5px;
}

.register-waiting-swipe {
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-height: 100%;
  background: #dae8ef;
  max-height: calc(100% - 143px);
}

.register-waiting-read-content-button-container {
  padding: 5px 0px;
}

.register-waiting-read-content-button {
  background: #233ec4;
  border-radius: 7px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;

  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.04em;
  padding: 10px 15px;
  color: #ffffff;
}

.register-waiting-start-content-button {
  background: #2d9cdb;
  border-radius: 7px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;

  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.04em;
  padding: 10px 15px;
  color: #ffffff;
}

.register-waiting-status-text-etc {
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  letter-spacing: -0.02em;
  white-space: nowrap;

  color: #6b6b6b;
}

.register-waiting-status-waiting {
  color: #2d9cdb;
}

.register-waiting-status-denied {
  color: #f32e6d;
}

.register-waiting-message {
  margin-left: 25px;
  margin-top: 15px;

  font-size: 15px;
  line-height: 20px;
  /* or 154% */

  letter-spacing: -0.02em;

  /* 프로필아이디 */

  color: #6b6b6b;
}

.register-waiting-guide.register-waiting-guide-margin {
  margin-top: 115px;
}

.register-waiting-guide {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: flex-end;
  width: 100%;
  text-align: right;
  padding-right: 25px;
  padding-left: 25px;

  font-size: 13px;
  line-height: 20px;
  /* or 154% */

  letter-spacing: -0.02em;

  /* 프로필아이디 */

  color: #6b6b6b;
}

.register-waiting-kakao {
  height: 40px;
  width: 82px;
  margin-top: 10px;
}

.register-waiting-message-box {
  margin-top: 20px;
  margin-right: 15px;
  margin-left: 15px;
  width: calc(100% - 30px);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #888888;
  box-sizing: border-box;

  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.02em;

  /* 프로필아이디 */

  color: #6b6b6b;
}

.register-waiting-bottom {
  margin-top: 70px;

  font-size: 17px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1em;
}

.ion-color-register-waiting-button {
  margin-left: 24px;
  margin-right: 24px;
  height: 48px;
  width: calc(100% - 48px);
  --ion-color-base: #2d9cdb;
  --ion-color-shade: #c4c4c4;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.1);
  --border-radius: 24px;
  --padding-start: 0px;
  --padding-end: 0px;
  color: #fff;
}

.ion-color-register-waiting-button-inactive {
  margin-left: 24px;
  margin-right: 24px;
  height: 48px;
  width: calc(100% - 48px);
  --ion-color-base: #fff;
  --ion-color-shade: #c4c4c4;
  --box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.1);
  --border-radius: 24px;
  --border-width: 1px;
  --border-style: solid;
  --border-color: #afafaf;
  --padding-start: 0px;
  --padding-end: 0px;
  color: #6b6b6b;
  margin-bottom: 15px;
}

.register-cancel-done-popup-title {
  color: #f32e6d;
}

.ion-color-register-waiting-fab-ask {
  --ion-color-base: #ffdf37;
  --ion-color-contrast: #0000;
  --ion-color-shade: #ffdf37;
  width: 84px;
  height: 84px;
}

.ion-color-register-waiting-fab-start {
  --ion-color-base: #2d9cdb;
  --ion-color-contrast: #0000;
  --ion-color-shade: #2d9cdb;
  width: 84px;
  height: 84px;
}

.register-waiting-fab-text {
  color: #fff;

  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.1em;
  font-weight: bold;
}

.register-waiting-fab-text-ask {
  color: #392f31;

  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.1em;
  font-weight: bold;
}

.register-waiting-page-container {
  position: relative;
  top: 20px;
  width: 100%;
  z-index: 3;
}

.register-waiting-content-sample {
  margin: auto;
  display: block;
}

.register-waiting-page-selected {
  background: #4448;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
  margin: 0px 7px;
}

.register-waiting-page {
  background: #8888;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  overflow: hidden;
  transform: translateZ(0);
  z-index: 0;
  margin: 0px 8px;
}
