.ion-color-appstack {
  --ion-color-base: #fff;
  --ion-color-contrast: #2d9cdb;
  color: #a1a0a5;
  --ion-color-tint: #393939;
}

.appstack-tabbar {
  height: 56px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.appstack-icon {
  width: 24px;
  height: 24px;
  --padding-top: 0px;
  --padding-end: 0px;
  --padding-bottom: 0px;
  --padding-start: 0px;
}

.appstack-bag-icon {
  position: absolute;
  top: 8px;
  right: calc(50% - 15px);
  width: 15px;
  height: 15px;
  color: #fd6128;
}

.appstack-icon-label {
  font-size: 9px;
  line-height: 10px;
  text-align: center;
}

.ion-color-appstack-notification-count {
  --ion-color-base: #e94d45;
  --padding-top: 1px;
  --padding-end: 2px;
  --padding-bottom: 1px;
  --padding-start: 2px;
  position: absolute;
  top: 3px;
  left: 50%;
  height: 15px;
  min-width: 15px;
  border-radius: 7.5px;

  font-size: 11px;
  line-height: 11px;
  color: #fff;
}

.appstack-new-count-badge {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #fd6128;
  top: 3px;
  left: calc(50% + 5px);
}

.appstack-new-icon-badge {
  height: 16px;
  min-width: 16px;
  position: absolute;
  top: 3px;
  left: calc(50% + 3px);
  height: 16px;
  min-width: 16px;
}

.appstack-new-badge {
  background: #e94d45;
  height: 16px;
  min-width: 16px;
  padding-top: 3px;
  border-radius: 8px;
  position: absolute;
  top: 0px;
  left: calc(50% + 3px);
  height: 15px;
  min-width: 15px;
  border-radius: 7.5px;

  font-size: 11px;
  line-height: 11px;
  color: #fff;
}
