.allinone-head-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px;
}

.allinone-footer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;
}
