.profile-simple-container {
  /* width:100%; */
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-simple-image {
  width: 40px;
  height: 40px;
  background-color: #a1a0a5;
  border-radius: 20px;
  color: #2d9cdb;
  margin-right: 12px;
}

.profile-simple-info-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.profile-simple-info-name {
  display: flex;
  align-items: center;
  font-weight: 800;

  font-size: 15px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #6b6b6b;
}

.profile-simple-info-name-blocked{
    font-weight: 800;
    font-size: 12px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #FF1F31;
    
}

.profile-simple-name-input{
    font-weight: 800;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #6B6B6B;
    --padding-start: 0px;
    --padding-end: 0px;
}

.profile-simple-info-desc {
  font-size: 11px;
  line-height: 17px;
  letter-spacing: -0.005em;
  text-transform: lowercase;

  color: #888888;
}

.profile-simple-info-my-post {
  padding: 4px 6px;
  background: #2d9cdb;
  border-radius: 1px;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
  margin-right: 5px;
}

.profile-simple-info-nice {
  padding: 4px 6px;
  background: #dd4e4e;
  border-radius: 1px;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 13px;
  color: #ffffff;
  margin-right: 5px;
}
