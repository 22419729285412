.select-region-popup-container {
  background-color: #fff;
  display: flex;
  flex-direction: row;
}

.select-region-popup-province-container {
  width: 30%;
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 2;
}

.select-region-popup-region-container {
  width: 70%;
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 0px 10px;
}

.select-region-popup-province-item-container {
  height: 40px;
  width: 100%;

  color: #171717;

  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 100%;
  border-bottom: 1px solid #ccc;
}

.ion-color-select-region-popup-province-item-container {
  height: 40px;
  --ion-color-base: #ececec;
  --border-radius: 0px;
  --border-width: 0px;
  --border-style: solid;
  --border-color: #0000;
  --padding-start: 0px;
  --padding-end: 0px;
  margin: 0px;
  margin-inline: 0px;
  --box-shadow: none;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  width: 100%;

  color: #171717;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.ion-color-select-region-popup-region-item-container {
  height: 40px;
  --ion-color-base: #fff;
  --border-radius: 0px;
  --border-width: 0px;
  --border-style: solid;
  --border-color: #0000;
  --padding-start: 20px;
  --padding-end: 0px;
  margin: 0px;
  margin-inline: 0px;
  --box-shadow: none;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100%;
  width: 100%;
  text-align: left;

  color: #171717;
  border-bottom: 1px solid #ccc;
}
