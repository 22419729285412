.admin-notification-recipient-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 28px;
}
