.information-popup-container {
  position: absolute;
  max-height: 50%;
  max-width: 100%;
  width: 100%;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
}

.information-popup-body {
  background: #ffffff;
  border: 1px solid #2667eb;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
  padding: 5px 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 150%;

  color: #2667eb;
}
