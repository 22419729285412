
.image-viewer-popup-container {
    --width: 100%;
    --height: 100%;
    --background: #fff;
}

.image-viewer-popup-inner{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.image-viewer-title {
    width: 100%;
    text-align: center;
}

.image-viewer-swipe {
    height: 100%;
    max-height: 90%;
}
.react-swipeable-view-container {
    height: 100% !important;
}

.image-view-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.image-view{
    height: auto;
    width:100%;
}